import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom"

export const Callback = () => {
    const history = useHistory();

    useEffect((props) => {
        if (sessionStorage.getItem('callback') !== null) {
            console.log("on est pas null !!!")
            const value = sessionStorage.getItem('callback');

                history.push("/"+value);

        } else {
            console.log("on est null !!!")
            history.push("/");
        }
    }, []);
    return <>
        <div className="container text-center pt-5"  style={{ minHeight: "580px" }}>
            <h1  style={{ color: "#111" }}>Redirection en cours...</h1>
        </div>
    </>

}
