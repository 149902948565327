import React from "react";
import Page from "../Page";
import ElasticLjc from "../ElasticLjc/ElasticLjc";
import { generatePath } from "react-router-dom";
import MetaTags from 'react-meta-tags';


import "./Articles.scss";
import { LjcContext, getIdNiveau, getNomDecode } from "../ElasticLjc/fonctions";



class Articles extends Page {
	static contextType = LjcContext;
	componentDidMount() {
		const params = new URLSearchParams(window.location.search);
		const query = params.get("q");
		this.context(
			getNomDecode(this.props.match.params.niveau1),
			getNomDecode(this.props.match.params.niveau2),
			getNomDecode(this.props.match.params.niveau3),
			query
		);
	}


	componentDidUpdate() {
		const niveau1 = this.props.match.params.niveau1;
		const niveau2 = this.props.match.params.niveau2;
		const niveau3 = this.props.match.params.niveau3;
		const params = new URLSearchParams(window.location.search);
		const query = params.get("q");
		// let url = "";
		// if (niveau1 != null && niveau2 != null && niveau3 != null) {
		// 	url = `/articles/${niveau1}/${niveau2}/${niveau3}`
		// }
		// if (niveau1 != null && niveau2 != null && niveau3 == null) {
		// 	url = `/articles/${niveau1}/${niveau2}`
		// }
		// if (niveau1 != null && niveau2 == null && niveau3 == null) {
		// 	url = `/articles/${niveau1}`
		// }
		// if (niveau1 != null) {
		// 	window.history.pushState("", '', url)
		// }
		this.context(niveau1, niveau2, niveau3);
	}


	state = {
		elementsALaUne: [],
		actus: []
	};

	render() {
		const params = new URLSearchParams(window.location.search);
		const query = params.get("q");
		return (
			<>
				<div className="container-fluid " style={{ minHeight: "480px", backgroundColor: "#f4f4f4" }}>
					<MetaTags>
						<title>
							{"Articles" +
							(this.props.match.params.niveau1 ? ("-"+this.props.match.params.niveau1) : "") +
							(this.props.match.params.niveau2 ? ("-"+this.props.match.params.niveau2) : "") +
							(this.props.match.params.niveau3 ? ("-"+this.props.match.params.niveau3) : "")}
						</title>
						<meta
							name="description"
							content="Librairie Calvin" />
					</MetaTags>


					<div
						className="pb-lg-5 px-5"

					>
						<ElasticLjc
							idNiveau1={getIdNiveau(this.props.match.params.niveau1)}
							idNiveau2={getIdNiveau(this.props.match.params.niveau2)}
							idNiveau3={getIdNiveau(this.props.match.params.niveau3)}
							niveau1={this.props.match.params.niveau1}
							niveau2={this.props.match.params.niveau2}
							niveau3={this.props.match.params.niveau3}
							query={query}
						/>
					</div>

				</div>
			</>
		);
	}
}

export default Articles;
