// Libraries
import React from "react";
import {
	BrowserRouter as Router,
	Switch
} from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer/Footer";
import { HeaderAndRoute } from "./HeaderAndRoute";
import Home from "./Home";

import "@mitim/react-mitim/dist/styles.css";


// CSS
import "../styles/App.scss";
import "../styles/Font.scss";
import "../styles/Media.scss";

import { Storage, api } from "@mitim/react-mitim";
import Articles from "./Articles/Articles";
import Contact from "./Contact/Contact"
import AffichageCategories from "./AffichageCategories/AffichageCategories"
import { LjcContext, getNomNiveau } from "./ElasticLjc/fonctions";
import Actualites from "./Actualites/Actualites"
import { Podcast } from "./Podcast/Podcast"
import { Panier } from "./Panier/Panier"





//******************************************************************************** */
// ELASTIC SEARCH
import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch
} from "@elastic/react-search-ui";
import { SearchDriver } from "@elastic/search-ui";
import { FACETS, RESULT_FIELDS } from "./ElasticLjc/config/configuration";
import {
	buildAutocompleteQueryConfig,
	buildFacetConfigFromConfig,
	buildSearchOptionsFromConfig,
	buildSortOptionsFromConfig,
	getConfig,
	getFacetFields
} from "./ElasticLjc/config/config-helper";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import Navigation from "./Navigation/Navigation";
import PageArticle from "./PageArticle/PageArticle"
import { NosLibrairies } from "./NosLibrairies/NosLibrairies";
import { MonCompte } from "./MonCompte/MonCompte";
import Caisse from "./Caisse/Caisse";
import { Callback } from "./Callback";
import Livraison from "./Livraison/Livraison";
import Paiement from "./Paiement/Paiement";

//******************************************************************************** */

$(document).ready(function () {


	/* ---------------------------------------------- /*
	* Scroll top
	/* ---------------------------------------------- */

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$(".scroll-up").fadeIn();
		} else {
			$(".scroll-up").fadeOut();
		}
	});

	$('a[href="#totop"]').click(function () {
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
		return false;
	});
});






export default class App extends React.Component {

	state = {
		niveau1: null,
		niveau2: null,
		niveau3: null,
		query: "",
	}


	componentDidMount() {
		// connect();
	}


	render() {

		//**************************************************************************** */
		// CONFIGURATION ELASTIC SEARCH
		const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
		const connector = new AppSearchAPIConnector({
			searchKey,
			engineName,
			hostIdentifier,
			endpointBase
		});


		let filters = () => {
			let datas = [];
			if (this.state.niveau1 != null) {
				datas.push(
					{
						field: "niveau_1",
						values: [this.state.niveau1],
					}
				)
			}
			if (this.state.niveau2 != null) {
				datas.push(
					{
						field: "niveau_2",
						values: [this.state.niveau2],
					}
				);
			}
			if (this.state.niveau3 != null) {
				datas.push(
					{
						field: "niveau_3",
						values: [this.state.niveau3],
					}
				);
			}
			return datas;
		}

		let configElasticSearch = {
			trackUrlState: false,
			initialState: { resultsPerPage: 28 },
			autocompleteQuery: buildAutocompleteQueryConfig(),
			apiConnector: connector,
			alwaysSearchOnInitialLoad: true,
			searchQuery: {
				result_fields: RESULT_FIELDS,
				facets: FACETS,
				filters: filters(),
				query: this.state.query
			}
		};
		//**************************************************************************** */

		//**************************************************************************** */
		// Methode du contexte ljc	LjcContext	
		const changeValues = (val1, val2, val3, query) => {
			if (this.state.niveau1 != val1 || this.state.niveau2 != val2 || this.state.niveau3 != val3) {
				let url = "";
				if (val1 != null && val2 != null && val3 != null) {
					url = `/articles/${val1}/${val2}/${val3}`
				}
				if (val1 != null && val2 != null && val3 == null) {
					url = `/articles/${val1}/${val2}`
				}
				if (val1 != null && val2 == null && val3 == null) {
					url = `/articles/${val1}`
				}
				this.setState({
					niveau1: val1,
					niveau2: val2,
					niveau3: val3
				}, () => {

				})
			}

			if (this.state.query != query && query != null) {
				this.setState({
					query: query
				})
			}
		}

		return (
				<Router>
					<div className="App">
						<LjcContext.Provider value={changeValues}  >
							<SearchProvider config={configElasticSearch}>
								<WithSearch
									mapContextToProps={({ wasSearched }) => ({ wasSearched })}
								>
									{({ wasSearched }) => {
										return (
											<ErrorBoundary>



												<Switch>
													{/* DIVERS *************************************************** */}
													{/* Connexion */}

													<HeaderAndRoute
														path="/callback"
														slide={{
															nom: "Page de callback",
															h1: "Page de callback",
															h2: "",
															h3: "",
															descriptionMeta: ""
														}}
														component={Callback}
													/>

													<HeaderAndRoute
														path="/livraison"
														slide={{
															nom: "Page de livraison",
															h1: "Page de livraison",
															h2: "",
															h3: "",
															descriptionMeta: "Page de livraison de la librairie Calvin"
														}}
														component={Livraison}
													/>
													<HeaderAndRoute
														path="/paiement"
														slide={{
															nom: "Page de paiement de commande",
															h1: "Page de paiement de commande",
															h2: "",
															h3: "",
															descriptionMeta: "Page de paiement de commande de la librairie Calvin"
														}}
														component={Paiement}
													/>

													<HeaderAndRoute
														path="/panier"
														slide={{
															nom: "Mon panier",
															h1: "Mon panier",
															h2: "",
															h3: "",
															descriptionMeta: "Panier de la librairie Calvin"
														}}
														component={Panier}
													/>

													<HeaderAndRoute
														path="/mon-compte"
														slide={{
															nom: "Espace mon compte",
															h1: "Espace mon compte",
															h2: "",
															h3: "",
															descriptionMeta: "Espace mon compte de la librairie Calvin"
														}}
														component={MonCompte}
													/>

													<HeaderAndRoute
														path="/article/:id"
														slide={{
															disable: true
														}}
														component={PageArticle}
													/>



													<HeaderAndRoute
														path="/articles/:niveau1/:niveau2/:niveau3"
														slide={{
															disable: true
														}}
														component={Articles}
													/>

													<HeaderAndRoute
														path="/articles/:niveau1/:niveau2"
														slide={{
															disable: true
														}}
														component={Articles}
													/>
													<HeaderAndRoute
														path="/articles/:niveau1"
														slide={{
															disable: true
														}}
														component={Articles}
													/>


													<HeaderAndRoute
														path="/articles"
														slide={{
															disable: true
														}}
														component={Articles}
													/>

													{/* ACTUALITES */}
													<HeaderAndRoute
														path="/actualites"
														component={Actualites}
														slide={{
															nom: "Page actualité",
															h1: "Page actualité",
															h2: "",
															h3: "",
															descriptionMeta: "Page des actualités de la librairie Calvin"
														}}
													/>

													{/* AFFICHAGE D'UN PODCAST */}
													<HeaderAndRoute
														path="/podcast/:idPodcast"
														component={Podcast}
														slide={{
															nom: "Afficher un podcast",
															h1: "",
															h2: "",
															h3: "",
															descriptionMeta: "Afficher un podcast"
														}}
													/>

													{/* NOS LIBRAIRIES */}
													<HeaderAndRoute
														path="/nos-librairies/:nom"
														component={NosLibrairies}
														slide={{
															nom: "Nos librairies",
															h1: "Nos librairies Alès et Rennes",
															h2: "",
															h3: "",
															descriptionMeta: "Liste des librairie Calvin de Alès et Rennes"
														}}
													/>

													{/* CONTACT */}
													<HeaderAndRoute
														path="/categories"
														component={AffichageCategories}
														slide={{
															nom: "Catégories",
															h1: "Catégories",
															h2: "",
															h3: "",
															descriptionMeta: "Liste des catégories"
														}}
													/>

													{/* CONTACT */}
													<HeaderAndRoute
														path="/contact"
														component={Contact}
														slide={{
															nom: "Page contact",
															h1: "Page contact",
															h2: "",
															h3: "",
															descriptionMeta: "Page contact"
														}}
													/>
													{/* HOME */}
													<HeaderAndRoute
														path="/"
														slide={{
															nom: "Site de la librairie Calvin",
															h1: "Site de la librairie Calvin",
															h2: "",
															h3: "",
															descriptionMeta: "La librairie Calvin est une librairie protestante"
														}}
														component={Home}
													/>
												</Switch>
											</ErrorBoundary>
										);
									}}
								</WithSearch>
							</SearchProvider>
							<Footer />
						</LjcContext.Provider>
						<div className="scroll-up">
							<a href="#totop">
								<i className="fa fa-angle-double-up" />
							</a>
						</div>
					</div>
				</Router>
		);
	}
}

export const loadUserByToken = token => {
	fetch(`${api.timediaUrl}/public/getUser`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			var authorities = [];
			json.data.authorities.forEach(function (authority) {
				authorities.push(authority.name);
			});
			Storage.setAuthoritiesJson(JSON.stringify(authorities));
			localStorage.setItem("user", JSON.stringify(json.data));
			document.location.reload();
		});
	});
};
