import React from "react";
import { Menu } from "./Menu/Menu";

class MenuWrapper extends React.Component {
	render() {
		return (
			<Menu
				header={this.props.header}
				items={[
					{
						rank: 1,
						theme: true,
						name: "Livres",
						link: "/articles",
						type: "liste",
						roles: ["ROLE_PUBLIC"],

					},
					{
						rank: 2,
						link: "/actualites",
						name: "Actualités",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 3,
						link: "/articles/Bibles-1",
						name: "Bibles",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 5,
						link: "/articles/Jeunesse-25",
						name: "Jeunesse",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 6,
						link: "/articles/Bonnes-Affaires-177",
						name: "Bonnes affaires",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 7,
						link: "/articles/Papeterie-3",
						name: "Papeterie",
						roles: ["ROLE_PUBLIC"]
					}
				]}
				path={this.props.path}
				hauteurMenuRechercher={this.props.header.state.hauteurMenuRechercher}
				opacityPetitMenu={this.props.header.state.opacityPetitMenu}
				positionMenu={this.props.header.state.positionMenu}
			/>
		);
	}
}

export default MenuWrapper;
