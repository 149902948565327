import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import * as couleurs from "../Couleurs"
import './ElementPanier.scss';
import { URL_IMAGE_PAR_DEFAUT } from "../Config"
import { Trash } from "../SVG/Svg"

export const ElementPanier = ({ data, removeArticle, updateQuantite }) => {
    const [qte, setQte] = useState(data.qte);

    const handleChangeQte = (event) => {
        let newValue = Number(event.target.value);
        console.log(newValue);
        if (newValue > data.qteEnStock) {
            newValue = data.qteEnStock;
        }
        if (newValue === 0 && data.qteEnStock > 0) {
            newValue = 1;
        }
        if(data.qteEnStock === 0){
            newValue = 0;
        }
        setQte(newValue);
        updateQuantite(data.idArticle, newValue);
    }

    const handleInputBlur = () => {
        console.log("on sort")
        updateQuantite(data.idArticle, qte);
    }

    return (
        <div className={"element-panier " + (data.disponibilite ? "" : "non-dipo")} >
            <Link className="contenu-image" to={`/article/${data.idArticle}`}>
                <div className="image-wrapper">
                    <img
                        className="img-fluid image"
                        style={{ margin: "auto" }}
                        src={data.imageItems ? data.imageItems
                            .filter((element, index) => index === 0)
                            .map(elem => elem.original) : ""}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = URL_IMAGE_PAR_DEFAUT;
                        }}
                        alt=""
                    />
                </div>
            </Link>
            <div className="contenu">
                <div className="titre">{data?.titre}</div>
                <div className="sous-titre">{data?.sousTitre}</div>
                <div className="affichagPrix">
                    <div className="prix">{data?.prixTotal} € {data?.montantRemiseStr !== "0" && <span className="ft-open-sans prix-avant-remise">{data?.prixAvantRemise}€</span>}</div>
                </div>
                {!data.disponibilite && <div className="titre-non-dispo">
                    Article non diponible !
                </div>}
            </div>
            <section>

                <div className="icone-wrapper" onClick={() => removeArticle(data.idArticle)}>
                    <Trash className="trash" height={20} color={couleurs.ROSE} />
                </div>
                <div className="qte">
                    <div className={"form-group"}>
                        <div className="input-group input-group-sm">
                            <input
                                type={"number"}
                                value={qte}
                                onChange={handleChangeQte}
                                className="form-control text-center"
                                id={"Id" + data.idArticle}
                                name={"Name"}
                                required
                                min="0"
                                onBlur={handleInputBlur}
                            />

                        </div>
                        <div className="reste">{data.qteEnStock - data.qte > 0 ? `reste ${data.qteEnStock - data.qte} en stock` : "Vous avez pris le dernier !"}</div>
                    </div>
                </div>
            </section>
        </div>
    );
}

