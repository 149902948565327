import React from "react";
import "./Svg.scss"


//****************************************************************************************************** */
// ARTICLES
export const IconeFavoris = ({ width, couleur, couleurFond, couleurHover, id }) => <svg
    id="iconeFavoris2"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="140 -70 1020 1020"
>

    <defs>
        <style dangerouslySetInnerHTML={{ __html: `.${id}icone-favoris2-1{clip-path:url(#icone-favoris2-clippath);}.${id}icone-favoris2-2{fill:none;}.${id}icone-favoris2-2,.${id}icone-favoris2-3{stroke-width:0px;}.${id}icone-favoris2-4{clip-path:url(#icone-favoris2-clippath-1);}.${id}icone-favoris2-3{}` }} />
        <clipPath id="icone-favoris2-clippath">
            <polygon className={`${id}icone-favoris2-2`} points="-448.91 994 1777.96 994 2753.91 -1158 -448.91 -661.9 -448.91 994" />
        </clipPath>
        <clipPath id={`icone-favoris2-clippath-1`}>
            <rect className={`${id}icone-favoris2-2`} x="317.5" y="202.9" width="648.5" height="566.06" />
        </clipPath>
    </defs>
    <g>
        <circle fill={couleurFond} cx="650" cy="440" r="480" stroke={couleurHover} strokeWidth="40" style={{ filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))" }} />
    </g>
    <g className={`${id}icone-favoris2-1`}><g className={`${id}icone-favoris2-4`}>
        <path fill={couleur} className={`${id}icone-favoris2-3`} d="m488.52,238.52c-74.65,0-135.41,60.71-135.41,135.36,0,98.32,61.85,163.97,288.63,349.11,226.79-185.14,288.63-250.8,288.63-349.11,0-74.65-60.77-135.36-135.41-135.36-68.39,0-104.06,40.68-132.68,73.4l-20.54,23.04-20.54-23.04c-28.62-32.72-64.29-73.4-132.68-73.4m153.22,530.44l-11.27-9.16c-246.25-200.62-312.99-271.22-312.99-385.93,0-94.28,76.75-170.97,171.03-170.97,78.8,0,123.35,44.72,153.22,78.4,29.87-33.68,74.42-78.4,153.22-78.4,94.28,0,171.03,76.7,171.03,170.97,0,114.7-66.74,185.31-312.99,385.93l-11.27,9.16Z" />
    </g>
    </g>
</svg >


// export const IconeFavoris = props => <svg width={props.width} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 13.5 13.1" style={{ enableBackground: 'new 0 0 13.5 13.1' }} xmlSpace="preserve">
//     <style type="text/css" dangerouslySetInnerHTML={{ __html: `\n\t\n\t\t.st0{clip-path:url(#SVGID_00000128457546326058558770000004379243965489352107_);fill:none;stroke:${props.couleur || "#952865"};stroke-width:0.705;stroke-miterlimit:10;}\n\t.st1{clip-path:url(#SVGID_00000128457546326058558770000004379243965489352107_);fill:${props.couleur || "#952865"};}\n` }} />
//     <g>
//         <defs>
//             <rect id="SVGID_1_" x="0.3" y="0.1" width="12.9" height="12.9" />
//         </defs>


//         <clipPath id="SVGID_00000127008401535491372650000010765324421979428232_">
//             <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
//         </clipPath>


//         <path style={{ clipPath: 'url(#SVGID_00000127008401535491372650000010765324421979428232_)', fill: props.couleurFond || "#952865" }} d="
//     M6.8,12.7c3.4,0,6.1-2.7,6.1-6.1s-2.7-6.1-6.1-6.1S0.6,3.2,0.6,6.6S3.4,12.7,6.8,12.7z" />



//         <path style={{ clipPath: 'url(#SVGID_00000127008401535491372650000010765324421979428232_)', fill: props.couleur || "#952865" }} d="M4.8,3.9
//     C3.8,3.9,3,4.7,3,5.7c0,1.3,0.8,2.1,3.7,4.5c2.9-2.4,3.7-3.2,3.7-4.5c0-1-0.8-1.7-1.7-1.7C7.8,3.9,7.4,4.4,7,4.9L6.8,5.2L6.5,4.9
//     C6.1,4.4,5.7,3.9,4.8,3.9 M6.8,10.7l-0.1-0.1c-3.2-2.6-4-3.5-4-5c0-1.2,1-2.2,2.2-2.2c1,0,1.6,0.6,2,1c0.4-0.4,1-1,2-1
//     c1.2,0,2.2,1,2.2,2.2c0,1.5-0.9,2.4-4,5L6.8,10.7z" />
//     </g>
// </svg>

export const Favoris = ({ width, couleur, id }) => <svg id="favoris" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="320 200 640 640">
    <defs>
        <style dangerouslySetInnerHTML={{ __html: `.${id}favoris-1{clip-path:url(#favoris-clippath);}.${id}favoris-2,.${id}favoris-3{fill:none;}.${id}favoris-2,.${id}favoris-4{stroke-width:0px;}.${id}favoris-3{stroke:${couleur || "#e73065"};stroke-miterlimit:10;stroke-width:31px;}.${id}favoris-4{fill:${couleur || "#e73065"};}` }} />
        <favoris-clippath id="favoris-clippath"><rect className="favoris-2" x={316} y={199} width={661} height={638} />
        </favoris-clippath>
    </defs>
    <g className={id + "favoris-1"}>
        <path className={id + "favoris-3"} d="m640.81,819.28c166.38.1,301.4-134.75,301.5-301.13.1-166.38-134.75-301.4-301.13-301.5-166.38-.1-301.35,134.75-301.45,301.13-.1,166.38,134.7,301.4,301.08,301.5Z" />
        <path className={id + "favoris-4"} d="m550.76,396.68c-44.55,0-80.83,36.23-80.83,80.83,0,58.62,36.93,97.83,172.28,208.33,135.35-110.5,172.28-149.71,172.28-208.33,0-44.61-36.28-80.83-80.83-80.83-40.8,0-62.08,24.27-79.19,43.79l-12.25,13.78-12.25-13.78c-17.12-19.52-38.4-43.79-79.19-43.79Zm90.74,326.12l-6.99-5.71c-153.85-125.33-195.51-169.44-195.51-241.02,0-58.95,47.91-106.86,106.8-106.86,49.2,0,75.55,21.76,94.2,42.8,18.65-21.04,48-42.8,97.2-42.8,58.89,0,106.8,47.91,106.8,106.86,0,71.59-41.65,115.7-195.51,241.02l-6.99,5.71Z" />
    </g>
</svg>

// export const IconeFavoris = props => <svg width={props.width} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 13.5 13.1" style={{ enableBackground: 'new 0 0 13.5 13.1' }} xmlSpace="preserve">
//     <style type="text/css" dangerouslySetInnerHTML={{ __html: `\n\t\n\t\t.st0{clip-path:url(#SVGID_00000128457546326058558770000004379243965489352107_);fill:none;stroke:${props.couleur || "#952865"};stroke-width:0.705;stroke-miterlimit:10;}\n\t.st1{clip-path:url(#SVGID_00000128457546326058558770000004379243965489352107_);fill:${props.couleur || "#952865"};}\n` }} />
//     <g>
//         <defs>
//             <rect id="SVGID_1_" x="0.3" y="0.1" width="12.9" height="12.9" />
//         </defs>
//         <clipPath id="SVGID_00000127008401535491372650000010765324421979428232_">
//             <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
//         </clipPath>
//         <path style={{ clipPath: 'url(#SVGID_00000127008401535491372650000010765324421979428232_)', fill: 'none', stroke: props.couleur || "#952865", strokeWidth: '0.705', strokeMiterlimit: 10 }} d="
// 		M6.8,12.7c3.4,0,6.1-2.7,6.1-6.1s-2.7-6.1-6.1-6.1S0.6,3.2,0.6,6.6S3.4,12.7,6.8,12.7z" />
//         <path style={{ clipPath: 'url(#SVGID_00000127008401535491372650000010765324421979428232_)', fill: props.couleur || "#952865" }} d="M4.8,3.9
// 		C3.8,3.9,3,4.7,3,5.7c0,1.3,0.8,2.1,3.7,4.5c2.9-2.4,3.7-3.2,3.7-4.5c0-1-0.8-1.7-1.7-1.7C7.8,3.9,7.4,4.4,7,4.9L6.8,5.2L6.5,4.9
// 		C6.1,4.4,5.7,3.9,4.8,3.9 M6.8,10.7l-0.1-0.1c-3.2-2.6-4-3.5-4-5c0-1.2,1-2.2,2.2-2.2c1,0,1.6,0.6,2,1c0.4-0.4,1-1,2-1
// 		c1.2,0,2.2,1,2.2,2.2c0,1.5-0.9,2.4-4,5L6.8,10.7z" />
//     </g>
// </svg>

export const IconeCommander = props => <svg width={props.width} viewBox="0 0 26 26">
    <style dangerouslySetInnerHTML={{ __html: `.com-cls-1{fill:none;}.commander-cls-3{fill:${props.couleur};}` }} />
    <g className="com-cls-2"><path style={{ fill: props.couleur }} className="commander-cls-3" d="M18.76,11.52h-1.51v-3.24c0-3.16-1.43-4.79-4.59-4.79s-4.5,1.67-4.5,4.83v3.24h-1.61v-3.45c0-3.99,2.12-6.22,6.11-6.22s6.14,2.17,6.1,6.39v3.24Z" />
        <path style={{ fill: props.couleur }} className="commander-cls-3" d="M5.86,8.61c-.47,0-.85,.38-.85,.85v10.19c0,.47,.38,.85,.85,.85h13.59c.47,0,.85-.38,.85-.85V9.46c0-.47-.38-.85-.85-.85H5.86Zm13.59,13.59H5.86c-1.41,0-2.55-1.14-2.55-2.55V9.46c0-1.41,1.14-2.55,2.55-2.55h13.59c1.41,0,2.55,1.14,2.55,2.55v10.19c0,1.41-1.14,2.55-2.55,2.55" />
    </g>
</svg>

//****************************************************************************************************** */
















export const IconeMenu = props => <svg width={props.width} id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.96 13.5">
    <defs>
        <style dangerouslySetInnerHTML={{ __html: ".cls-menu-1{fill:#ff0186;}" }} />
    </defs>
    <rect className="cls-menu-1" x=".21" y=".63" width={12} height="1.59" />
    <rect className="cls-menu-1" x=".21" y="4.6" width={12} height="1.59" />
    <rect className="cls-menu-1" x=".21" y="8.57" width={12} height="1.59" />
</svg>



export const Logo = props => <svg
    width={props.width}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="155 155 990 690">
    <linearGradient id={props.id + "-lineargradiant"} x1="633.48" y1="563.84" x2="562.85" y2="163.32" gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#ff4c32" />
        <stop offset=".18" stopColor="#fd4934" />
        <stop offset=".29" stopColor="#f7413b" />
        <stop offset=".38" stopColor="#ed3348" />
        <stop offset=".42" stopColor="#e72a51" />
        <stop offset=".53" stopColor="#e02855" />
        <stop offset=".57" stopColor="#cc225f" />
        <stop offset=".61" stopColor="#c01f66" />
        <stop offset=".65" stopColor="#bc1e69" />
        <stop offset=".65" stopColor="#bb1e69" />
        <stop offset=".74" stopColor="#882473" />
        <stop offset=".82" stopColor="#5f2a7a" />
        <stop offset=".89" stopColor="#422d80" />
        <stop offset=".95" stopColor="#313083" />
        <stop offset={1} stopColor="#2b3185" />
    </linearGradient>
    <g className="logo-calvin-cls-1">
        <path className="logo-calvin-cls-3" d="m923.18,640.31v-5.88h-34.26v-24.61h27.83v-5.97h-27.83v-23.78h32.88v-5.87h-39.86v66.12h41.24Zm-76.31-66.12h-6.98v66.12h6.98v-66.12Zm-60.43,5.87c9.83,0,15.7,5.24,15.7,13.77s-5.69,13.77-15.25,13.77h-14.14v-27.55h13.69Zm-20.66-5.87v66.12h6.98v-26.9h15.06l14.6,26.9h7.81l-15.52-27.91c8.27-2.02,14.51-8.55,14.51-18.56,0-12.3-8.54-19.65-22.5-19.65h-20.94Zm-35.07,0h-6.98v66.12h6.98v-66.12Zm-76.4,44.35c4.77-10.93,8.9-20.48,13.13-30.86,4.23,10.38,8.35,19.92,13.13,30.86h-26.26Zm-16.99,21.76h7.43l6.89-15.98h31.5l7.07,15.98h7.34l-30.02-67.22h-.18l-30.03,67.22h0Zm-44.54-60.24c9.83,0,15.71,5.24,15.71,13.77s-5.7,13.77-15.25,13.77h-14.14v-27.55h13.68Zm-20.66-5.87v66.12h6.98v-26.9h15.06l14.6,26.9h7.81l-15.52-27.91c8.27-2.02,14.51-8.55,14.51-18.56,0-12.3-8.55-19.65-22.5-19.65h-20.94Zm-67.5,34.8h17.82c6.8,0,11.94,5.04,11.94,12.58s-5.79,12.95-13.97,12.95h-15.79v-25.53h0Zm0-5.51v-23.51h13.59c8.36,0,13.41,4.77,13.41,12.4,0,6.05-3.4,10.01-7.62,11.11h-19.38Zm25.9,2.12c4.96-2.4,8.27-7.44,8.27-13.59,0-10.75-8.08-17.82-20.76-17.82h-20.2v66.12h22.77c12.21,0,20.94-7.16,20.94-18.36,0-7.99-5.05-14.14-11.02-16.34m-67.77-31.41h-6.98v66.12h6.98v-66.12Zm-34.62,66.12v-5.88h-29.11v-60.23h-6.99v66.12h36.1Z" />
        <g className="logo-calvin-cls-7">
            <text className="logo-calvin-cls-8" transform="translate(156.87 836)">
                <tspan x={0} y={0}>C</tspan>
            </text>
            <text className="logo-calvin-cls-8" transform="translate(344.39 836)">
                <tspan x={0} y={0}>A</tspan>
            </text>
            <text className="logo-calvin-cls-5" transform="translate(540.49 836)">
                <tspan x={0} y={0}>L</tspan>
            </text>
            <text className="logo-calvin-cls-6" transform="translate(676.02 836)">
                <tspan className="logo-calvin-cls-9" x={0} y={0}>V</tspan>
                <tspan className="logo-calvin-cls-10" x="204.23" y={0}>I</tspan>
            </text>
            <text className="logo-calvin-cls-6" transform="translate(977.72 836)">
                <tspan x={0} y={0}>N</tspan>
            </text>
        </g>
    </g>
    <g>
        <path className={props.id + "-calvin-cls-4"} d="m742.78,364.53l-42.19-71.57,12.81-23.6-16.88-44.76,37.64-6.34-15.55-10.7s-4.26-.55,4.13-13.13c8.39-12.58,14.45-23.77-20.5-27.5-34.95-3.73-106.15-5.46-148.37-2.03l-111.43,61.1s-29.59,15.96-25.4,29.48,24.51,13.91,17.15,19.3-13.44,10.05-13.44,10.05l30.31-6.52,4.19,1.86,36.12,122.33,52.44-50.72v8.36l16.79,65.29,128.35,89.98,13.72,30.85,4.78,4.53v-79.16l-4.29-6.66-31.35-28.48,31.12-46.97v-17.19l-31.42,13.56-14.17,6.35-17.12,26.71-95.03-69.23,28.31-30.06.7-11.88-18.52-57.67,121.63-21.67,16.63,40.91-13.82,23.6,43.68,72.15,18.99-.58Z">
        </path>
    </g>
</svg>

export const IconeLoupe = props => <svg width={props.width} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.1 14" style={{ enableBackground: 'new 0 0 14.1 14' }} xmlSpace="preserve">
    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{clip-path:url(#SVGID_00000178917810224395942860000003927694300703181495_);fill:#fff;}\n\t.loupe-st1{fill:#fff;}\n" }} />
    <g>
        <defs>
            <rect id="SVGID_1_" x="0.5" y="0.3" width="13.2" height="13.2" />
        </defs>
        <clipPath id="SVGID_00000098903380723224186140000012281114586402373250_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <path style={{ clipPath: 'url(#SVGID_00000098903380723224186140000012281114586402373250_)', fill: '#fff' }} d="M5.9,1.1
		c-2.6,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S8.5,1.1,5.9,1.1 M5.9,11.2c-3,0-5.4-2.4-5.4-5.4s2.4-5.4,5.4-5.4
		s5.4,2.4,5.4,5.4S8.9,11.2,5.9,11.2" />
    </g>
    <rect x="11.1" y="8.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.6795 11.5029)" className="loupe-st1" width="0.8" height="5.3" />
</svg>

export const IconeMonCompte = props => <svg
    width={props.width}
    version="1.1"
    id="iconeMonCompte"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="300 170 670 670">
    <g>
        <path style={{ fill: props.couleur || "#E94C32" }} className="mon_st1" d="M631.2,349.3c-27,0-49,21.7-49,48.4s21.9,48.4,49,48.4s49-21.7,49-48.4S658.2,349.3,631.2,349.3 M631.2,476.6
 c-43.8,0-81.2-35.7-81.2-80.2s36.1-80.2,81.2-80.2s81.2,35.7,81.2,80.2S675,476.6,631.2,476.6" />
        <path style={{ fill: props.couleur || "#E94C32" }} className="mon_st1" d="M526.8,652.4h210v-63.7c0-35.7-28.3-63.7-64.4-63.7h-81.2c-36.1,0-64.4,28-64.4,63.7L526.8,652.4L526.8,652.4
 z M752.3,684.3H510.1c-9,0-16.8-7.6-16.8-16.5v-80.3c0-52.2,43.8-95.5,96.6-95.5h81.2c52.8,0,96.7,43.3,96.7,95.5v80.3
 C767.8,676.7,761.3,684.3,752.3,684.3" />
        <path style={{ fill: props.couleur || "#E94C32" }} className="mon_st1" d="M631.2,214.2c-159.8,0-289.9,128.7-289.9,286.6s130.1,286.6,289.9,286.6s290-128.6,290-286.6
 S791,214.2,631.2,214.2 M631.2,819.3C453.4,819.3,309,676.7,309,500.9s144.3-318.5,322.2-318.5S953.4,325,953.4,500.9
 S809,819.3,631.2,819.3" />

    </g>
</svg>

export const IconePanier = props => <svg width={props.width} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 13.5 13.1" style={{ enableBackground: 'new 0 0 13.5 13.1' }} xmlSpace="preserve">
    <style type="text/css" dangerouslySetInnerHTML={{ __html: `\n\t.st0{clip-path:url(#SVGID_00000007405483332070223330000015838367712142061244_);fill:${props.couleur || "#952865"};}\n` }} />
    <g>
        <defs>
            <rect id="SVGID_1_" x="0.2" y="0.7" width={13} height="12.2" />
        </defs>
        <clipPath id="SVGID_00000134945762946244803540000017319544732880278935_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <path style={{ clipPath: 'url(#SVGID_00000134945762946244803540000017319544732880278935_)', fill: props.couleur || "#952865" }} d="M11.3,10.6H2.9
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2,0-0.4l0.7-1.8L2.6,1.4H0.2V0.7h2.7c0.2,0,0.4,0.1,0.4,0.3L4,8.3c0,0.1,0,0.1,0,0.2
		L3.5,9.8h7.8V10.6z" />
        <path style={{ clipPath: 'url(#SVGID_00000134945762946244803540000017319544732880278935_)', fill: props.couleur || "#952865" }} d="M3.7,8.7L3.6,7.9
		l8.9-1.8V3H3.3V2.2h9.5c0.2,0,0.4,0.2,0.4,0.4v3.8c0,0.2-0.1,0.3-0.3,0.4L3.7,8.7z" />
        <path style={{ clipPath: 'url(#SVGID_00000134945762946244803540000017319544732880278935_)', fill: props.couleur || "#952865" }} d="M11.7,10.6
		c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8C12.5,10.9,12.1,10.6,11.7,10.6 M11.7,12.9
		c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C13.2,12.2,12.5,12.9,11.7,12.9" />
        <path style={{ clipPath: 'url(#SVGID_00000134945762946244803540000017319544732880278935_)', fill: props.couleur || "#952865" }} d="M2.5,10.6
		c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8C3.3,10.9,2.9,10.6,2.5,10.6 M2.5,12.9c-0.8,0-1.5-0.7-1.5-1.5
		c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C4.1,12.2,3.4,12.9,2.5,12.9" />
    </g>
</svg>




export const IconeQuestion = ({ width, couleur }) => <svg width={width} id="Calque_1" viewBox="0 0 13.49 13.1">
    <defs><style dangerouslySetInnerHTML={{ __html: `.cls-1{fill:none;}.cls-2{clip-path:url(#clippath);}.question-cls-3{fill:${couleur};}` }} />
        <clipPath id="clippath"><rect className="cls-1" x=".44" y=".22" width="12.6" height="12.6" />
        </clipPath>
    </defs>
    <g className="cls-2">
        <path className="question-cls-3" d="M6.74,.91C3.64,.91,1.13,3.43,1.13,6.52s2.52,5.61,5.61,5.61,5.61-2.52,5.61-5.61S9.83,.91,6.74,.91m0,11.91C3.26,12.82,.44,9.99,.44,6.52S3.26,.22,6.74,.22s6.3,2.83,6.3,6.3-2.83,6.3-6.3,6.3" />
        <path className="question-cls-3" d="M6.37,9.12h.74v.74h-.74v-.74Zm-1.57-4.6c.1-.28,.24-.52,.43-.72,.19-.2,.42-.35,.69-.46,.27-.11,.58-.16,.93-.16,.27,0,.52,.04,.76,.12,.24,.08,.44,.2,.62,.35,.18,.15,.32,.34,.43,.56,.1,.22,.15,.47,.15,.76,0,.36-.08,.67-.24,.92-.16,.25-.36,.49-.6,.73-.2,.2-.36,.36-.48,.47-.12,.11-.21,.22-.27,.33-.06,.11-.1,.24-.12,.4s-.03,.26-.04,.55h-.61c0-.3,0-.42,.05-.62,.04-.2,.1-.38,.19-.54,.09-.16,.2-.32,.35-.46,.15-.15,.32-.32,.53-.5,.18-.17,.33-.35,.45-.56,.12-.21,.18-.43,.18-.69,0-.19-.04-.37-.11-.53-.07-.16-.17-.3-.3-.41-.13-.11-.27-.2-.44-.27s-.35-.1-.53-.1c-.26,0-.49,.05-.69,.14-.2,.09-.36,.21-.49,.37-.13,.16-.23,.34-.29,.56-.07,.21-.09,.39-.09,.64h-.61c0-.34,.05-.6,.15-.88" />
    </g>
</svg>


export const IconeFermer = ({ width, color }) => <svg viewBox="0 0 32 32" width={width} className="iconeFermer"> 
    <defs>
        <style dangerouslySetInnerHTML={{ __html: `.fermer-cls-1{fill:none;stroke:${color || "#000"};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}` }} />
    </defs>
    <title />
    <g id="cross">
        <line className="fermer-cls-1" x1={7} x2={25} y1={7} y2={25} />
        <line className="fermer-cls-1" x1={7} x2={25} y1={25} y2={7} />
    </g>
</svg>

// export const Fleche = ({ width, color }) => <svg width={width} aria-hidden="true" viewBox="0 0 63 99" focusable="false">
//     <defs>
//         <style dangerouslySetInnerHTML={{ __html: `.fleche{fill:${color || "#000"};}` }} />
//     </defs>
//     <path className="fleche" d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z" />
// </svg>

export const Fleche = ({ width, color }) => <svg width={width} version="1.1" id="Capa_r1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 306 306" style={{ enableBackground: 'new 0 0 306 306' }} xmlSpace="preserve">
    <g>
        <g id="keyboard-arrow-right">
            <polygon points="58.65,267.75 175.95,153 58.65,35.7 94.35,0 247.35,153 94.35,306 		" />
        </g>
    </g>
</svg>


export const FlecheLeft = ({ width, color }) => <svg width={width} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 306 306" style={{ enableBackground: 'new 0 0 306 306' }} xmlSpace="preserve">
    <g>
        <g id="chevron-left">
            <polygon points="247.35,35.7 211.65,0 58.65,153 211.65,306 247.35,270.3 130.05,153 		" />
        </g>
    </g>
</svg>

//#######################################################################################################
// PAGE ACCUEIL
export const BulleDialogue = ({ width }) => <svg
    id="bulle-dialogue"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="355 240 570 540"
    width={width}
>
    <defs>
        <style dangerouslySetInnerHTML={{ __html: ".bulle-dialogue-1{clip-path:url(#bulle-dialogue-clippath);}.bulle-dialogue-2{fill:none;}.bulle-dialogue-2,.bulle-dialogue-3{stroke-width:0px;}.bulle-dialogue-3{fill:#241c97;}" }} />
        <clipPath id="bulle-dialogue-clippath">
            <rect className="bulle-dialogue-2" x={359} y="247.82" width="564.55" height="531.18" />
        </clipPath>
    </defs>
    <g className="bulle-dialogue-1">
        <path className="bulle-dialogue-3" d="m641.28,278.5c-157.52,0-251.59,75.36-251.59,201.54,0,108.25,66.08,157.13,121.51,179.05l10.88,4.33-1.3,11.66c-3.08,27.69-9.19,51.31-18.63,71.54,56.68-7.06,89.7-35.32,108.03-59.54l4.85-6.37,10.27.43c5.29.22,10.62.43,15.99.43,227.07,0,251.59-140.96,251.59-201.54,0-126.19-94.08-201.54-251.59-201.54m-166.83,500.5h-30.16l17.77-24.4c13.43-18.42,22.23-41.95,26.74-71.63-83.85-37.44-129.78-109.11-129.78-202.93,0-143.26,108.16-232.22,282.27-232.22s282.27,88.96,282.27,232.22-105.52,232.22-282.27,232.22c-3.94,0-7.84-.09-11.7-.26-26.52,31.94-74.06,66.99-155.13,66.99" />
    </g>
</svg>

export const Cloche = ({ width }) => <svg id="cloche" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="395 300 490 450"><defs><style dangerouslySetInnerHTML={{ __html: ".cloche-1{clip-path:url(#cloche-clippath);}.cloche-2{fill:none;}.cloche-2,.cloche-3{stroke-width:0px;}.cloche-4{clip-path:url(#cloche-clippath-1);}.cloche-5{stroke:#241a97;stroke-miterlimit:10;stroke-width:7px;}.cloche-5,.cloche-3{fill:#241c97;}" }} /><cloche-clippath id="cloche-clippath"><rect className="cloche-2" x="405.34" y="305.88" width="470.79" height="442.12" transform="translate(-1.11 1.36) rotate(-.12)" /></cloche-clippath><cloche-clippath id="cloche-clippath-1"><rect className="cloche-2" x={400} y="305.88" width={480} height="452.12" /></cloche-clippath></defs><g className="cloche-1"><path className="cloche-5" d="m876.49,692.11c-89.64.19-101.6-82.82-113.23-163.13-12.54-86.84-24.42-168.91-122.87-168.7-98.49.21-109.98,82.33-122.15,169.22-11.29,80.36-22.9,163.42-112.54,163.61l-.05-25.77c62.76-.13,74.76-53.5,87.1-141.37,11.93-85.18,26.76-191.16,147.59-191.42,120.8-.26,136.11,105.66,148.4,190.79,12.71,87.82,24.93,141.13,87.69,141l.05,25.77Z" /><path className="cloche-5" d="m641.21,741.77c-37.64.08-68.33-30.48-68.41-68.12l25.77-.05c.05,23.45,19.14,42.45,42.58,42.4,23.45-.05,42.45-19.14,42.4-42.58l25.77-.05c.08,37.64-30.48,68.33-68.12,68.41" /></g><rect className="cloche-5" x="405.34" y="665.87" width="470.79" height="27.69" /><g className="cloche-4"><path className="cloche-3" d="m671.26,340.28c0,18.44-14.91,25.03-33.29,25.03s-33.29-6.59-33.29-25.03,14.91-33.39,33.29-33.39,33.29,14.95,33.29,33.39" /></g></svg>
export const Pouce = ({ width }) => <svg id="pouce" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="380 270 520 490"><defs><style dangerouslySetInnerHTML={{ __html: ".pouce-1{clip-path:url(#pouce-clippath);}.pouce-2{fill:none;}.pouce-2,.pouce-3{stroke-width:0px;}.pouce-3{fill:#241c97;}" }} /><pouce-clippath id="pouce-clippath"><rect className="pouce-2" x="384.99" y="273.63" width="510.21" height="478.37" /></pouce-clippath></defs><g className="pouce-1"><path className="pouce-3" d="m861.77,516.81c0,20.18-21.45,22.59-24.07,32.37-2.93,10.79,12.89,15.12,12.89,34.08,0,19.92-24.03,19.7-27.35,29.97-3.89,12.19,7.91,15.55,7.91,35.12,0,1.22-.13,2.4-.52,3.58-3.28,16.38-27.65,16.82-31.85,23.59-4.5,7.21.61,11.71.61,28.66,0,9.31-10.44,15.95-23.72,15.95h-151.5c-12.19,0-26.04-6.9-40.72-14.15-12.54-6.2-25.43-12.58-39.01-15.6v-169.33c39.58-30.27,90.87-75.27,110.7-130.4.26-.79.52-1.66.7-2.53l14.55-80.03c4.81-2.05,9.22-2.53,17.56-2.53,3.54,0,15.95,19.09,15.95,79.73,0,23.15-4.11,49.98-12.28,79.73h-3.93c-8.82,0-15.95,7.12-15.95,15.95s7.12,15.95,15.95,15.95h143.77c15.73,0,30.32,7.47,30.32,19.92m33.42,0c0-33.9-26.52-51.81-63.74-51.81h-106.86c7.43-29.23,11.18-56.01,11.18-79.73,0-92.27-25.99-111.62-47.84-111.62-15.07,0-25.43,1.57-39.97,10.18-4.02,2.36-6.77,6.33-7.6,10.88l-15.68,86.37c-16.86,45.39-60.72,84.49-96.2,111.92v227.43c12.23,0,26.08,6.86,40.76,14.11,17.34,8.56,35.25,17.47,54.91,17.47h151.5c31.85,0,55.66-25.25,55.66-47.84,0-4.37-.44-8.34-1.22-11.93,19.75-7.3,33.2-23.98,33.2-43.9,0-9.48-1.66-17.69-5.16-24.94,12.06-8.52,24.29-22.45,24.29-38.84,0-8.91-4.15-19.57-10.22-26.87,13.89-10.4,22.98-25.73,22.98-40.89" /><path className="pouce-3" d="m416.89,496.87v223.24h95.63l.04-223.24h-95.67Zm95.67,255.13h-95.67c-17.61,0-31.89-14.29-31.89-31.89v-223.24c0-17.61,14.29-31.89,31.89-31.89h95.67c17.61,0,31.89,14.29,31.89,31.89v223.24c0,17.61-14.29,31.89-31.89,31.89" /></g></svg>


//#######################################################################################################
// NOS LIBRAIRIES

export const Mail = ({ width, couleur }) => <svg id="mail" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="410 335 460 360"><defs><style dangerouslySetInnerHTML={{ __html: `.mail-1{clip-path:url(#mail-clippath);}.mail-2{fill:none;}.mail-2,.mail-3{stroke-width:0px;}.mail-3{fill:${couleur || "#241c97"}}` }} /><mail-clippath id="mail-clippath"><rect className="mail-2" x={417} y="338.04" width="446.47" height="350.76" /></mail-clippath></defs><g className="mail-1"><path className="mail-3" d="m832.63,624.32c0,18.57-15.05,33.68-33.62,33.68h-317.53c-18.57,0-33.68-15.1-33.68-33.68v-221.81c0-4.48.9-8.78,2.52-12.75l134.32,152.44c14.1,15.94,34.35,25.06,55.61,25.06s41.51-9.12,55.55-25.06l134.32-152.44c1.62,3.97,2.52,8.28,2.52,12.75v221.81Zm-33.62-255.43c2.63,0,5.2.34,7.66.9l-133.98,151.99c-8.22,9.34-20.03,14.66-32.45,14.66s-24.28-5.31-32.45-14.66l-133.98-151.99c2.46-.56,4.98-.9,7.66-.9h317.53Zm0-30.82h-317.53c-35.58,0-64.5,28.92-64.5,64.44v221.81c0,35.58,28.92,64.5,64.5,64.5h317.53c35.52,0,64.44-28.92,64.44-64.5v-221.81c0-35.52-28.92-64.44-64.44-64.44" /></g></svg>
export const Contact = ({ width, couleur }) => <svg id="contact" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="475 245 340 470"><defs><style dangerouslySetInnerHTML={{ __html: `.contact-1{clip-path:url(#contact-clippath);}.contact-2{fill:none;}.contact-2,.contact-3{stroke-width:0px;}.contact-3{fill:${couleur || "#241c97"}}` }} /><contact-clippath id="contact-clippath"><rect className="contact-2" x="483.41" y={250} width="321.89" height={460} /></contact-clippath></defs><g className="contact-1"><path className="contact-3" d="m737.23,678.58h-185.73c-20.21,0-36.69-16.47-36.69-36.69v-32.94h259.1v32.94c0,20.21-16.47,36.69-36.69,36.69m-185.73-397.21h185.73c20.21,0,36.69,16.47,36.69,36.69v259.45h-259.1v-259.45c0-20.21,16.47-36.69,36.69-36.69m185.73-31.4h-185.73c-37.53,0-68.08,30.55-68.08,68.08v323.84c0,37.53,30.55,68.08,68.08,68.08h185.73c37.53,0,68.08-30.55,68.08-68.08v-323.84c0-37.53-30.55-68.08-68.08-68.08" /><path className="contact-3" d="m640.07,326.6c6.94,0,12.58-5.64,12.58-12.58s-5.64-12.58-12.58-12.58-12.58,5.64-12.58,12.58,5.64,12.58,12.58,12.58" /><path className="contact-3" d="m640.07,628.48c-6.94,0-12.58,5.64-12.58,12.58s5.64,12.58,12.58,12.58,12.58-5.64,12.58-12.58-5.64-12.58-12.58-12.58" /></g></svg>
export const Adresse = ({ width, couleur }) => <svg id="adresse" width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="460 250 360 465"><defs><style dangerouslySetInnerHTML={{ __html: `.adresse-1{clip-path:url(#adresse-clippath);}.adresse-2{fill:none;}.adresse-2,.adresse-3{stroke-width:0px;}.adresse-3{fill:${couleur || "#241c97"}}` }} /><adresse-clippath id="adresse-clippath"><rect className="adresse-2" x="461.66" y={252} width="357.44" height="459.74" /></adresse-clippath></defs><g className="adresse-1"><path className="adresse-3" d="m640.39,283.93c-80.92,0-146.74,65.87-146.74,146.79,0,63.1,96.95,185.24,146.74,241.26,49.79-56.03,146.74-178.16,146.74-241.26,0-80.92-65.82-146.79-146.74-146.79m0,427.81h0c-4.45,0-8.67-1.83-11.72-5.11-6.8-7.31-167-180.83-167-275.91s80.17-178.72,178.72-178.72,178.72,80.17,178.72,178.72-160.2,268.59-167,275.91c-3.05,3.28-7.27,5.11-11.72,5.11" /><path className="adresse-3" d="m640.39,374.38c-26.72,0-48.48,21.75-48.48,48.52s21.75,48.48,48.48,48.48,48.48-21.75,48.48-48.48-21.75-48.52-48.48-48.52m0,128.98c-44.35,0-80.45-36.1-80.45-80.45s36.1-80.5,80.45-80.5,80.45,36.1,80.45,80.5-36.1,80.45-80.45,80.45" /></g></svg>

//#######################################################################################################
// PIED DE PAGE

export const Youtube = ({ width }) => <svg id="youtube" width={width} xmlns="http://www.w3.org/2000/svg" viewBox="385 255 510 510"><defs><style dangerouslySetInnerHTML={{ __html: ".youtube-1{fill:#241c97;}.youtube-1,.youtube-2{stroke-width:0px;}.youtube-2{fill:#fff;}" }} /></defs><path className="youtube-1" d="m892.4,511c0,139.18-112.82,252-252,252h0c-139.18,0-252-112.82-252-252h0c0-139.18,112.82-252,252-252h0c139.18,0,252,112.82,252,252h0Z" /><path className="youtube-2" d="m749.01,404.22h-217.2c-21.46,0-38.86,17.4-38.86,38.85v135.85c0,21.46,17.4,38.85,38.86,38.85h217.2c21.45,0,38.85-17.39,38.85-38.85v-135.85c0-21.45-17.4-38.85-38.85-38.85Zm-73.71,116.15l-60.4,30.18c-6.97,3.49-15.17-1.57-15.17-9.36v-60.37c0-7.79,8.2-12.85,15.17-9.37l60.4,30.19c7.72,3.85,7.72,14.87,0,18.74Z" /></svg>
export const X = ({ width }) => <svg id="x" width={width} xmlns="http://www.w3.org/2000/svg" viewBox="390 258 510 510"><defs><style dangerouslySetInnerHTML={{ __html: ".x-1{fill:#241c97;}.x-1,.x-2{stroke-width:0px;}.x-2{fill:#fff;}" }} /></defs><path className="x-1" d="m896.9,511.5c0,139.45-113.05,252.5-252.5,252.5h0c-139.45,0-252.5-113.05-252.5-252.5h0c0-139.45,113.05-252.5,252.5-252.5h0c139.45,0,252.5,113.05,252.5,252.5h0Z" /><path className="x-2" d="m680.84,488.3l102.14-109.25h-38.79l-80.17,85.75-61.39-85.75h-106.09l107.4,150.02-107.4,114.87h38.79l85.43-91.38,65.42,91.38h106.09l-111.42-155.64Zm-129.64-81.16h36.99l149.41,208.7h-36.99l-149.41-208.7Z" /></svg>
export const Instagram = ({ width }) => <svg id="instagram" width={width} xmlns="http://www.w3.org/2000/svg" viewBox="385 255 510 510"><defs><style dangerouslySetInnerHTML={{ __html: ".instagram-1{fill:#241c97;}.instagram-1,.instagram-2{stroke-width:0px;}.instagram-2{fill:#fff;}" }} /></defs><path className="instagram-1" d="m892.9,511.5c0,139.45-113.05,252.5-252.5,252.5h0c-139.45,0-252.5-113.05-252.5-252.5h0c0-139.45,113.05-252.5,252.5-252.5h0c139.45,0,252.5,113.05,252.5,252.5h0Z" /><path className="instagram-2" d="m708.91,379.05h-137.01c-35.3,0-63.94,28.64-63.94,63.94v137.01c0,35.3,28.64,63.94,63.94,63.94h137.01c35.3,0,63.94-28.64,63.94-63.94v-137.01c0-35.3-28.64-63.94-63.94-63.94Zm41.1,196.38c0,25.21-20.46,45.67-45.67,45.67h-127.88c-25.21,0-45.67-20.46-45.67-45.67v-127.88c0-25.21,20.46-45.67,45.67-45.67h127.88c25.21,0,45.67,20.46,45.67,45.67v127.88Z" /><path className="instagram-2" d="m640.53,442.99c-37.82,0-68.51,30.69-68.51,68.51s30.69,68.51,68.51,68.51,68.51-30.69,68.51-68.51-30.69-68.51-68.51-68.51Zm0,114.18c-25.16,0-45.67-20.51-45.67-45.67s20.51-45.67,45.67-45.67,45.67,20.51,45.67,45.67-20.51,45.67-45.67,45.67Z" /><circle className="instagram-2" cx="713.6" cy="438.43" r="13.7" /></svg>
export const Facebook = ({ width }) => <svg id="facebook" width={width} xmlns="http://www.w3.org/2000/svg" viewBox="395 260 505 505"><defs><style dangerouslySetInnerHTML={{ __html: ".facebook-1{fill:#241c97;}.facebook-1,.facebook-2{stroke-width:0px;}.facebook-2{fill:#fff;}" }} /></defs><path className="facebook-1" d="m898.9,513.5c0,138.35-112.15,250.5-250.5,250.5h0c-138.35,0-250.5-112.15-250.5-250.5h0c0-138.35,112.15-250.5,250.5-250.5h0c138.35,0,250.5,112.15,250.5,250.5h0Z" /><path className="facebook-2" d="m722.62,494.07l-4.17,33.51c-.7,5.6-5.45,9.81-11.07,9.81h-54.28v140.11c-5.73.52-11.53.78-17.39.78-13.11,0-25.91-1.31-38.28-3.81v-137.09h-41.75c-3.83,0-6.96-3.15-6.96-6.98v-41.93c0-3.84,3.13-6.98,6.96-6.98h41.75v-62.89c0-38.59,31.16-69.88,69.59-69.88h48.71c3.83,0,6.96,3.15,6.96,6.98v41.93c0,3.84-3.13,6.98-6.96,6.98h-34.8c-15.37,0-27.83,12.51-27.83,27.95v48.92h58.45c6.71,0,11.89,5.91,11.07,12.58Z" /></svg>



export const Trash = ({height, color}) => <svg  style={{cursor: "pointer"}} height={height || 300} xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 456 511.82"><path  d="M48.42 140.13h361.99c17.36 0 29.82 9.78 28.08 28.17l-30.73 317.1c-1.23 13.36-8.99 26.42-25.3 26.42H76.34c-13.63-.73-23.74-9.75-25.09-24.14L20.79 168.99c-1.74-18.38 9.75-28.86 27.63-28.86zM24.49 38.15h136.47V28.1c0-15.94 10.2-28.1 27.02-28.1h81.28c17.3 0 27.65 11.77 27.65 28.01v10.14h138.66c.57 0 1.11.07 1.68.13 10.23.93 18.15 9.02 18.69 19.22.03.79.06 1.39.06 2.17v42.76c0 5.99-4.73 10.89-10.62 11.19-.54 0-1.09.03-1.63.03H11.22c-5.92 0-10.77-4.6-11.19-10.38 0-.72-.03-1.47-.03-2.23v-39.5c0-10.93 4.21-20.71 16.82-23.02 2.53-.45 5.09-.37 7.67-.37zm83.78 208.38c-.51-10.17 8.21-18.83 19.53-19.31 11.31-.49 20.94 7.4 21.45 17.57l8.7 160.62c.51 10.18-8.22 18.84-19.53 19.32-11.32.48-20.94-7.4-21.46-17.57l-8.69-160.63zm201.7-1.74c.51-10.17 10.14-18.06 21.45-17.57 11.32.48 20.04 9.14 19.53 19.31l-8.66 160.63c-.52 10.17-10.14 18.05-21.46 17.57-11.31-.48-20.04-9.14-19.53-19.32l8.67-160.62zm-102.94.87c0-10.23 9.23-18.53 20.58-18.53 11.34 0 20.58 8.3 20.58 18.53v160.63c0 10.23-9.24 18.53-20.58 18.53-11.35 0-20.58-8.3-20.58-18.53V245.66z" /></svg>


export const Crayon = ({height, color}) => <svg aria-hidden="true" focusable="false" height={height} viewBox="0 0 24 24" ><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill={color} /></svg>

export const Plus = ({height, color}) => <svg height={height} version={1.0} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 1280" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
    <path d="M6055 11684 c-969 -75 -1819 -365 -2600 -889 -301 -201 -533 -393
-795 -655 -267 -267 -456 -498 -665 -810 -475 -712 -749 -1461 -857 -2342 -20
-162 -22 -229 -22 -588 0 -429 10 -570 65 -888 186 -1085 697 -2070 1479
-2852 267 -267 498 -456 810 -665 638 -425 1331 -698 2081 -819 328 -53 427
-60 849 -60 422 0 521 7 849 60 1997 322 3653 1780 4226 3720 89 302 145 576
187 916 20 162 22 229 22 588 0 429 -10 572 -65 888 -125 724 -395 1408 -795
2016 -811 1231 -2096 2071 -3543 2316 -292 49 -440 61 -816 64 -198 2 -382 2
-410 0z m795 -829 c931 -97 1798 -474 2490 -1082 832 -731 1354 -1705 1500
-2795 117 -881 -39 -1792 -444 -2593 -264 -520 -612 -968 -1056 -1358 -1345
-1181 -3290 -1440 -4896 -653 -889 435 -1606 1145 -2048 2027 -635 1265 -632
2750 8 4014 264 520 612 968 1056 1358 670 588 1501 959 2400 1072 293 37 694
41 990 10z" />
    <path d="M5715 9361 c-48 -22 -79 -54 -100 -103 -13 -33 -15 -158 -15 -1048
l0 -1010 -1012 0 c-963 0 -1015 -1 -1053 -19 -48 -22 -79 -54 -100 -103 -13
-32 -15 -122 -15 -678 0 -556 2 -646 15 -678 21 -49 52 -81 100 -103 38 -18
90 -19 1053 -19 l1012 0 0 -1012 c0 -963 1 -1015 19 -1053 22 -48 54 -79 103
-100 32 -13 122 -15 680 -15 602 0 646 2 683 19 50 22 91 69 105 119 6 24 10
381 10 1040 l0 1002 1010 0 c890 0 1015 2 1048 15 49 21 81 52 103 100 17 38
19 79 19 685 0 604 -2 648 -19 685 -22 50 -69 91 -119 105 -24 6 -381 10
-1039 10 l-1003 0 0 1003 c0 658 -4 1015 -10 1039 -14 50 -55 97 -105 119 -37
17 -81 19 -685 19 -606 0 -647 -2 -685 -19z" />
  </g>
</svg>
