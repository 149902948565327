import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom'
import * as couleurs from "../Couleurs"
import './Newsletter.scss';
import { URL_IMAGE_PAR_DEFAUT } from "../Config"
import { Trash } from "../SVG/Svg"
import { InputText } from './InputTextNewsletter';
import { Chargement } from '../Chargement/Chargement';
import * as config from "../Config"

export const NewsLetter = () => {

    const [chargement, setChargement] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [resultRequest, setResultRequest] = useState(false);
    const [inputFields, setInputFields] = useState({
        email: ""
    });

    const [validFields, setValidFields] = useState({
        email: false
    });
    //---------------------------------------------------------------------------

    const handleChangeInput = useCallback(
        (name, value, valid) => {

            setInputFields({ ...inputFields, [name]: value });
            setValidFields({ ...validFields, [name]: valid });
            console.log(inputFields)
            console.log(validFields)
        },
        [inputFields],
    )


    const subscribeNewsletter = async () => {
        setChargement(true);
        const url = `${config.URL_BASE_TOOLS}/subscribe/${inputFields["email"]}`;
        const fetchData = async () => {
            fetch(url, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setChargement(false);
                    if (data.success) {
                        setInputFields({ ...inputFields, ["email"]: "" });
                        setValidFields({ ...validFields, ["email"]: "" });
                        setResultRequest(true);
                        console.log("inscription OK")
                    } else {
                        console.log("probleme lors de l'inscription")
                        setResultRequest(false);
                    }
                    setShowAlert(true);
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }


    return (
        <div className="newsletter">
            {chargement && <Chargement />}
            <div className="titre text-center tk-cabrito-didone-normal">Inscription à la newsletter</div>
            {(showAlert && resultRequest) && <div className="alerte-wrapper">
                <div class={"alerte alert alert-success"} role="alert">
                    Votre inscription a bien été prise en compte !
                </div>
            </div>}
            {(showAlert && !resultRequest) && <div className="alerte-wrapper">
                <div class={"alerte alert alert-danger"} role="alert">
                    Problème lors de votre inscription !
                </div>
            </div>}
            <div className="flex-container">
                <div className="flex-item">
                    <InputText
                        nom="email"
                        value={inputFields}
                        valid={validFields}
                        handleChangeInput={handleChangeInput}
                        required={true}
                        valCol="12"
                        mask="email"
                        type="email"
                        label="Email"
                    />
                </div>
                <div className="flex-item">

                    <button
                        className={"bouton bouton-" + (validFields["email"] ? "enabled" : "disabled")}
                        style={{ float: "right", backgroundColor: "#241c97" }}
                        onClick={() => {
                            if (validFields["email"]) {
                                subscribeNewsletter();
                            }
                        }}>
                        S'inscrire
                    </button>
                </div>
            </div>
        </div>
    );
}
