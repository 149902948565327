import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import './Livraison.scss';
import * as config from "../Config"
import { useAuth } from "react-oidc-context";
import { Crayon, Plus } from "../SVG/Svg"
import * as couleurs from "../Couleurs"
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { InputText } from "./InputText"
import Select from 'react-select';
import { ChoixLivraison } from './ChoixLivraison';
import { Chargement } from '../Chargement/Chargement';


const Livraison = () => {

    const [chargement, setChargement] = useState(true);
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [prefixTelLoaded, setPrefixTelLoaded] = useState(false);
    const [countries, setCountries] = useState([]);
    const [prefixTel, setPrefixTel] = useState([]);
    //--------------------------------------------------
    // Adresse du client
    const [adresseIsPresent, setAdresseIsPresent] = useState(false);
    const [inputFields, setInputFields] = useState({
        nom: "",
        prenom: "",
        adresse: "",
        ville: "",
        codePostal: "",
        pays: "fr",
        prefixTel: "fr",
        tel: ""
    });
    const [adresseFields, setAdresseFields] = useState({
        nom: "",
        prenom: "",
        adresse: "",
        ville: "",
        codePostal: "",
        pays: "fr",
        prefixTel: "fr",
        tel: ""
    });
    const [validFields, setValidFields] = useState({
        nom: false,
        prenom: false,
        adresse: false,
        ville: false,
        codePostal: false,
        tel: false
    });

    const [livreurSelected, setLivreurSelected] = useState(null);
    const [livreurCodeSelected, setLivreurCodeSelected] = useState(null);
    const [choixPointRelais, setChoixPointRelais] = useState(null);
    const [livreurs, setLivreurs] = useState([]);
    //
    const [datas, setDatas] = useState({});
    const [boutonEnable, setBoutonEnable] = useState(false);
    const [showModalAdresse, setShowModalAdresse] = useState(false);
    const [elemBasketDtos, setElemBasketDtos] = useState([]);
    //
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    useEffect((props) => {
        loadCountries();
        loadPrefixTel();
        console.log(inputFields)
        loadDelivery();
    }, []);






    const loadDelivery = async () => {
        const basket = localStorage.getItem('basket') !== null ? localStorage.getItem('basket') : '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}'
        const url = `${config.URL_BASE_DELIVERY}/deliveries`;
        const fetchData = async () => {
            fetch(url, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: basket
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;

                    console.log(data);
                    if (val !== null) {

                        //--------------------------------------------------------------------------
                        // load Adresse
                        const adresse = val.adresseDto;
                        if (adresse !== null) {
                            setInputFields(adresse);
                            setAdresseFields(adresse);
                            setValidFields({
                                nom: adresse.nom.length > 0 ? true : false,
                                prenom: adresse.prenom.length > 0 ? true : false,
                                adresse: adresse.adresse.length > 0 ? true : false,
                                ville: adresse.ville.length > 0 ? true : false,
                                codePostal: adresse.codePostal.length > 0 ? true : false,
                                tel: adresse.tel.length > 0 ? true : false
                            })
                            if (
                                adresse.nom.length > 0
                                && adresse.prenom.length > 0
                                && adresse.adresse.length > 0
                                && adresse.ville.length > 0
                                && adresse.codePostal.length > 0
                                && adresse.tel.length > 0) {
                                setAdresseIsPresent(true);
                            }
                            setChargement(false);
                            console.log(adresse);
                        } else {
                            setShowModalAdresse(true);
                        }
                        //--------------------------------------------------------------------------

                        setLivreurs(val.livreurs);
                        handleChangeLivreur(val.defaultLivreurSelected);
                        setLivreurCodeSelected(val.defaultCodeLivreurSelected);
                        if (
                            val.defaultCodeLivreurSelected !== null
                            && val.defaultCodeLivreurSelected !== "nd"
                            && val.livreurs.length > 0) {

                            if (val.defaultCodeLivreurSelected === "MON") {
                                if (choixPointRelais !== null) {
                                    handleChangeBoutonEnable(true);
                                } else {
                                    handleChangeBoutonEnable(false);
                                }
                            } else {
                                setBoutonEnable(true);
                                //handleChangeBoutonEnable(true);
                            }
                        }

                        //--------------------------------------------------------------------------
                        // chargement BASKET
                        const dataStr = JSON.stringify(val.basketDto);
                        if (dataStr !== undefined) {
                            localStorage.setItem("basket", JSON.stringify(val.basketDto));
                            if (val.basketDto.elemBasketDtos.length > 0 && val.basketDto.totalTTC !== "0") {
                                setDatas(val.basketDto);
                                setElemBasketDtos(val.basketDto.elemBasketDtos);
                            } else {
                                history.push("/");
                            }
                        } else {
                            console.log("le panier n'a pas été trouvé");
                            localStorage.setItem("basket", '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}');
                        }
                        //
                        //--------------------------------------------------------------------------



                        console.log(val);
                    } else {

                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }


    const handleChangeInput = useCallback(
        (name, value, valid) => {
            setInputFields({ ...inputFields, [name]: value });
            setValidFields({ ...validFields, [name]: valid });
            console.log(inputFields)
        },
        [inputFields],
    )

    const handleChangeBoutonEnable = (value) => {
            setBoutonEnable(value);
    }

    const handleChangeChoixPointRelais = (value) => {
        setChoixPointRelais(value);
        handleChangeBoutonEnable(true);
    }

    const saveAdresse = async () => {
        const data = JSON.stringify(inputFields);
        const urlAddBasket = `${config.URL_BASE_USER}/adresses/save`;
        const fetchData = async () => {
            fetch(urlAddBasket, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: data
            })
                .then((response) => response.json())
                .then((data) => {
                    setChargement(false);
                    const val = data.data;
                    if (val !== null) {
                        console.log(data.data)
                        setInputFields(data.data);
                        setAdresseFields(data.data);
                        setShowModalAdresse(false);
                        if (
                            val.nom.length > 0
                            && val.prenom.length > 0
                            && val.adresse.length > 0
                            && val.ville.length > 0
                            && val.codePostal.length > 0
                            && val.tel.length > 0) {
                            setAdresseIsPresent(true);
                        }
                        loadDelivery();
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const loadCountries = async () => {
        const urlAdresse = `${config.URL_BASE_USER}/pays`;
        const fetchData = async () => {
            fetch(urlAdresse, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    if (val !== null) {
                        setCountries(data.data)
                        setCountriesLoaded(true)
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const loadPrefixTel = async () => {
        const urlAdresse = `${config.URL_BASE_USER}/pays/prefix`;
        const fetchData = async () => {
            fetch(urlAdresse, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    if (val !== null) {
                        setPrefixTel(data.data)
                        setPrefixTelLoaded(true)
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const handleChangeLivreur = livreur => {
        setLivreurCodeSelected(livreur.code)
        setLivreurSelected(livreur);
    }

    const getMontantStr = () => {

        let centimes = 0;
        if (livreurSelected !== null) {
            centimes = livreurSelected?.livraisonGratuite ? 1 : livreurSelected?.montantLivraisonEnCentimes
        }
        centimes = centimes + datas?.montantTotalEnCentimes
        console.log(centimes);


        // Diviser les centimes par 100 pour obtenir les euros
        let euros = centimes / 100;
        // Vérifier si le montant est un entier ou non
        if (Number.isInteger(euros)) {
            // Si c'est un entier, retourner sans décimales
            return `${euros}`;
        } else {
            // Sinon, formater avec deux décimales
            return `${euros.toFixed(2).replace('.', ',')}`;
        }
    }

    const getStrFromCentimes = (centimes) => {

        // Diviser les centimes par 100 pour obtenir les euros
        let euros = centimes / 100;
        // Vérifier si le montant est un entier ou non
        // if (Number.isInteger(euros)) {
        //     // Si c'est un entier, retourner sans décimales
        //     return `${euros}`;
        // } else {
        //     // Sinon, formater avec deux décimales
        //     return `${euros.toFixed(2).replace('.', ',')}`;
        // }
        return `${euros.toFixed(2).replace('.', ',')}`;
    }

    return (
        <div className="livraison" style={{ minHeight: "780px" }}>
            {chargement && <Chargement />}
            <Modal show={showModalAdresse} /* onHide={() => setShowModalAdresse(false)} */ className='modal-lg modal-adresse' >
                <Modal.Header closeButton style={{ backgroundColor: "#e2e3e4" }}>
                    <Modal.Title><span style={{ color: "#111" }}>Adresse</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="form-row row">
                        <InputText
                            nom="nom"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="uppercase"
                            type="text"
                            label="Nom"
                        />
                        <InputText
                            nom="prenom"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="uppercase"
                            type="text"
                            label="Prénom"
                        />
                        <InputText
                            nom="adresse"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="12"
                            mask="uppercase"
                            type="text"
                            label="Adresse"
                        />
                        <InputText
                            nom="codePostal"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="uppercase"
                            type="text"
                            label="Code postal"
                        />
                        <InputText
                            nom="ville"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="uppercase"
                            type="text"
                            label="Ville"
                        />
                        <div className={'pb-3 form-group col-md-6'}>
                            <label
                                htmlFor={'pays'}
                                className={
                                    'col-form-label pl-3 pr-3 mb-2 text-white text-uppercase '
                                }
                                style={{ paddingLeft: "10px", paddingRight: "10px", fontWeight: "500", backgroundColor: "#e72461" }}
                            >
                                Pays
                            </label>

                            {countriesLoaded && <Select
                                className="select-country"
                                classNamePrefix="select"
                                defaultValue={
                                    countries.filter(option =>
                                        option.value === inputFields["pays"])
                                }
                                isSearchable={true}
                                name="pays"
                                options={countries}
                                onChange={(choice) => {
                                    console.log(choice.value)
                                    setInputFields({ ...inputFields, ["pays"]: choice.value });
                                }}
                            />}
                        </div>
                        <div className={'pb-3 form-group col-md-6'}></div>
                        <div className={'pb-3 form-group col-md-12'}></div>

                        <div className={'pb-3 form-group col-md-6'}>
                            <label
                                htmlFor={'pays'}
                                className={
                                    'col-form-label pl-3 pr-3 mb-2 text-white text-uppercase '
                                }
                                style={{ paddingLeft: "10px", paddingRight: "10px", fontWeight: "500", backgroundColor: "#e72461" }}
                            >
                                Préfixe tel
                            </label>

                            {prefixTelLoaded && <Select
                                className="select-tel"
                                classNamePrefix="select"
                                defaultValue={
                                    prefixTel.filter(option =>
                                        option.value === inputFields["prefixTel"])
                                }
                                isSearchable={true}
                                name="prefixTel"
                                options={prefixTel}
                                onChange={(choice) => {
                                    console.log(choice.value)
                                    setInputFields({ ...inputFields, ["prefixTel"]: choice.value });
                                }}
                            />}
                        </div>

                        <InputText
                            nom="tel"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="uppercase"
                            type="tel"
                            label="tel"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="footer-modal mb-3">

                        {validFields["nom"] &&
                            validFields["prenom"] &&
                            validFields["adresse"] &&
                            validFields["ville"] &&
                            validFields["codePostal"] &&
                            validFields["tel"]
                            && <button
                                className="btn btn-dark mr-3 bouton-valider"
                                style={{ float: "right", backgroundColor: "#241c97" }}
                                onClick={() => {
                                    saveAdresse();
                                }}>
                                Enregistrer mon adresse
                            </button>}
                    </div>
                </Modal.Footer>
            </Modal>






            <div
                className="container-fluid no-gutters"
                style={{ minHeight: "580px" }}
            >
                <div className="entete">
                    <div className="retour-wrapper">
                        <Link to="/panier">
                            <img src="https://storage.jeancalvin.fr/back.svg" />
                            <span className="texte" >Panier</span>
                        </Link>
                    </div>
                    <div className="titre">Livraison</div>
                </div>

                <div className="container pb-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="adresse">
                                <div className="entete-adresse">

                                    <div className="titre">
                                        Mon adresse
                                    </div>
                                    <span className='crayon-wrapper' onClick={() => setShowModalAdresse(true)}>
                                        <span className="icone-crayon">
                                            {!adresseIsPresent ? <Plus height={25} color={couleurs.BLEU} /> : <Crayon height={25} color={couleurs.ROSE} />}
                                        </span>
                                        <span className="texte">
                                            {!adresseIsPresent ? "Ajouter" : "Modifier"}
                                        </span>
                                    </span>
                                </div>
                                <div className="contenu-adresse text-uppercase">
                                    {!adresseIsPresent ? <div className="text-center" style={{}}>Veuillez ajouter votre adresse<br /><br /></div> :
                                        <>{adresseFields["prenom"]} {adresseFields["nom"]}<br />
                                            {adresseFields["adresse"]}, {adresseFields["codePostal"]} {adresseFields["ville"]} - {countries.filter(option => option.value === adresseFields["pays"])[0]?.label}<br />
                                            +{prefixTel.filter(option => option.value === adresseFields["prefixTel"])[0]?.indicatifTel} {adresseFields["tel"]}</>}
                                </div>
                            </div>
                            <ChoixLivraison
                                livreurSelected={livreurSelected}
                                handleChangeLivreur={handleChangeLivreur}
                                handleChangeBoutonEnable={handleChangeBoutonEnable}
                                adresseIsPresent={adresseIsPresent}
                                adresseFields={adresseFields}
                                livreurs={livreurs}
                                choixPointRelais={choixPointRelais}
                                handleChangeChoixPointRelais={handleChangeChoixPointRelais}
                                livreurCodeSelected={livreurCodeSelected}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="bloc-montant-wrapper">

                                <div className="bloc-montant">

                                    <div className="data">

                                        <div className="sous-total elem">
                                            <div className="titre">Sous-total</div>
                                            <div className="val">{getStrFromCentimes(datas?.montantTotalEnCentimes)} €</div>
                                        </div>
                                        <div className="montant-livraison elem">
                                            <div className="titre">Livraison</div>
                                            <div className="val">{getStrFromCentimes(livreurSelected?.livraisonGratuite === true ? 1 : livreurSelected?.montantLivraisonEnCentimes)} €</div>
                                        </div>
                                        <div className="elem" style={{ color: "#000" }}> <hr width="100%" />	</div>
                                        <div className="montant-total elem">

                                            <div className="titre">Total</div>
                                            <div className="val">{getMontantStr()} €</div>
                                        </div>
                                    </div>


                                    <div className="infos">
                                        Les prix sont indiqués avec TVA comprise
                                    </div>
                                    <div className="text-center pt-3">
                                        <button
                                            type="button"
                                            className={"text-uppercase bouton " + (boutonEnable ? "bouton-enable" : "bouton-disable")}
                                            onClick={() => {
                                                localStorage.setItem("codeLivreur", livreurCodeSelected);
                                                if (choixPointRelais !== null && livreurCodeSelected == "MON") {
                                                    localStorage.setItem("choixPointRelais", JSON.stringify(choixPointRelais));
                                                } else {
                                                    localStorage.removeItem("choixPointRelais");
                                                }
                                                history.push('/paiement')
                                            }}
                                        >
                                            Valider la livraison
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default withAuthenticationRequired(Livraison, {
    onBeforeSignin: () => {
        sessionStorage.setItem("callback", "panier") // sera lu ensuite dans le composant Callback, on retourne au panier après être authentifier
    },
    onRedirecting: () => <>
        <div className="container text-center" style={{ height: "580px" }}>
            <h1 style={{ color: "#111", fontSize: "2rem" }}>Redirection vers la page d'authentification</h1>
        </div>
    </>
});