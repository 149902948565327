
import React, { useState } from 'react';
import { useEffect } from 'react';
import { IconeFavoris } from "../SVG/Svg"
import "./BoutonFavoris.scss"
import * as couleurs from "../Couleurs"


export default class BoutonFavoris extends React.Component {

    state = {
        checked: false,
        hover: false
    };

    componentDidMount() {
        let favorisListe = localStorage.getItem('favoris');
        if (favorisListe != undefined) {
            let datas = JSON.parse(favorisListe);
            if (datas.find(element => element == this.props.id)) {
                this.setState({
                    checked: true
                })
            }
        }
    }

    handleChange = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            let favorisListe = localStorage.getItem('favoris');
            if (favorisListe != undefined) {
                let datas = JSON.parse(favorisListe);
                if (datas.find(element => element == this.props.id) && !this.state.checked) { // id existe dans la liste et décoché
                    for (var i = 0; i < datas.length; i++) {
                        if (datas[i] === this.props.id) {
                            datas.splice(i, 1);
                        }
                    }
                } else if (!datas.find(element => element == this.props.id)) {
                    datas.push(this.props.id);
                }
                localStorage.setItem('favoris', JSON.stringify(datas));
            } else {
                if (this.state.checked) {
                    console.log("checked")
                    let tableau = [];
                    tableau.push(this.props.id);
                    localStorage.setItem('favoris', JSON.stringify(tableau));
                }
            }
        })
    }

    render() {
        return <>
            <span className="bouton-favoris"
                onClick={this.handleChange.bind()}
                onMouseEnter={() => { this.setState({ hover: true }) }}
                onMouseLeave={() => { this.setState({ hover: false }) }}
            >
                <IconeFavoris
                    width={42}
                    couleur={this.state.checked ? "#fff" : couleurs.ROSE}
                    couleurFond={this.state.checked ? couleurs.ROSE : "#fff"}
                    id={"ic-" + this.props.id + "-"}
                    couleurHover={this.state.checked && couleurs.ROSE || (this.state.hover ? couleurs.ROSE : "#fff")}
                />
            </span>
        </>
    }
}