import React from "react";
import Page from "../Page";
import ElasticLjc from "../ElasticLjc/ElasticLjc";
import { ModuleCaroussel } from "../ModuleCaroussel/ModuleCaroussel";
import { api } from "@mitim/react-mitim"
import { Pub } from "../Pub/Pub"
import CarouselHome from "../CarouselHome/CarouselHome";
import * as couleurs from "../Couleurs"
import ListeLibrairies from "../ListeLibrairies/ListeLibrairies"
import { Cloche } from "../SVG/Svg";
import { URL_BASE_MEDIAS, URL_BASE_GLC } from "../Config"
import "./Home.scss";
import { NewsLetter } from "../Newsletter/Newsletter";

class Home extends Page {
	componentDidMount() {
		this.loadElements();
		this.loadNews();
	}

	loadElements = () => {
		fetch(`${URL_BASE_MEDIAS}/bandeaux`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			method: "GET"
		}).then(response => {
			if (response.status !== 200) {
				console.error("Error: " + response.status);
				return;
			}
			response.json().then(json => {
				this.setState({
					elements: json.data,
				}, () => {
				})
			});
		});
	}

	loadNews = () => {
		fetch(`${URL_BASE_GLC}/news`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			method: "GET"
		}).then(response => {
			if (response.status !== 200) {
				console.error("Error: " + response.status);
				return;
			}
			response.json().then(json => {
				this.setState({
					news: json.data,
				}, () => {
				})
			});
		});
	}

	state = {
		elementsALaUne: [],
		actus: [],
		elements: [],
		news: []
	};

	render() {
		const elements = this.state.elements;
		return (
			<>

				<div
					className="container-fluid no-gutters"
				>
					{elements.length > 0 && <CarouselHome elements={elements} />}
				</div>
				<ListeLibrairies />
				<div
					className="container-fluid no-gutters bg-gris8"
				>
					<div className="container pb-5 pt-2 nouveautes">

						<div className="titre">
							<span className="icone"><Cloche width={20} /></span>
							<h4 className="text-uppercase texteTitre" style={{ color: couleurs.BLEU }} >Nouveautés</h4>
						</div>
						<div className="">
							<ModuleCaroussel elements={this.state.news} className="p-5" />
						</div>
					</div>
				</div>
				<div className="container-fluid bg-gris8 pb-5">
					{elements.filter(data => data.nomBandeau === "LIVRE_HONNEUR").map(item => (
						<Pub key={item.id} img={item.couverture?.urlThumbOrig} titre="Livre à l'honneur" logo="" voirPlus={item.url} href={item.url} icone="pouce" />
					))}
					<div className="pt-5"></div>
					{elements.filter(data => data.nomBandeau === "COIN_JEUNESSE").map(item => (
						<Pub key={item.id} img={item.couverture?.urlThumbOrig} titre="Le coin jeunesse" logo="" voirPlus={item.url} href={item.url} icone="bulle" />
					))}
				</div>
			</>
		);
	}
}

export default Home;
