import React, { Component } from 'react';
import Moment from 'moment';

export class InputText extends Component {
	componentDidMount() {
		const val = this.props.value[this.props.nom]
		// this.props.handleChangeInput(
		// 	this.props.nom,
		// 	val,
		// 	this.check(val)
		// );
	}

	componentDidUpdate() {
	}

	state = {
		isValid: false,
		messageInvalid: 'Vous devez remplir ce champ',
		messageValid: 'Cela semble bon!',
		updated: false,
		passwordMin8Max64: false,
		passwordContient1Chiffre1Majuscule1Miniscule: false,
		passwordNoPrenomNomEmail: true
	};

	handleChange = event => {
		let valid = false;
		let val = event.target.value;
		//
		val = val.trimStart();
		const mask = this.props.mask || '';
		if (mask == 'uppercase') {
			val = val.toUpperCase();
		}
		if (this.props.type === "tel" || this.props.nom === "codePostal") {
			val = val.replace(/\D/g, '');
		}
		if (this.props.type === "tel" && val.length == 1 && val == 0) {
			val = "";
		}
		if (this.props.type === "tel" && val.length > 15) {
			val = "";
		}
		if (this.props.nom === "codePostal" && val.length > 15) {
			val = "";
		}
		if (val.length > 100) {
			val = "";
		}
		if (val.length > 60 && mask == 'text') {
			val = "";
		}


		//
		valid = this.check(val);
		this.props.handleChangeInput(this.props.nom, val, valid);
	};

	check = (val) => {
		let valid = false;
		if (this.props.nom === "email") {

			if(this.estAdresseEmailValide(val)){
				valid = true;
			} else {
				valid = false;
			}
		}

		if ((val.length > 1) && this.props.nom !== "new" && this.props.nom !== "email") {
			valid = true;
		}

		if (val.length > 1 && this.props.nom === "new") {
			let isTrue = true;
			//--------------------------------------------------
			// au moins 8 caracteres et moins de 64 carac
			if (val.length > 7 && val.length < 64) {
				this.setState({
					passwordMin8Max64: true
				})
			} else {
				isTrue = false;
				this.setState({
					passwordMin8Max64: false
				})
			}
			//--------------------------------------------------


			//--------------------------------------------------
			// ne contient pas les données de l'utilisateur
			if (
				val.toLowerCase().includes(this.props.userNom.toLowerCase())
				|| val.toLowerCase().includes(this.props.userPrenom.toLowerCase())
				|| val.toLowerCase().includes(this.props.userEmail.toLowerCase())
			) {
				isTrue = false;
				this.setState({
					passwordNoPrenomNomEmail: false
				})
			} else {
				this.setState({
					passwordNoPrenomNomEmail: true
				})
			}
			//--------------------------------------------------

			//--------------------------------------------------
			// Contient un chiffre, une majuscule et une miniscule
			if (this.contientMajusculeMinusculeEtChiffre(val)) {
				this.setState({
					passwordContient1Chiffre1Majuscule1Miniscule: true
				})
			} else {
				isTrue = false;
				this.setState({
					passwordContient1Chiffre1Majuscule1Miniscule: false
				})
			}
			//--------------------------------------------------

			if (
				isTrue
			) {
				valid = true;
			} else {
				valid = false;
			}

			//
		}

		return valid;
	};

	estAdresseEmailValide = email => {
		// Expression régulière pour valider une adresse email
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	}

	contientMajusculeMinusculeEtChiffre = chaine => {
		const majuscule = /[A-Z]/;
		const minuscule = /[a-z]/;
		const chiffre = /[0-9]/;

		return majuscule.test(chaine) && minuscule.test(chaine) && chiffre.test(chaine);
	}

	isValidForClass = () => {
		if (this.props.required) {
			if (this.props.valid[this.props.nom]) {
				return 'is-valid';
			} else {
				return 'is-invalid';
			}
		} else {
			return '';
		}
	};

	render() {
		return (
			<>
				<div className={'pb-3 form-group col-md-' + this.props.valCol || '6'}>
					<label
						htmlFor={'id' + this.props.nom}
						className={
							'col-form-label pl-3 pr-3 mb-2 text-white text-uppercase '
						}
						style={{ paddingLeft: "10px", paddingRight: "10px", fontWeight: "500", backgroundColor: "#e72461" }}
					>
						{this.props.label || this.props.nom}
					</label>
					<input
						type={this.props.type || 'text'}
						className={'form-control ' + this.isValidForClass()}
						id={'id' + this.props.nom}
						placeholder={
							(this.props.placeholder || this.props.label) +
							(this.props.required ? '*' : '')
						}
						onChange={this.handleChange}
						required={this.props.required || false}
						value={this.props.value[this.props.nom]}
						min={this.props.min || ''}
						max={this.props.max || ''}
						onKeyPress={e => {
							if (window.event.keyCode === 13) {
								// Si on appuie sur la touche entrée on ignore
								e.preventDefault();
							}
						}}
					/>
					{/* {(this.props.valid[this.props.nom] && this.props.type === "password") &&
						(this.props.required || false) && (
							<div className="valid-feedback">{this.state.messageValid}</div>
						)} */}
					{(!this.props.valid[this.props.nom] && this.props.type === "password" && this.props.nom === "new") &&
						(this.props.required || false) && (
							<div className="invalid-feedback">
								<ul style={{ color: "#000" }}>
									<li><div style={{ fontWeight: "400", color: this.state.passwordMin8Max64 ? "#198754" : "#F00" }}>8 caractères minimum</div></li>
									<li><div style={{ fontWeight: "400", color: this.state.passwordContient1Chiffre1Majuscule1Miniscule ? "#198754" : "#F00" }}>Contient un chiffre, une majuscule et une miniscule</div></li>
									<li><div style={{ fontWeight: "400", color: this.state.passwordNoPrenomNomEmail ? "#198754" : "#F00" }}>Ne doit pas contenir votre prénom, ni votre nom, ni votre adresse e-mail</div></li>
								</ul>
							</div>
						)}
				</div>
			</>
		);
	}
}