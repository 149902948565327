




export const RESULT_FIELDS = {
    "reference_fournisseur": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "auteurs": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "disponible_en_librairie": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "titre": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "isbn": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "disponibilite": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "poids": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "nbr_images": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "stock_stand": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "stock3": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "livraison_reduction": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "nombre_de_pages": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "niveau_1": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "niveau_2": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "niveau_3": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "stock10": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "publique": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "montant_remise": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "stock_externe": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "resume": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "stock_interne": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "presentation_auteur": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "tags_recherche": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "prix_achat": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "niveau_lecture": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "collection": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "taxe": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "prix_total": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "prix_total_avant_remise": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "stock153": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "type_stock": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    // "stock_web": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "avis": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "annee_parution": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "code_editeur": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "reliure": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "qualite": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    // "stock391": {
    //     "raw": {

    //     },
    //     "snippet": {
    //         "size": 100,
    //         "fallback": true
    //     }
    // },
    "sous_titre": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "etat": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    },
    "id": {
        "raw": {

        },
        "snippet": {
            "size": 100,
            "fallback": true
        }
    }
}

//****************************************************************************************************************** */

export const FACETS = {
    "niveau_1": {
        "type": "value",
        "size": 900
    },
    "niveau_2": {
        "type": "value",
        "size": 900
    },
    "niveau_3": {
        "type": "value",
        "size": 900
    },
    // "annee_parution": {
    //     "type": "value",
    //     "size": 900
    // },
    "auteurs": {
        "type": "value",
        "size": 900
    },
    // "type_stock": {
    //     "type": "value",
    //     "size": 900
    // },
    "code_editeur": {
        "type": "value",
        "size": 900
    },
    // "collection": {
    //     "type": "value",
    //     "size": 900
    // },
    // "disponibilite": {
    //     "type": "value",
    //     "size": 900
    // },
    "disponible": {
        "type": "value",
        "size": 900
    },
    "version_bible": {
        "type": "value",
        "size": 900
    },
    "etat": {
        "type": "value",
        "size": 900
    },
    // "disponible_en_librairie": {
    //     "type": "value",
    //     "size": 900
    // },
    // "nbr_images": {
    //     "type": "value",
    //     "size": 900
    // },
    // "niveau_lecture": {
    //     "type": "value",
    //     "size": 900
    // },
    // "nombre_de_pages": {
    //     "type": "value",
    //     "size": 900
    // },
    // "poids": {
    //     "type": "value",
    //     "size": 900
    // },

}