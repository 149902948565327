import React from "react";
//import Page from "../Page";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./AffichageCategories.scss";
import { Link, generatePath, } from "react-router-dom";
import { encodageUrl } from "../ElasticLjc/fonctions"
import {URL_BASE_GLC} from "../Config"

export default class AffichageCategories extends React.Component {


    constructor(props) {
        super(props);
    }

    state = {
        categories: []
    }
    componentDidMount() {
        //this.loadCategories();
    }

    loadCategories = () => {
        fetch(`${URL_BASE_GLC}/categories`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET"
        }).then(response => {
            if (response.status !== 200) {
                console.error("Error: " + response.status);
                return;
            }
            response.json().then(json => {
                this.setState({
                    categories: json.data
                }, () => {
                    console.log(this.state.categories)
                })

            });
        });
    }


    handleChange = FieldValue => {
        console.log(FieldValue);
    }




    render() {

        const Niveau1 = () => <>
            {this.state.categories.filter(data => (data.id == data.n1 && data.n2 == 0)).sort((a, b) => a.rang - b.rang).map(niveau1 => (
                <div className="col-2">
                    <Link to={`/articles/${encodageUrl(niveau1.nom)}-${niveau1.id}`} >{niveau1.nom}</Link>
                    <Niveau2 data={niveau1} />
                </div>
            ))}
        </>


        const Niveau2 = props => <ul>
            {this.state.categories.filter(data => (data.n1 === props.data.id && data.n2 != 0 && data.n3 == 0)).sort((a, b) => a.rang - b.rang).map(niveau2 => (
                <li>
                    <Link to={`/articles/${encodageUrl(props.data.nom)}-${props.data.id}/${encodageUrl(niveau2.nom)}-${niveau2.id}`} >{niveau2.nom}</Link>
                    <Niveau3 data={niveau2} n1={props.data} />
                </li>
            ))}
        </ul>

        const Niveau3 = props => <ul>
            {this.state.categories.filter(data => (data.n1 == props.data.n1 && data.n2 == props.data.id && data.n3 != 0)).sort((a, b) => a.rang - b.rang).map(niveau2 => (
                <li>
                    <Link to={`/articles/${encodageUrl(props.n1.nom)}-${props.n1.id}/${encodageUrl(props.data.nom)}-${props.data.id}/${encodageUrl(niveau2.nom)}-${niveau2.id}`} >{niveau2.nom}</Link>
                </li>
            ))}
        </ul>



        return (
            <>
                <div className="container-fluid pl-5 pr-5 affichage-categories">
                    <div className="row pt-5 pb-5 ">
                        <Niveau1 />
                    </div>
                </div>
            </>
        );
    }
}
