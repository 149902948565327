import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom"
import "./Menu.scss";
// import auth from "../Login/Auth";
import { auth } from "@mitim/react-mitim";
import Navigation from "../Navigation/Navigation";
import { MenuMobile } from "../MenuMobile/MenuMobile"
import {
	IconeMenu,
	Favoris,
	IconeMonCompte,
	IconePanier,
} from "../SVG/Svg";
import * as couleurs from "../Couleurs"
import { HeaderContext } from '../ContextList'; // Importer votre contexte
import { PROFIL } from '../Config'
import { useAuth } from "react-oidc-context";



const couleurElements = couleurs.ROSE;
const couleurElementsSelected = couleurs.BLEU;

// class Menu extends React.Component {
export const Menu = (props) => {

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [openedMenu, setOpenedMenu] = useState();
	const [currentScrollHeight, setCurrentScrollHeight] = useState(0);
	const [opacity, setOpacity] = useState("#f4f4f4");
	const [navigationVisible, setNavigationVisible] = useState(false);
	const [screen, setScreen] = useState(couleurElements);
	const [monCompte, setMonCompte] = useState(couleurElements);
	const [panier, setPanier] = useState(couleurElements);
	const [mesFavoris, setMesFavoris] = useState(couleurElements);

	const menuRef = useRef(null);

	const history = useHistory(); 
	const auth = useAuth();

	const toggleMenu = () => {
		setOpenedMenu(prevOpenedMenu => !prevOpenedMenu);
	};

	const closeMenu = () => {
		if (menuRef.current) {
			menuRef.current.close();
		}
	};

	// Effet pour fermer le menu lorsque openedMenu devient vrai
	useEffect(() => {
		if (JSON.parse(localStorage.getItem("authorities")) === null) {
			console.log("on est dedans");
			localStorage.setItem("authorities", JSON.stringify(["ROLE_PUBLIC"]));
			window.location.reload();
		}
	}, []);

	useEffect(() => {
		if (openedMenu) {
			closeMenu();
		}
	}, [openedMenu]);



	const dropdownToggleClickHandler = () => {
		toggleMenu();

		setDropdownOpen(prevDropdownOpen => !prevDropdownOpen);
		setOpenedMenu(false);
	};

	const closeDropdownHandler = () => {
		toggleMenu();
		setDropdownOpen(false);
		setOpenedMenu(false);
	};

	const openMenu = menu => {
		if (menu !== openedMenu) {
			openedMenu && openedMenu.close();
			setOpenedMenu(menu);
		}
	};

	const handleMenuClick = () => {
		setNavigationVisible(!navigationVisible);
		setScreen("computer");
	}

	const handleMenuMobile = () => {
		setNavigationVisible(!navigationVisible);
		setScreen("mobile");
	}

	const openNavigationVisible = () => {
		setNavigationVisible(true);
	}

	const closeNavigationVisible = () => {
		console.log("ON ferme")
		setNavigationVisible(false);
	}

	const { updateMenuFavorisOpen, menuFavorisOpen, listeFavoris } = useContext(HeaderContext);
	const currentUrl = window.location.pathname;
	let toolbarItems = props.items
		// .filter(u => auth.hasRoles(u.roles))
		.map(item => (
			<li
				key={item.rank}
				className="text-uppercase p-xl-3 p-2"
			>
				{item.theme === true && <span className="iconeMenuTheme" style={{ paddingRight: "7px" }}>
					<a
						style={{ cursor: "pointer" }}
						onClick={handleMenuClick.bind()}
					>
						<IconeMenu width={25} />
					</a>
				</span>}
				{item.theme === true ? // si theme = true c'est que c'est le bouton d'accès aux thèmes d'articles
					<a
						style={{ cursor: "pointer" }}
						onClick={handleMenuClick.bind()}
					// to={window.location.pathname}
					>
						{item.name}
					</a>
					: // on n'est pas sur le bouton Livres
					<Link
						className={currentUrl === encodeURI(item.link) ? "selected" : ""}
						to={item.link}
					>
						{item.name}
					</Link>}
			</li>
		));
	return (
		<div className="menu-wrapper">
			<MenuMobile
				handleMenuMobile={handleMenuMobile}
			/>
			<div className="menu-rechercher transition d-none d-xl-block" style={{ height: props.hauteurMenuRechercher + "px", position: "fixed" }}></div>
			<div className="navigation-wrapper" style={{ position: "relative" }}>
				<Navigation
					self={this}
					navigationVisible={navigationVisible}
					closeNavigationVisible={closeNavigationVisible}
					openNavigationVisible={openNavigationVisible}
					screen={screen}
					items={props.items}
				/>
			</div>

			<div className="fixe-view d-none d-xl-block" style={{ height: "55px" }}>

				<nav className="menu" style={{ position: props.positionMenu }}>
					<ul className="d-flex flex-row justify-content-center align-items-center">
						<span className="petit-menu" style={{ opacity: props.opacityPetitMenu * 2 }}>
							{PROFIL !== "venteDisabled" && <span className="p-1 select"
								onMouseEnter={() => {
									setMonCompte(couleurElementsSelected);
								}
								}
								onMouseLeave={() => {
									setMonCompte(couleurElements);
								}}
								onClick={() => {
									if (auth.isAuthenticated) {
										history.push('/mon-compte');
									} else {
										void auth.signinRedirect();
									}
								}}
							>
								<span className="elem">
									<IconeMonCompte width={35} couleur={monCompte} />
								</span>
							</span>}
							{PROFIL !== "venteDisabled" && <Link className="p-1 select"
								onMouseEnter={() => {
									setPanier(couleurElementsSelected)
								}
								}
								onMouseLeave={() => {
									setPanier(couleurElements)
								}}
								to="/panier"
							>
								<span className="elem">
									<IconePanier width={35} couleur={panier} />
								</span>
							</Link>}
							<span className="p-1 select"
								onMouseEnter={() => {
									setMesFavoris(couleurElementsSelected);
								}
								}
								onMouseLeave={() => {
									setMesFavoris(couleurElements);
								}}
								onClick={updateMenuFavorisOpen}
							>
								<span className="elem">
									<Favoris width={35} couleur={mesFavoris} id={"fav2-"} />
								</span>
							</span>
						</span>
						{toolbarItems}
					</ul>
				</nav>
			</div>
		</div>
	);

}

