import React from 'react';
import "./Gallerie.scss"
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";



const images = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
];


export class Gallerie extends React.Component {


    render() {

        return (
            <>
                <ImageGallery
                    items={this.props.items}
                    showThumbnails={this.props.items.length > 1 ? true : false}
                    showBullets={this.props.items.length > 1 ? true : false}
                    showNav={false}
                    showPlayButton={false}
                />
            </>
        );
    }
}

