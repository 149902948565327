import React from 'react';
import isExternal from 'is-url-external';
import {Link} from 'react-router-dom'

export const lienStorage = 'https://storage.timothee.fr/ftp/sites';
export const lienPhotos = `${lienStorage}/photos`;
export const lienIcons = `${lienStorage}/icons`;
export const lienWallpaperTaj = `${lienStorage}/wallpaper-taj`;
export const lienCalvinEdition = `${lienStorage}/calvinEdition`;
export const lienMediaSearch = `${lienStorage}/mediasearch`;



export const BLEU = "#7cb4d7"; // EXHORTATION
export const BLEU_FONCE = BLEU;
export const BLEU_TURQUOISE = "#39beb7"; // PARUTION
export const ORANGE = "#e46644"; // REVUE
export const ORANGE_CLAIR = "#ff9f1f"; // TÉMOIGNAGE
export const ORANGE2 = "#ee7840"; // 
export const JAUNE = "#e3af56";
export const JAUNE_CLAIR = "#ffd366"; // ALBUM
export const ROUGE = "#b11f27"; // MÉDITATION DU JOUR
export const ROSE = "#ff7359"; // LA BIBLE EN 1 AN
export const VERT = "#bee056"; // CANTIQUE
export const VERT_FONCE = "#87bc3d"; // LIVRE
export const VIOLET = "#b58390"; // CONFÉRENCE
export const MARRON = "#da9d4c"; // MESSAGE À LIRE




export const COULEUR_PREDICATION = BLEU;
export const COULEUR_EXHORTATION = BLEU;
export const COULEUR_PARUTION = BLEU_TURQUOISE;
export const COULEUR_LIVRE = VERT_FONCE;
export const COULEUR_CANTIQUE = VERT;
export const COULEUR_ALBUM = JAUNE_CLAIR;
export const COULEUR_MESSAGE_A_LIRE = MARRON;
export const COULEUR_TEMOIGNAGE = ORANGE_CLAIR;
export const COULEUR_REVUE = ORANGE2;
export const COULEUR_BIBLE_1_AN = ROSE;
export const COULEUR_MEDITATION_JOUR = ROUGE;
export const COULEUR_CONFERENCE = VIOLET;


// LISTE DES MEDIATYPES
export const EXHORTATION = 1;
export const CANTIQUE = 2;
export const TEMOIGNAGE = 3;
export const CONFERENCE = 4;
export const COUR_FORMATION = 5;
export const REVUE = 6;
export const ARTICLE_REVUE = 7;
export const ALBUM_CD = 8;
export const ALBUM_PHOTO = 9;
export const OUVRAGE = 10;
export const RECCUEIL = 11;
export const PAGE_SITE = 12;
export const BLOG = 13;
export const LECTURE = 14;
export const ACTUALITES = 15;




export const getColorByAuthorId = idAuthor => {
		switch (idAuthor) {
			case 1: // Daniel
				return ORANGE;
			case 2: // luc
				return BLEU;
			case 5: // Joseph ZEhr
				return JAUNE;
			case 9: // Daniel Loiret
				return VIOLET;
			case 27: // Stephane Zehr
				return VERT_FONCE;
			default:
				return MARRON;
		}
}

export const getColorOfMediaType = idMediaType => {
		switch (idMediaType) {
			case EXHORTATION:
				return COULEUR_EXHORTATION;
			case CANTIQUE:
				return COULEUR_CANTIQUE;
			case TEMOIGNAGE:
				return COULEUR_TEMOIGNAGE;
			case CONFERENCE:
				return COULEUR_CONFERENCE;
			// case COUR_FORMATION:
			// 	return BLEU;
			case REVUE:
				return COULEUR_REVUE;
			// case ARTICLE_REVUE:
			// 	return BLEU;
			case ALBUM_CD:
				return COULEUR_ALBUM;
			// case ALBUM_PHOTO:
			// 	return BLEU;
			// case OUVRAGE:
			// 	return BLEU;
			// case RECCUEIL:
			// 	return BLEU;
			// case RECCUEIL:
			// 	return BLEU;
			// case PAGE_SITE:
			// 	return COULEUR_CANTIQUE;
			// case BLOG:
			// 	return COULEUR_CANTIQUE;
			// case LECTURE:
			// 	return COULEUR_CANTIQUE;
			// case ACTUALITES:
			// 	return COULEUR_CANTIQUE;
			default:
				return ROSE;
		}
}





export const Bouton = props => <div className="text-center pt-5" style={{}}>
	{isExternal(props.lien) ? (
		<a
			href={props.lien}
			target="_blank"
			className="text-center mt-5 ft-lato-light text-uppercase"
			style={{
				fontSize: '1.1em',
				color: '#333',
				border: '1px solid #000',
				padding: '20px 60px 20px 60px'
			}}
		>
			{props.nom}
		</a>
	) : (
			<Link
				to={props.lien}
				className="text-center mt-5 ft-lato-light text-uppercase"
				style={{
					fontSize: '1.1em',
					color: '#333',
					border: '1px solid #000',
					padding: '20px 60px 20px 60px'
				}}
			>
				{props.nom}
			</Link>
		)}
</div>


export const titreSousTitre = (titre, sousTitre) => {
	return (
		<React.Fragment>
			<h1 className="contenu-h1 pt-5 text-uppercase">{titre}</h1>
			<h2 className="contenu-h2 pt-2 pb-5">{sousTitre}</h2>
		</React.Fragment>
	);
};



const lowerExceptions = [
	'a',
	'à',
	'le',
	'la',
	'au',
	'aux',
	'les',
	'de',
	'des',
	'du',
	'et',
	'en',
	'l',
	'd',
	'pas',
	'ne'
];
const upperExceptions = ['jc', 'tv'];

export const titleCase = str => {
	if (!str) {
		return str;
	}
	str = str.toLowerCase().replace(')(', ') (');
	let splitChars = [' ', "'", '’', '_', '.'];
	return str
		.split(splitChars[0])
		.map((word, index) => {
			return insideTitleCase(splitChars, 1, word, index);
		})
		.join(splitChars[0]);
};

const insideTitleCase = (splitChars, indiceSplitChar, word, index) => {
	if (
		index !== 0 &&
		lowerExceptions.includes(word.replace(/(\(|\)|'|’|\.)/g, ''))
	) {
		return word;
	}
	if (upperExceptions.includes(word.replace(/(\(|\)|'|’|\.)/g, ''))) {
		return word.toUpperCase();
	}
	if (indiceSplitChar === splitChars.length) {
		if (word.startsWith('(') && word.length >= 2) {
			return word[0] + word.charAt(1).toUpperCase() + word.slice(2);
		}
		return word.charAt(0).toUpperCase() + word.slice(1);
	}
	return word
		.split(splitChars[indiceSplitChar])
		.map(wordW => {
			return insideTitleCase(splitChars, indiceSplitChar + 1, wordW, index);
		})
		.join(splitChars[indiceSplitChar]);
};

export const NonBreakingSpace = number => '\u00A0'.repeat(number || 1);
