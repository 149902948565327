import React from "react";

export const DATA = [
	{
		id: 0,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/couv_lettre_4eme.jpg"
			},
			{
				nom: "Préface",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Lettres de Calvin-préface.pdf"
			},
			{
				nom: "Table des matières",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Lettres de Calvin-TM.pdf"
			}
		],
		prix: "10",
		couleurBloc: "jaune",
		couverture: "couv_calvin_lettres.jpg",
		titre: "Au milieu de tant d'épreuves",
		sousTitre: "Lettres de consolation",
		collection: "Réforme et réveil",
		dateParution: "06/07/2020",
		isbn: "978-2-492099-007",
		ean: "9782492099007",
		auteur: "Jean Calvin, Paul Wells (préface)",
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "160",
		poids: "158g",
		dimensions: "12cm x 19cm",
		epaisseur: "13mm",
		lienVideoYoutube: "https://www.youtube.com/embed/Nr3foAIzKmA",
		resume: (
			<>
				<p>
					Jean Calvin le réformateur fut avant tout un pasteur. Sa volumineuse
					correspondance en témoigne : il écrit à des hommes et des femmes de
					toutes conditions pour les exhorter à la foi. Ses lettres de direction
					spirituelle, qui impliquent une relation personnelle affectueuse avec
					ses destinataires, sont ainsi centrées sur l’édification et la
					consolation. Elles sont aussi un moyen important pour la diffusion de
					la doctrine réformée.
				</p>
				<p>
					Voilà pourquoi nous avons souhaité proposer au lecteur d’aujourd’hui
					cette sélection de 15 lettres traduites en français contemporain,
					introduites et annotées. Leur intérêt dépasse en effet les
					circonstances historiques de leur rédaction. Écrites pour consoler les
					croyants « sous la Croix », elles peuvent encore servir à ceux qui
					sont affligés ainsi qu’à ceux qui veulent les aider.
				</p>
			</>
		)
	},

	{
		id: 1,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/JPK/JPK-4eme-de-couv.jpg"
			},
			{
				nom: "Préface",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/JPK/JPK-préface.pdf"
			},
			{
				nom: "Présentation de la première édition",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/JPK/JPK-pres-premiere-edition.pdf"
			}
		],
		prix: "13,90",
		couleurBloc: "rouge",
		couverture: "JPK-couverture.jpg",
		titre: "Le salut ne vient pas d’Hitler",
		sousTitre: (
			<>
				Un mennonite déporté à Natzweiler
				<br /> et Buchenwald
			</>
		),
		collection: "Témoins",
		dateParution: "17/09/2020",
		isbn: "978-2-492099-014",
		ean: "9782492099014",
		auteur: (
			<>
				Jean-Paul Krémer,
				<br /> Frédérique Neau-Dufour (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "264",
		poids: "286g",
		dimensions: "13,5cm x 21cm",
		epaisseur: "18mm",
		lienVideoYoutube: "https://www.youtube.com/embed/gZ1lHL-guhM",
		// lienVideoYoutube: 'https://www.youtube.com/embed/Nr3foAIzKmA',
		resume: (
			<>
				<p>
					De Colmar à Buchenwald, en passant par le KL Natzweiler-Struthof,
					Jean-Paul Krémer témoigne de son expérience concentrationnaire. Agé de
					dix-sept ans, il refuse le salut hitlérien au lycée, la participation
					aux Jeunesses Hitlériennes et l’incorporation dans la Wehrmacht.
					Commence alors le cycle infernal de la répression, accepté par
					fidélité à ses convictions chrétiennes et traversé grâce au soutien de
					sa foi. Un document exceptionnel.
				</p>
			</>
		)
	},
	{
		id: 2,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/couv_seul-bienfait.jpg"
			},
			{
				nom: "Préface",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/le_bienfait_de_jesus_christ_crucifie_preface.pdf"
			}
			// {
			//     nom: 'Présentation de la première édition',
			//     url: 'https://storage.timothee.fr/ftp/sites/calvinEdition/livres/JPK/JPK-pres-premiere-edition.pdf'
			// }
			// {
			//     nom: 'Table des matières',
			//     url: ''
			// },
			// {
			//     nom: 'Préface',
			//     url: ''
			// },
			// {
			//     nom: 'Fiche article',
			//     url: ''
			// }
		],
		prix: "10",
		couleurBloc: "jaune",
		couverture: "1couv_seul-bienfait.jpg",
		titre: "Le bienfait de Jésus-Christ crucifié",
		sousTitre: (
			<>
				Un texte rescapé <br />
				des flammes de l'inquisition
			</>
		),
		collection: "Réforme & Réveil",
		dateParution: "03/05/21",
		isbn: "978-2-492099-038",
		ean: "9782492099038",
		auteur: (
			<>
				Benedetto da Mantova, Marcantonio Flaminio
				<br />
				Pietro Bolognesi (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "168",
		poids: "168g",
		dimensions: "12cm x 19cm",
		epaisseur: "13mm",
		lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Le bienfait de Jésus-Christ crucifié est un chef-d'œuvre de la Réforme
					italienne. Traqué par l'inquisition, rapidement traduit en français,
					réédité au moment du Réveil par Louis Bonnet, il expose ce qu'est la
					justification par la foi. C'est un texte enthousiaste et plein de
					force, nourri de Luther et de Calvin, qui fera sentir au lecteur toute
					l'actualité du message de la grâce.
					<br /> "Rien n'égalait l'ardeur que les amis de l'Evangile mettaient à
					lire et à répandre ce livre dans toute l'Italie, si ce n'est le zèle
					des inquisiteurs à le rechercher et à le livrer aux flammes." Louis
					Bonnet, 1858.
				</p>
			</>
		)
	},
	{
		id: 3,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/barioz_4_couv.jpg"
			},
			{
				nom: "Préface originale",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Chr-Med-préface_originale.pdf"
			},
			{
				nom: "Préface d'Alain-Cyril Barioz",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Chr-Med-préface_Barioz.pdf"
			}
			// {
			//     nom: 'Table des matières',
			//     url: ''
			// },
			// {
			//     nom: 'Préface',
			//     url: ''
			// },
			// {
			//     nom: 'Fiche article',
			//     url: ''
			// }
		],
		prix: "10",
		couleurBloc: "jaune",
		couverture: "barioz_couv.jpg",
		titre: "Chrétiennes méditations",
		sousTitre: <>Les psaumes pénitentiels</>,
		collection: "Réforme & Réveil",
		dateParution: "30/09/21",
		isbn: "978-2-492099-045",
		ean: "9782492099045",
		auteur: (
			<>
				Théodore de Bèze
				<br />
				Alain-Cyril Barioz (préface)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "144",
		poids: "140g",
		dimensions: "12cm x 19cm",
		epaisseur: "12mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Un des principaux mérites de la Réforme en Suisse et en France est
					d'avoir mis la Bible, et en particulier le livre des Psaumes, au cœur
					de la piété personnelle des croyants. Théodore de Bèze (1519-1605),
					sans doute le plus proche des compagnons de Calvin, a beaucoup
					contribué à la traduction des Écritures, en prose comme en vers.
					<br />
					<br />
					Les Chrétiennes méditations (1581) se situent au croisement des
					talents littéraires et théologiques de leur auteur. À partir de huit
					psaumes choisis (le psaume 1 et les sept psaumes dits
					traditionnellement pénitentiels), Théodore de Bèze renouvelle le genre
					ancien de la méditation. Si la dimension autobiographique est souvent
					sensible, l'itinéraire de repentance et de conversion qu'il propose
					peut néanmoins parler à chacun.
					<br />
					<br />
					Cette édition présente le texte de Bèze en français modernisé et le
					situe dans le contexte littéraire de l'époque. Le lecteur est ainsi à
					même d'apprécier les qualités esthétiques et doctrinales d'un des plus
					beaux exemples de la spiritualité du protestantisme réformé.
					<br />
					<br />
					Adaptation du texte original par Arthur Laisis et Caleb Abraham
				</p>
			</>
		)
	},
	{
		id: 4,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/couv_seuls_huguenot2.jpg"
			},
			{
				nom: "Avant-propos de Denis Vatinel",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Avant-propos-D-V.pdf"
			}
		],
		prix: "13,90",
		couleurBloc: "bleu",
		couverture: "couv_seuls_huguenot.jpg",
		titre: "Huguenot, galérien et martyr",
		sousTitre: <>Histoire des malheurs d'Isaac Le Febvre</>,
		collection: "Témoins",
		dateParution: "07/12/2021",
		isbn: "978-2-492099-021",
		ean: "9782492099021",
		auteur: (
			<>
				Etienne Girard
				<br />
				Denis Vatinel (avant-propos)
			</>
		),
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "328",
		poids: "340g",
		dimensions: "13.5cm x 21cm",
		epaisseur: "23mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Le témoignage d'Isaac Le Febvre est un des documents les plus précieux
					sur la vie des protestants français condamnés aux galères sous le
					règne de Louis XIV, coupables aux yeux du roi d’avoir assisté à une
					assemblée de culte interdite ou d’avoir tenté de quitter
					clandestinement le royaume.
					<br />
					<br />
					Jules Bonnet écrivait en 1882 dans le Bulletin de la Société de
					l’Histoire du Protestantisme Français :
					<br />
					Voici un de ces volumes que l’on ne peut ouvrir sans respect ni fermer
					sans émotion, véritable joyau de notre littérature réformée, où l’art
					disparaît pour laisser parler l’âme seule dans la sainteté de ses
					douleurs et la sublimité de ses sacrifices ; héroïsme bien supérieur à
					celui des champs de bataille et qui serait demeuré inconnu au siècle
					du grand roi, s’il n’eut pris soin d’en attiser la flamme au foyer
					dévasté de Port-Royal et de la Réforme. Autre cependant est la vertu
					qui se déploie au fond des cloîtres, autre celle qui s’exerce sur le
					banc des galères, et qui arrache ce cri d’admiration à un éloquent
					historien : « Oh ! Noble société que celle des galères. Il semble que
					toute vertu s’y fut réfugiée. Obscur ailleurs, là Dieu était visible.
					C’est là qu’il eut fallu amener toute la terre. »
					<br />
					<br />
					Avant-propos de Denis Vatinel, pasteur de l'Eglise protestante unie de
					France et conservateur du musée régional d'Histoire protestante du
					Bois-Tiffrais en Vendée.
				</p>
			</>
		)
	},
	{
		id: 5,
		fichiers: [
			{
				nom: "Quatrième de couverture",
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/couv-arriere-MDMF-HD.JPG"
			}
		],
		prix: "35",
		couleurBloc: "rouge",
		couverture: "couv-avant-MDMF-HD.JPG",
		titre: "Mémoires de mes fantômes",
		sousTitre: <>Ami Bost - John Bost - Les descendants</>,
		collection: "Histoire & Mémoire",
		dateParution: "25/02/2022",
		isbn: "978-2-492099-052",
		ean: "9782492099052",
		auteur: <>Charles-Marc Bost</>,
		editeur: "Calvin Éditions",
		typeReliure: "broché",
		nombrePages: "1020",
		poids: "1230g",
		dimensions: "16,3cm x 24cm",
		epaisseur: "44mm",
		//lienVideoYoutube: "https://www.youtube.com/embed/VfkKd8SkNdk",
		resume: (
			<>
				<p>
					Saga protestante, histoire du Réveil et roman-fleuve, Mémoires de mes
					fantômes retrace, à sa façon personnelle et puissamment évocatrice,
					les destinées de la famille Bost.
					<br />
					Laissant une grande part à la parole des acteurs, Charles-Marc Bost
					retrace la vie d'Ami, père de la lignée, "enfant terrible" du Réveil
					et celle de John, son fils, fondateur des Asiles de La Force et
					pionnier de la prise en charge du handicap. La saga est aussi celle
					des descendants parmi lesquels on trouve des pasteurs et des
					historiens (Charles Bost), des pionniers de l'Ouest américain
					(Théodore Bost), des écrivains et des artistes (Renaud). Des "dix
					fils" d'Ami est née une immense famille répartie sur plusieurs
					continents.
					<br />
					Parue en trois tomes (Ami et ses dix fils - John - Les descendants)
					l’œuvre de Charles-Marc Bost est aujourd'hui rééditée en un volume
					dans la collection "Histoire et Mémoire".
				</p>
			</>
		)
	},
	{
		id: 6,
		fichiers: [
			{
				nom: "Quatrième de couverture", //
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/couv_seuls_dupont2.jpg"
			},
			{
				nom: "Introduction de Stéphane Zehr", //
				url:
					"https://storage.timothee.fr/ftp/sites/calvinEdition/livres/Introduction-de-stephane-zehr.pdf"
			}
		],
		prix: "13,90", //
		couleurBloc: "jaune", //
		couverture: "couv_seuls_dupont.jpg",
		titre: "Le chemin d'espérance", //
		sousTitre: (
			<>
				Musique, occultisme et rédemption <br />
				dans la France du XXe siècle
			</>
		), //
		collection: "Témoins", //
		dateParution: "16/06/2022", //
		isbn: "978-2-492099-069", //
		ean: "9782492099069", //
		auteur: <>Maéli Dupont</>, //
		editeur: "Calvin Éditions", //
		typeReliure: "broché", //
		nombrePages: "336", //
		poids: "340g", //
		dimensions: "13,5cm x 21cm", //
		epaisseur: "23mm", //
		lienVideoYoutube: "https://www.youtube.com/embed/ME9K09kcrDU",
		resume: (
			<>
				<p>
					<span className="">
						“Il faut avoir vécu des jours douloureux comme nous en avions vécu,
						livrés à ce que nous pensions être un destin malchanceux contre
						lequel on ne peut rien, pour réaliser la valeur d’un secours
						spirituel inespéré.”
					</span>
					<br />
					<br />
					Maéli Dupont (1908-1991), ancienne résistante, a été musicienne,
					compositrice proche de Ravel et ambassadrice des Ondes Martenot (l’un
					des premiers instruments de musique électronique). Sa vie est d’abord
					placée sous le signe du voyage et de la réussite, puis marquée par des
					drames : mauvais mariages, déclassement, misère, angoisses liées à la
					pratique de l’art et de l’occultisme.
					<br />
					Son témoignage révèle une personnalité haute en couleur ainsi que
					l’envers de la vie d’artiste. Récit de femme, il donne surtout un
					visage à la France reléguée des années 1950-1960, “l’autre côté du
					paradis” des prétendues “Trente glorieuses”.
					<br />
					Pourtant, ce “destin malchanceux” la rend sensible à l’espérance de
					l’Évangile, lorsqu’elle lui est présentée par des missionnaires qui
					frappent à sa porte. Cette rencontre est l’occasion d’un nouveau
					départ.
					<br />
					<br />
					Introduction de Stéphane Zehr
					<br />
					<br />
					Suivi de : Musique que choisir ? Essai sur la relation entre la
					musique, la magie et le surnaturel à travers le monde, par Maéli
					Dupont.
				</p>
			</>
		)
	}
];
