import React, { useEffect, useState, useCallback, useRef } from 'react';
import './ChoixLivraison.scss';
import * as config from "../Config"
import { useAuth } from "react-oidc-context";
import Modal from "react-bootstrap/Modal";

export const ChoixLivraison = ({ handleChangeLivreur, handleChangeBoutonEnable, adresseIsPresent, adresseFields, livreurs, choixPointRelais, handleChangeChoixPointRelais, livreurCodeSelected }) => {
    const auth = useAuth();



    //MODAL POINT RELAIS
    const [showModalPointRelais, setShowModalPointRelais] = useState(false);



    useEffect((props) => {
        // loadDelivery();
    }, []);

    useEffect(() => {
        const handleMondialRelay = (event) => {
            if (event.data && event.data.type === 'mondial-relay') {
                const val = event.data;
                handleChangeChoixPointRelais(event.data.mondialRelais);
                setShowModalPointRelais(false);
                console.log(val)
            }
        };
        window.addEventListener('message', handleMondialRelay);

        return () => {
            window.removeEventListener('message', handleMondialRelay);
        };
    }, []);

    const getStrFromCentimes = (centimes) => {

        // Diviser les centimes par 100 pour obtenir les euros
        let euros = centimes / 100;
        // Vérifier si le montant est un entier ou non
        if (Number.isInteger(euros)) {
            // Si c'est un entier, retourner sans décimales
            return `${euros}`;
        } else {
            // Sinon, formater avec deux décimales
            return `${euros.toFixed(2).replace('.', ',')}`;
        }
    }



    const handleChangeLivreurIn = (livreur) => {
        if (livreur.code !== livreurCodeSelected) {
            if (livreur.code === "MON") {
                if (choixPointRelais !== null) {
                    handleChangeBoutonEnable(true);
                } else {
                    handleChangeBoutonEnable(false);
                }
            } else {
                handleChangeBoutonEnable(true);
            }

            handleChangeLivreur(livreur)
            if (livreur.code === "MON" && choixPointRelais === null) {
                setShowModalPointRelais(true);
            }
            console.log(livreur)
        }
    }

    return (
        <div className="choix-livraison" >


            <Modal show={showModalPointRelais} onHide={() => setShowModalPointRelais(false)} className='modal-xl' >
                <Modal.Header closeButton style={{ backgroundColor: "#e2e3e4" }}>
                    <Modal.Title><span style={{ color: "#111" }}>Choisissez un point relais</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#e2e3e4" }}>
                    {(livreurCodeSelected === "MON") && <>
                        <div style={{}} className='modal-mr-wrapper'>
                            <iframe
                                className=""
                                src={`/mondial-relais.html?codePostal=${adresseIsPresent ? adresseFields["codePostal"] : "30140"}&pays=${adresseIsPresent ? adresseFields["pays"].toUpperCase() : "FR"}`}
                                style={{ width: '100%', height: 610, border: 'none' }}
                            ></iframe>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#e2e3e4" }}>

                </Modal.Footer>
            </Modal>






            <div className="titre">Mode de livraison</div>

            {livreurCodeSelected === "nd" && <div className='no-delivery'>Nous somme désolé, mais nous ne pouvons pas vous livrer dans ce pays</div>}
            {livreurCodeSelected && livreurs
                .filter((data, index) => data.available === true)
                .sort((a, b) => a.montantLivraisonEnCentimes - b.montantLivraisonEnCentimes)
                .map(data => (
                    <div>

                        <section key={data.code} className={"element-mr mb-3 " + (livreurCodeSelected === data.code && "selected")} onClick={() => handleChangeLivreurIn(data)}>

                            <div className="entete">

                                <div className="item text-center">
                                    <img style={{ height: "50px" }} src={data.logoUrl} alt="" className='img-fluid text-center' />
                                </div>
                                <div className="item nom">
                                    {data.nom}<br />
                                    {(data.messageLivraisonGratuite !== null) && <span style={{ fontSize: "0.85rem", color: "#298a91", fontWeight: "500", lineHeight: "1rem", display: "block" }}>
                                        {data.messageLivraisonGratuite}
                                    </span>}
                                </div>
                                <div className="item">
                                    {data.description}
                                </div>
                                <div className="item">
                                    {(data.livraisonGratuite === true) && <span className='prixLivraisonBarre'>
                                        {getStrFromCentimes(data.montantLivraisonEnCentimes)} €
                                    </span>}
                                    {(data.livraisonGratuite === true) && <span>
                                        {'  '}0,01 €
                                    </span>}
                                    {(data.livraisonGratuite === false) && <span className=''>
                                        {getStrFromCentimes(data.montantLivraisonEnCentimes)} €
                                    </span>}

                                </div>
                            </div>
                            {(data.code === "MON" && livreurCodeSelected === data.code) && <>
                                {choixPointRelais !== null && <>
                                    <div className="data-choix-wrapper">
                                        <div className="point-relais-choisi">Point relais choisi</div>
                                        <div className="data-choix">
                                            {choixPointRelais.nom}<br />
                                            {choixPointRelais.adresse}<br />
                                            {choixPointRelais.cp} {choixPointRelais.ville} - {choixPointRelais.pays}
                                        </div>
                                    </div>
                                </>}
                                <div className="bouton text-center pb-3">

                                    {<button
                                        className="bouton-choix"
                                        onClick={() => {
                                            setShowModalPointRelais(true);
                                        }}>
                                        {choixPointRelais === null ? "Choisir un point relais" : "Changer de point relais"}
                                    </button>}

                                </div>
                            </>}
                        </section>
                    </div>
                ))}


        </div>
    );
};