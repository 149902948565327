import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import './Paiement.scss';
import * as config from "../Config"
import { useAuth } from "react-oidc-context";
import { Crayon, Plus } from "../SVG/Svg"
import * as couleurs from "../Couleurs"
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import { ChoixPaiement } from './ChoixPaiement';
import { Chargement } from '../Chargement/Chargement';


const Paiement = () => {

    const [showModal, setShowModal] = useState(false);
    const [messageModal, setMessageModal] = useState(<></>);
    const [modePaiement, setModePaiement] = useState("cb");
    const [chargement, setChargement] = useState(true);
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [prefixTelLoaded, setPrefixTelLoaded] = useState(false);
    const [countries, setCountries] = useState([]);
    const [prefixTel, setPrefixTel] = useState([]);
    const [order, setOrder] = useState(null);
    const [paid, setPaid] = useState(false);
    const [retourPaiementDto, setRetourPaiementDto] = useState(null);
    //--------------------------------------------------
    // Adresse du client
    const [adresseIsPresent, setAdresseIsPresent] = useState(false);
    const [adresseFields, setAdresseFields] = useState({
        nom: "",
        prenom: "",
        adresse: "",
        ville: "",
        codePostal: "",
        pays: "fr",
        prefixTel: "fr",
        tel: ""
    });


    const history = useHistory();

    const auth = useAuth();


    useEffect(() => {
        loadCountries();
        loadPrefixTel();
        loadOrder();
    }, [])

    const handlerChangeModePaiement = choix => {
        setModePaiement(choix);
    }


    const loadCountries = async () => {
        const urlAdresse = `${config.URL_BASE_USER}/pays`;
        const fetchData = async () => {
            fetch(urlAdresse, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    if (val !== null) {
                        setCountries(data.data)
                        setCountriesLoaded(true)
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const loadPrefixTel = async () => {
        const urlAdresse = `${config.URL_BASE_USER}/pays/prefix`;
        const fetchData = async () => {
            fetch(urlAdresse, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    if (val !== null) {
                        setPrefixTel(data.data)
                        setPrefixTelLoaded(true)
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }





    const loadOrder = async () => {

        const codeLivreur = localStorage.getItem("codeLivreur");
        if (codeLivreur === null) {
            history.push('/livraison')
        }

        let choixPointRelais = null;
        if (codeLivreur === "MON") {
            choixPointRelais = localStorage.getItem("choixPointRelais");
            if (choixPointRelais === null) {
                history.push('/livraison')
            }
        }

        const data = `{
        "codeLivraisonChoisi": "${codeLivreur}",
        "mondialRelayChoisiDto":${choixPointRelais}
        }`;
        console.log(data);

        const url = `${config.URL_BASE_ORDER}/orders/order`;
        // const url = `${config.URL_BASE_DELIVERY}/deliveries`;
        const fetchData = async () => {
            fetch(url, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: data
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;

                    console.log(data);
                    if (val !== null) {

                        if (val.paid === true) {
                            setMessageModal("La commande a déjà été payée, merci !");
                            setShowModal(true);
                            setPaid(true);
                            localStorage.removeItem("basket");
                            localStorage.removeItem("codeLivreur");
                        }

                        const adresse = val.adresseDto;
                        setOrder(val)
                        setAdresseFields(adresse);
                        setAdresseIsPresent(true);
                        setChargement(false);
                    } else {

                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const handleRetourErreurPaiement = async payload => {
        setMessageModal(payload.error.message);
        setShowModal(true);
        setPaid(false);
        setChargement(false);
    }

    const retourApresPaiement = async modePaiement => {
        setChargement(true);
        const url = `${config.URL_BASE_ORDER}/orders/payer/${modePaiement}`;
        const fetchData = async () => {
            fetch(url, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    setChargement(false);
                    console.log(data);
                    if (val !== null) {
                        console.log(val)

                        let texte = val.texte;
                        setMessageModal(texte)

                        setShowModal(true)
                        setRetourPaiementDto(val)
                        if (val.paid === true) {
                            setPaid(true)
                            localStorage.removeItem("basket");
                            localStorage.removeItem("codeLivreur");
                        }
                    } else {

                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const getStrFromCentimes = (centimes) => {
        // Diviser les centimes par 100 pour obtenir les euros
        let euros = centimes / 100;
        // Vérifier si le montant est un entier ou non
        // if (Number.isInteger(euros)) {
        //     // Si c'est un entier, retourner sans décimales
        //     return `${euros}`;
        // } else {
        //     // Sinon, formater avec deux décimales
        //     return `${euros.toFixed(2).replace('.', ',')}`;
        // }
        return `${euros.toFixed(2).replace('.', ',')}`;
    }


    const handleHideModal = () => {
        if (paid === true) {
            // la commande est déjà payée, on renvoie sur la page d'accueil
            history.push('/')
        } else {
            setShowModal(false);
            history.push('/paiement')
        }
    }


    return (
        <div className="paiement" style={{ minHeight: "780px" }}>

            {chargement && <Chargement />}
            <Modal show={showModal} onHide={() => { }} className='modal-lg modal-adresse' >
                <Modal.Header closeButton style={{ backgroundColor: "#e2e3e4" }}>
                    <Modal.Title><span style={{ color: "#111" }}>Paiement de la commande</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#e2e3e4", color: "#000", fontSize: "1.1rem", fontWeight: "400" }}>
                    {messageModal}

                    {retourPaiementDto?.modePaiement === "rib" && <div className="pt-5">
                        <div className="telecharger pb-5">
                            Vous pouvez télécharger notre RIB ici : <a target='_blank' href="https://storage.jeancalvin.fr/rib.pdf">télécharger</a>
                        </div>


                        <h4>RIB</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Code pays</th>
                                        <th scope="col">Code banque</th>
                                        <th scope="col">Code guichet</th>
                                        <th scope="col">Numéro de compte</th>
                                        <th scope="col">Clé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>FR</td>
                                        <td>30003</td>
                                        <td>03226</td>
                                        <td>00020041154</td>
                                        <td>10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">IBAN</th>
                                        <th scope="col">BIC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>R76 3000 3032 2600 0200 4115 410</td>
                                        <td>SOGEFRP</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="footer-bouton-wrapper">
                        <button onClick={() => handleHideModal()} class="bouton">Fermer</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <div
                className="container-fluid no-gutters"
                style={{ minHeight: "580px" }}
            >
                <div className="entete">
                    <div className="retour-wrapper">
                        <Link to="/livraison">
                            <img src="https://storage.jeancalvin.fr/back.svg" />
                            <span className="texte" >Livraison</span>
                        </Link>
                    </div>
                    <div className="titre">Paiement</div>
                </div>

                <div className="container pb-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="adresse">
                                <div className="entete-adresse">

                                    <div className="titre">
                                        Mon adresse
                                    </div>
                                </div>
                                <div className="contenu-adresse text-uppercase">
                                    {!adresseIsPresent ? <div className="text-center" style={{}}>Veuillez ajouter votre adresse<br /><br /></div> :
                                        <>{adresseFields["prenom"]} {adresseFields["nom"]}<br />
                                            {adresseFields["adresse"]}, {adresseFields["codePostal"]} {adresseFields["ville"]} - {countries.filter(option => option.value === adresseFields["prefixTel"])[0]?.label}<br />
                                            +{prefixTel.filter(option => option.value === adresseFields["prefixTel"])[0]?.indicatifTel} {adresseFields["tel"]}</>}
                                </div>
                            </div>
                            {order !== null && <ChoixPaiement
                                modePaiement={modePaiement}
                                handlerChangeModePaiement={handlerChangeModePaiement}
                                paymentIntent={order.intent}
                                amount={order?.montantTotalCentimes}
                                getStrFromCentimes={getStrFromCentimes}
                                retourApresPaiement={retourApresPaiement}
                                handleRetourErreurPaiement={handleRetourErreurPaiement}
                            />}
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="bloc-montant-wrapper">

                                <div className="bloc-montant">

                                    <div className="data">

                                        <div className="sous-total elem">
                                            <div className="titre">Sous-total</div>
                                            <div className="val">{getStrFromCentimes(order?.montantSousTotalCentimes)} €</div>
                                        </div>
                                        <div className="montant-livraison elem">
                                            <div className="titre">Livraison</div>
                                            <div className="val">{getStrFromCentimes(order?.montantLivraisonCentimes)} €</div>
                                        </div>
                                        <div className="elem" style={{ color: "#000" }}> <hr width="100%" />	</div>
                                        <div className="montant-total elem">
                                            <div className="titre">Total</div>
                                            <div className="val">{getStrFromCentimes(order?.montantTotalCentimes)} €</div>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        Les prix sont indiqués avec TVA comprise
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default withAuthenticationRequired(Paiement, {
    onBeforeSignin: () => {
        sessionStorage.setItem("callback", "panier") // sera lu ensuite dans le composant Callback, on retourne au panier après être authentifier
    },
    onRedirecting: () => <>
        <div className="container text-center" style={{ height: "580px" }}>
            <h1 style={{ color: "#111", fontSize: "2rem" }}>Redirection vers la page d'authentification</h1>
        </div>
    </>
});