import React from 'react';
import Gallery from 'react-photo-gallery';
// import { getGallery } from '../../api/api';
import { api } from '@mitim/react-mitim';
import Carousel, { Modal, ModalGateway } from 'react-images';

export default class ModuleGallerie extends React.Component {
	state = {
		gallery: [],
		currentImage: 0,
		viewerIsOpen: false
	};

	openLightbox(self, event) {
		this.setState({ currentImage: event.index });
		this.setState({ viewerIsOpen: true });
	}

	closeLightbox = () => {
		this.setState({ currentImage: 0 });
		this.setState({ viewerIsOpen: false });
	};

	componentDidMount() {
		const data = this.props.data;
		this.setState({
			gallery: data
		})
	}

	render() {
		return (
			<div className="pt-2">
				<Gallery
					photos={this.props.data}
					onClick={this.openLightbox.bind(this)}
				/>
				<ModalGateway>
					{this.state.viewerIsOpen && (
						<Modal onClose={this.closeLightbox.bind()}>
							<Carousel
								currentIndex={this.state.currentImage}
								views={this.props.data.map(x => ({
									...x
								}))}
							/>
						</Modal>
					)}
				</ModalGateway>
			</div>
		);
	}
}
