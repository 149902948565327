import React, { Component } from 'react';
import Moment from 'moment';

export class InputText extends Component {

	componentDidMount() {
		const val = this.props.value[this.props.nom]
	}

	componentDidUpdate() {
	}

	state = {
		isValid: false,
		messageInvalid: 'Vous devez remplir ce champ',
		messageValid: 'Cela semble bon!',
		updated: false,
		passwordMin8Max64: false,
		passwordContient1Chiffre1Majuscule1Miniscule: false,
		passwordNoPrenomNomEmail: true
	};

	handleChange = event => {
		let valid = false;
		let val = event.target.value;
		val = val.replace(" ", "");
		//
		val = val.trimStart();
		const mask = this.props.mask || '';

		val = val.toLowerCase();
		

		if (val.length > 100) {
			val = "";
		}

		//
		valid = this.check(val);
		
		this.props.handleChangeInput(this.props.nom, val, valid);
	};

	check = (val) => {
		let valid = false;
		if (this.props.nom === "email") {

			if(this.estAdresseEmailValide(val)){
				valid = true;
			} else {
				valid = false;
			}
		}

		return valid;
	};

	estAdresseEmailValide = email => {
		// Expression régulière pour valider une adresse email
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	}


	isValidForClass = () => {
		if (this.props.required) {
			if (this.props.valid[this.props.nom]) {
				return 'is-valid';
			} else {
				return 'is-invalid';
			}
		} else {
			return '';
		}
	};

	render() {
		return (
			<>
				<div className="form-group">
					<input
						type={this.props.type || 'text'}
						className={'form-control ' + this.isValidForClass()}
						id={'id' + this.props.nom}
						placeholder={
							(this.props.placeholder || this.props.label) +
							(this.props.required ? '*' : '')
						}
						onChange={this.handleChange}
						required={this.props.required || false}
						value={this.props.value[this.props.nom]}
						min={this.props.min || ''}
						max={this.props.max || ''}
						onKeyPress={e => {
							if (window.event.keyCode === 13) {
								// Si on appuie sur la touche entrée on ignore
								e.preventDefault();
							}
						}}
					/>
				</div>
			</>
		);
	}
}