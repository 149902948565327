import "./Panier.scss"
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom"
import * as couleurs from "../Couleurs"
import * as config from "../Config"
import { useAuth } from "react-oidc-context";


import { ElementPanier } from "./ElementPanier"
import { Chargement } from "../Chargement/Chargement";

export const Panier = () => {
    const [chargement, setChargement] = useState(true);
    const [datas, setDatas] = useState({});
    const [elemBasketDtos, setElemBasketDtos] = useState([]);
    const [boutonEnable, setBoutonEnable] = useState(false);
    const [stateLocalStorage, setStateLocalStorage] = useState(localStorage.getItem('basket'));

    const params = useParams();
    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
        auth.signinSilent();
        window.scrollTo(0, 0);
        sessionStorage.removeItem("callback");
        loadBasket();
    }, [auth.isAuthenticated]);

    useEffect(() => {
        localStorage.setItem('basket', stateLocalStorage)
    }, [stateLocalStorage]);

    const loadBasket = async () => {
        const urlAddBasket = `${config.URL_BASE_BASKET}/baskets/find`;
        const fetchData = async () => {
            fetch(urlAddBasket, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: getBasket()
            })
                .then((response) => response.json())
                .then((data) => {
                    updateDatas(data);
                })
        };
        fetchData();
    }

    const removeArticle = (id) => {
        const urlAddBasket = `${config.URL_BASE_BASKET}/baskets/remove/${id}`;
        fetch(urlAddBasket, {
            method: "PUT",
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: config.getToken(auth)
            },
            body: getBasket()
        })
            .then((response) => response.json())
            .then((data) => {
                updateDatas(data);
            });
    };

    const getBasket = () => {
        // const basket = localStorage.getItem('basket') !== null ? localStorage.getItem('basket') : '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}'
        let basket = stateLocalStorage !== null ? stateLocalStorage : '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}'
        if(basket == "undefined"){
            basket = '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}';
        }
        console.log(basket);
        return basket;
    }

    const updateDatas = (data) => {
        const dataStr = JSON.stringify(data.data);
        if (dataStr !== undefined) {

            setChargement(false);
            // localStorage.setItem("basket", JSON.stringify(data.data));

            setStateLocalStorage(JSON.stringify(data.data));
            setDatas(data.data);
            setElemBasketDtos(data.data.elemBasketDtos);
            if (data.data.elemBasketDtos.length > 0 && data.data.totalTTC !== "0") {
                setBoutonEnable(true);
            } else {
                setBoutonEnable(false);
            }
        } else {
            console.log("le panier n'a pas été trouvé");
        }
        // console.log(data.data);
    }

    const updateQuantite = (idArticle, newValue) => {
        const urlAddBasket = `${config.URL_BASE_BASKET}/baskets/updateQte/${idArticle}/${newValue}`;
        fetch(urlAddBasket, {
            method: "PUT",
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: config.getToken(auth)
            },
            body: getBasket()
        })
            .then((response) => response.json())
            .then((data) => {
                updateDatas(data);
            });
    }


    return (
        <div className="panier">
            {chargement && <Chargement />}
            <div
                className="container-fluid no-gutters"
                style={{ minHeight: "580px" }}
            >
                <div className="container pt-4 pb-5">
                    <div className="votre-panier">Votre panier</div>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            {elemBasketDtos?.length === 0 && <div className="panier-vide" >Le panier est vide</div>}
                            <div className="liste-articles" >
                                {elemBasketDtos?.sort((a, b) => b.rang - a.rang).map(elem => (
                                    <div key={elem.idArticle}>
                                        <ElementPanier
                                            data={elem}
                                            removeArticle={removeArticle}
                                            loadBasket={loadBasket}
                                            getBasket={getBasket}
                                            updateQuantite={updateQuantite}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="bloc-montant-wrapper">

                                <div className="bloc-montant">
                                    <div className="montant-total">
                                        Montant total {datas?.totalTTC} €
                                    </div>
                                    <div className="infos">
                                        Les prix sont indiqués avec TVA comprise
                                    </div>
                                    <div className="infos-livraison">
                                        Hors frais de livraison
                                    </div>
                                    <div className="text-center pt-3">
                                        <button
                                            type="button"
                                            className={"text-uppercase bouton " + (boutonEnable ? "bouton-enable" : "bouton-disable")}
                                            onClick={() => boutonEnable && history.push('/livraison')}
                                        >
                                            Passer à la livraison
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
