import React, { Component } from 'react';
import Moment from 'moment';

export class InputText extends Component {
	componentDidMount() {
		const val = this.props.value[this.props.nom]
		// this.props.handleChangeInput(
		// 	this.props.nom,
		// 	val,
		// 	this.check(val)
		// );
	}

	componentDidUpdate() {
	}

	state = {
		isValid: false,
		messageInvalid: 'Vous devez remplir ce champ',
		messageValid: 'Cela semble bon!',
		updated: false
	};

	handleChange = event => {
		let valid = false;
		let val = event.target.value;
		//
		val = val.trimStart();
		const mask = this.props.mask || '';
		if (mask == 'uppercase') {
			val = val.toUpperCase();
		}
		if (this.props.type === "tel") {
			val = val.replace(/\D/g, '');
		}
		if (this.props.type === "tel" && val.length == 1 && val == 0) {
			val = "";
		}
		if (this.props.type === "tel" && val.length > 15) {
			val = "";
		}
		if (this.props.nom === "codePostal" && val.length > 15) {
			val = "";
		}
		if (val.length > 100) {
			val = "";
		}


		//
		valid = this.check(val);
		this.props.handleChangeInput(this.props.nom, val, valid);
	};

	check = (val) => {
		let valid = false;
		if (val.length > 1) {
			valid = true;
		}
		return valid;
	};

	isValidForClass = () => {
		if (this.props.required) {
			if (this.props.valid[this.props.nom]) {
				return 'is-valid';
			} else {
				return 'is-invalid';
			}
		} else {
			return '';
		}
	};

	render() {
		return (
			<>
				<div className={'pb-3 form-group col-md-' + this.props.valCol || '6'}>
					<label
						htmlFor={'id' + this.props.nom}
						className={
							'col-form-label pl-3 pr-3 mb-2 text-white text-uppercase '
						}
						style={{ paddingLeft: "10px", paddingRight: "10px", fontWeight: "500", backgroundColor: "#e72461" }}
					>
						{this.props.label || this.props.nom}
					</label>
					<input
						type={this.props.type || 'text'}
						className={'form-control ' + this.isValidForClass()}
						id={'id' + this.props.nom}
						placeholder={
							(this.props.placeholder || this.props.label) +
							(this.props.required ? '*' : '')
						}
						onChange={this.handleChange}
						required={this.props.required || false}
						value={this.props.value[this.props.nom]}
						min={this.props.min || ''}
						max={this.props.max || ''}
						onKeyPress={e => {
							if (window.event.keyCode === 13) {
								// Si on appuie sur la touche entrée on ignore
								e.preventDefault();
							}
						}}
					/>
					{/* {this.props.valid[this.props.nom] &&
						(this.props.required || false) && (
							<div className="valid-feedback">{this.state.messageValid}</div>
						)}
					{!this.props.valid[this.props.nom] &&
						(this.props.required || false) && (
							<div className="invalid-feedback">
								{this.state.messageInvalid}
							</div>
						)} */}
				</div>
			</>
		);
	}
}