// Librairies
import React from 'react';
import { Link } from 'react-router-dom';
// CSS
import './Bouton.scss';
import isExternal from 'is-url-external';
import { tools } from '@mitim/react-mitim';
import * as couleurs from '../Couleurs'
import { IconeCommander } from "../SVG/Svg"
import { VenteContext } from '../ContextList';

export class Bouton extends React.Component {

    state = {
        couleurTheme: this.props.couleurTheme,
        color: "#fff"
    }

    render() {

        const { openModalCommander } = this.context;
        return (
            <div
                onMouseEnter={() => this.setState({ color: this.state.couleurTheme })}
                onMouseLeave={() => this.setState({ color: "#fff" })} className="boutonMaconry text-center"
                // onClick={() => {
                //     const id = 1;
                //     openModalCommander(id);
                // }}
                style={{
                    backgroundColor: this.state.couleurTheme,
                    color: this.state.color,
                    border: "1px solid " + this.props.couleurTheme
                }}
            >
                <span className='texte'>

                    <span className="icone"
                        style={{ opacity: this.props.icone ? "100%" : "0%" }}
                    >
                        <IconeCommander width={17} couleur={this.state.color} />
                    </span>
                    {this.props.texteBouton}
                </span>
            </div>
        );
    }
}
Bouton.contextType = VenteContext;
