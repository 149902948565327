import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./CarouselHome.scss"
import * as icones from "../ModuleCaroussel/Icones"
import * as couleurs from "../Couleurs"
import { Livrebleu, Micro, Calendrier, LivreGaucheNew, LivreDroiteNew, JeanCalvin, Cadre } from "./CarousselHomeSvg"
import isExternal from 'is-url-external';
import { Link } from 'react-router-dom';


const Elem = ({ couleurFond, titreEntete, couleurTitreEntete, titre1, titre2, titre3, couleurTitre3, couleurIcones, couverture, index, url, nomIcone }) => <>
    <Slide index={index} style={{ color: "#fff", height: "376px", backgroundColor: couleurFond }}>
        <div className='ft-lato carousel-element'>
            <div className="livre-gauche">
                <LivreGaucheNew width={300} color={couleurIcones} />
            </div>
            <div className="livre-droite">
                <LivreDroiteNew width={250} couleur={couleurIcones} />
            </div>
            {url === "" && <ContenuElem
                titre1={titre1 + url}
                titre3={titre3}
                couleurTitre3={couleurTitre3}
                titre2={titre2}
                titreEntete={titreEntete}
                couleurTitreEntete={couleurTitreEntete}
                couleurIcones={couleurIcones}
                couverture={couverture}
                nomIcone={nomIcone}
            />}
            {url !== "" && (isExternal(url) ?
                <a href={url}>
                    <ContenuElem
                        titre1={titre1}
                        titre3={titre3}
                        couleurTitre3={couleurTitre3}
                        titre2={titre2}
                        titreEntete={titreEntete}
                        couleurTitreEntete={couleurTitreEntete}
                        couleurIcones={couleurIcones}
                        couverture={couverture}
                        nomIcone={nomIcone}
                    />
                </a>
                :
                <Link to={url}>
                    <ContenuElem
                        titre1={titre1}
                        titre3={titre3}
                        couleurTitre3={couleurTitre3}
                        titre2={titre2}
                        titreEntete={titreEntete}
                        couleurTitreEntete={couleurTitreEntete}
                        couleurIcones={couleurIcones}
                        couverture={couverture}
                        nomIcone={nomIcone}
                    />
                </Link>
            )}
        </div>
    </Slide>
</>

const ContenuElem = ({ titre3, couleurTitre3, titre2, titreEntete, couleurTitreEntete, couleurIcones, couverture, titre1, nomIcone }) => <>
    <div className="contenu">
        <div className="item">
            <div className="image-wrapper">
                <div className="cadre-wrapper">
                    <Cadre width={200} color={couleurIcones} />
                </div>
                <img className="image" src={couverture} alt="" />
            </div>
        </div>
        <div className="item">
            {nomIcone === "livre" && <Livrebleu width={100} color={couleurIcones} />}
            {nomIcone === "calendrier" && <Calendrier width={120} color={couleurIcones} />}
            {nomIcone === "micro" && <Micro width={120} color={couleurIcones} />}
            {nomIcone === "jeanCalvin" && <JeanCalvin width={120} color={couleurIcones} />}
            <div style={{ color: couleurTitreEntete }} className="titreEntete text-uppercase tk-cabrito-didone-normal">{titreEntete}</div>
            <div className="texte">{titre1}
                <br />
                {titre2}</div>
            <div className="titre3" style={{ color: couleurTitre3 }}>
                {titre3}
            </div>
        </div>
    </div>
</>

export default class CarouselHome extends React.Component {

    render() {

        // const elements = this.state.elements;
        const elements = this.props.elements;

        return (
            <div className="carousel-home">


                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={20}
                    totalSlides={elements.filter(data => (data.nomBandeau === "SLIDE_ACCUEIL")).length}
                    isPlaying={true}
                    infinite={true}
                    interval={5000} // en ms
                >
                    <Slider>


                        {elements
                            .filter(data => (data.nomBandeau === "SLIDE_ACCUEIL"))
                            .map((item, index) =>
                                <Elem index={index}
                                    key={item.id}
                                    titreEntete={item.titreEntete || ""}
                                    couleurTitreEntete={item.couleurTitreEntete || ""}
                                    couleurFond={item.couleurFond || couleurs.VERT}
                                    titre1={item.titre1 || ""}
                                    titre2={item.titre2 || ""}
                                    titre3={item.titre3 || ""}
                                    couleurTitre3={item.couleurTitre3 || couleurs.ROSE}
                                    couleurIcones={item.couleurIcones || couleurs.ORANGE}
                                    url={item.url || ""}
                                    couverture={item.couverture?.urlThumb1024 || ""}
                                    nomIcone={item.nomIcone || ""}
                                />
                            )}
                        {/* <Elem index={1}
                            titreEntete={"Nouveauté"}
                            couleurTitreEntete={couleurs.BLEU}
                            couleurFond={couleurs.VERT}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                            couleurTitre3={couleurs.BLEU}
                            couleurIcones={couleurs.BLEU}
                        />
                        <Elem index={2}
                            titreEntete={"Conférence à venir"}
                            couleurTitreEntete={"#fff"}
                            couleurFond={couleurs.ORANGE}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                            couleurTitre3={couleurs.VERT}
                            couleurIcones={couleurs.BLEU}
                        />
                        <Elem index={3}
                            titreEntete={"Conférence à revoir"}
                            couleurTitreEntete={couleurs.VERT}
                            couleurFond={couleurs.BLEU}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                            couleurTitre3={couleurs.VERT}
                            couleurIcones={couleurs.BLEU}
                        />
                        <Elem index={4}
                            couleurTitreEntete={"#fff"}
                            titreEntete={"Nouveauté"}
                            couleurFond={couleurs.VERT}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                            couleurTitre3={"#fff"}
                            couleurIcones={couleurs.ROSE}
                        />
                        <Elem index={5}
                            couleurTitreEntete={"#fff"}
                            titreEntete={"Évènement"}
                            couleurFond={couleurs.ROSE}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                            couleurTitre3={"#fff"}
                            couleurIcones={couleurs.ROSE}
                        />
                        <Elem index={6}
                            couleurTitreEntete={couleurs.BLEU}
                            couleurFond={couleurs.ORANGE}
                            couleurTitre3={"#fff"}
                            couleurIcones={couleurs.ROSE}
                            titreEntete={"Article de blog"}
                            titre1="« Vous, vous ètes mes amis »"
                            titre2="L'amitié dans le nouveau testament"
                            titre3="Jeudi 7 mars 2024 | 20h30, Alès"
                        /> */}
                    </Slider>
                    <div className="boutons">

                        <ButtonBack className='carousel__back-button bouton-precedant bouton' >
                            <icones.precedant className="" color="#236" height="35" />
                        </ButtonBack>
                        <DotGroup className='dot-group' />
                        <ButtonNext className='bouton-suivant bouton' >
                            <icones.suivant className="" color="#236" height="35" />
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            </div>
        );
    }
}