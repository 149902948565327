import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { api } from "@mitim/react-mitim";

export default function CheckoutForm(props) {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const [disabled, setDisabled] = useState(true);
	// const [paymentIntent, setPaymentIntent] = useState("pi_3PaEDpE8tS6wz08w0YdnoX1K_secret_TV7hJTErh8SgNeiFiRNrVyvxJ");
	const stripe = useStripe();
	const elements = useElements();

	const cardStyle = {
		hidePostalCode: true,
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d"
				}
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		}
	};

	const handleChange = async event => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};


	// appelé après click sur paiement
	const handleSubmit = async ev => {
		ev.preventDefault();
		setProcessing(true);

		// const payload = await stripe.confirmCardPayment(paymentIntent, {
		// 	payment_method: {
		// 		card: elements.getElement(CardElement),
		// 		billing_details: {
		// 			name: ev.target.name.value
		// 		}
		// 	}
		// });


		const payload = await stripe.confirmCardPayment(props.paymentIntent, {
			payment_method: {
			  card: elements.getElement(CardElement),
			  billing_details: {
				name: 'Jenny Rosen',
			  },
			},
		  });




		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
			console.log(payload);

			props.handleRetourErreurPaiement(payload);
			// props.retourApresPaiement(
			// 	<>
			// 		Votre paiement n'a pas pu être effectué, veuillez réessayer plus
			// 		tard ou nous contacter via la page contact
			// 	</>
			// )
		} else {
			console.log(payload);


			props.retourApresPaiement("cb");

			
			
			// 	const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
			// const defaultHeaders = {
			// 	Accept: "application/json",
			// 	"Content-Type": "application/json",
			// 	Authorization: "Basic " + tokenPublic
			// };

		// 	let createPaymentResponse = {
		// 		paymentIntent: payload.paymentIntent.client_secret,
		// 		statusApresPaiement: payload.paymentIntent.status,
		// 		montantPaye: payload.paymentIntent.amount
		// 	};
		// 	fetch(`${api.timediaUrl}/public/updateStatusStripePayment`, {
		// 		method: "POST",
		// 		body: JSON.stringify(createPaymentResponse),
		// 		headers: defaultHeaders
		// 	})
		// 		.then(response => {
		// 			if (response.status !== 200) {
		// 				console.log("Error: " + response.status);
		// 				return;
		// 			}
		// 			response.json().then(data => {
		// 				// self.setState({ success: data.success, swow: true });
		// 				// self.resetFields();
		// 				console.log(data);
		// 				sessionStorage.setItem("idDonation", data.id);
		// 				this.setState({
		// 					messageEntete: "Sélectionnez votre moyen de paiement",
		// 					formulaireDonSubmited: true,
		// 					choixMenuFormulairePaiement: 2
		// 				});
		// 			});
		// 		})
		// 		.catch(err => {
		// 			console.log(err);
		// 		});

		// 	setError(null);
		// 	setProcessing(false);
		// 	setSucceeded(true);
		// 	props.self.setState(
		// 		{
		// 			messageModal: (
		// 				<>
		// 					Votre paiement a bien été effectué, vous recevrez un E-Mail de
		// 					confirmation.
		// 					<br />
		// 					Merci
		// 				</>
		// 			),
		// 			showModal: true,
		// 			formulaireDonSubmited: false,
		// 			reloadPage: true
		// 		},
		// 		() => {
		// 			sessionStorage.setItem("idDonation", "null");
		// 		}
		// 	);
		}
	};

	return (
		<>
			<form id="payment-form" onSubmit={handleSubmit}>
				<CardElement
					id="card-element"
					options={cardStyle}
					onChange={handleChange}
				/>

				<button
					disabled={processing || disabled || succeeded}
					id="submit"
					style={{}}
					className="mt-4"
				>
					<span id="button-text">
						{processing ? (
							<div className="spinner" id="spinner"></div>
						) : (
							<>Payer {props.getStrFromCentimes(props.amount)} €</>
						)}
					</span>
				</button>

				{/* Show any error that happens when processing the payment */}
				{/* {error && (
					<div className="card-error" role="alert">
						{error}
					</div>
				)} */}
				{/* Show a success message upon completion */}
				{/* <p className={succeeded ? "result-message" : "result-message hidden"}>
					Payment succeeded, see the result in your
					<a href={`https://dashboard.stripe.com/test/payments`}>
						{" "}
						Stripe dashboard.
					</a>{" "}
					Refresh the page to pay again.
				</p> */}
			</form>
		</>
	);
}
