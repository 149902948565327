import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'
import * as couleurs from "../Couleurs"
import './ElementPanierModal.scss';
import { URL_BASE_GLC, URL_IMAGE_PAR_DEFAUT } from "../Config"
import * as config from "../Config"



export const ElementPanierModal = ({ idElem }) => {

    const [element, setElement] = useState({});
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState(null);
    const [imageItems, setImageItems] = useState([]);

    useEffect(() => {
        //---------------------------------------------
        const url = `${URL_BASE_GLC}/articleDetail/${idElem}`;
        fetch(url, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setElement(data.data);
                setImageItems(data.data.imageItems);
            });

        //---------------------------------------------
    }, []);

    return (
        <div className="element-panier-modal" >
            <div className="contenu-image d-block text-center">
                <div className="image-wrapper">
                    <img
                        className="img-fluid image"
                        style={{ margin: "auto" }}
                        src={imageItems ? imageItems
                            .filter((element, index) => index === 0)
                            .map(elem => elem.original) : ""}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = URL_IMAGE_PAR_DEFAUT;
                        }}
                        alt=""
                    />
                </div>
            </div>
            <div className="contenu">

                <div className="titre">{element?.titre}</div>
                <div className="affichagPrix">


                    <div className="prix">{element?.prixTotal} € {element?.montantRemiseStr !== "0" && <span className="ft-open-sans prix-avant-remise">{element?.prixAvantRemise}€</span>}</div>
                </div>
            </div>
        </div>
    );
}

