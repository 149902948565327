// Librairies
import React from 'react';
import { Link } from 'react-router-dom';
// CSS
import './Maconerie.scss';
// import Masonry from 'react-masonry-component';
import Masonry from 'react-masonry-component';
import isExternal from 'is-url-external';
import { tools } from '@mitim/react-mitim';
import * as couleurs from '../Couleurs'
import {Bouton} from "./Bouton"

const masonryOptions = {
	transitionDuration: 0,
	columnWidth: 1
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };
export default class Maconerie extends React.Component {


	encart = ({
		couleurEncart,
		titreEntete,
		couverture,
		titre1,
		titre2,
		titre3,
		titre4,
		contenu,
		urlBouton,
		texteBouton,
		icone
	}) => <div className="elementMaconrie">
			<div
				className="entete"
				style={{ backgroundColor: couleurEncart || '#7cb4d7' }}
			>

				<h4 className="text-center pt-2 ft-lato text-white text-uppercase nomEntete">
					{titreEntete}
				</h4>
				<div className="image">
					<div
						className="fleche"
						style={{
							borderTop: '15px solid ' + couleurEncart || '#7cb4d7'
						}}
					/>
					{couverture && <img src={`${couverture.urlThumb1024}`} className="img-fluid" />}
				</div>
			</div>
			{/* ********************** */}
			<div className='contenu ft-lato'>
				<div className="titre1">{titre1}</div>
				<div className="titre2">{titre2}</div>
				<div className="titre3">{titre3}</div>

				<div className="titre4" style={{ color: couleurEncart }}>
					{titre4}
				</div>
				{contenu && <div className="pl-4 pr-4 text-justify texteContenu">
					<div className="ft-alegreya-sans-light text-black">
						<p
							dangerouslySetInnerHTML={{
								__html: contenu
							}}
						/>
					</div>

				</div>}
				{urlBouton && <Bouton couleurTheme={couleurEncart} texteBouton={texteBouton} icone={icone}/>}
			</div>
		</div>



	icone(nomIcone) {
		return (
			<img
				src={nomIcone}
				style={{
					width: '120px',
					position: 'absolute',
					zIndex: '2',
					top: '50%',
					left: '50%' /* à 50%/50% du parent référent */,
					transform:
						'translate(-50%, -50%)' /* décalage de 50% de sa propre taille */
				}}
			/>
		);
	}


	render() {

		const childElements = this.props.datas.map(element => {
			return (

				<div className={"col-xl-4 col-md-6 mb-3 block"} key={element.id}>
					{element.url === "" && this.encart(element)}
					{element.url !== "" && ( isExternal(element.url) ? (
						<a
							href={element.url}
							target="_blank"
							to={element.url}
							style={{ textDecoration: 'none' }}
						>
							{this.encart(element)}
						</a>
					) : (
						<Link
							to={element.url}
							className=""
							style={{ textDecoration: 'none' }}
						>
							{this.encart(element)}
						</Link>
					))}
				</div>

			);
		});

		return (
			<Masonry
				className={'row'} // default ''
				elementType={'div'} // default 'div'
				options={masonryOptions} // default {}
				disableImagesLoaded={false} // default false
				updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
				imagesLoadedOptions={imagesLoadedOptions} // default {}
			>
				{childElements}
			</Masonry>
		);
	}
}

