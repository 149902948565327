import React from "react";
import "./PageArticle.scss"
import { DATA } from "./Data";

import Modal from "react-bootstrap/Modal";
import { IconeCommander } from "../SVG/Svg"
import BoutonFavoris from "../Favoris/BoutonFavoris"
import * as couleurs from "../Couleurs"
import { Gallerie } from '../Gallerie/Gallerie';
import { URL_BASE_GLC, URL_IMAGE_PAR_DEFAUT, LIEN_IMAGE_THUMB } from "../Config"
import { VenteContext } from "../ContextList";
import MetaTags from 'react-meta-tags';



const Ligne = props => (
    <tr>
        <td className="nom">{props.nom}</td>
        <td className="valeur">{props.valeur}</td>
    </tr>
);

const DetailListe1 = ({ data, auteurs }) => (
    <>
        <Ligne nom="Collection" valeur={data.collection || "-"} />
        <Ligne nom="Date de parution" valeur={data.anneeParution || " - "} />
        <Ligne nom="ISBN" valeur={data.isbn || "-"} />
        <Ligne nom="EAN" valeur={data.ean || "-"} />
        <Ligne nom="Titre" valeur={data.titre || "-"} />
        <Ligne nom="Sous-titre" valeur={data.sousTitre || "-"} />
        <Ligne nom="Auteur" valeur={
            auteurs.map((auteur, index) => (
                <span key={auteur}>
                    {auteur}
                    {index < auteurs.length - 1 && <span> - </span>}
                </span>
            ))
        } />
    </>
);

const DetailListe2 = props => (
    <>
        <Ligne nom="Editeur" valeur={props.data.editeur !== "Xxx" ? props.data.editeur : "-"} />
        <Ligne nom="Type de reliure" valeur={props.data.reliure || "-"} />
        <Ligne nom="Nombre de pages" valeur={((props.data.nombreDePages || "-") + (props.data.nombreDePages ? " pages" : ""))} />
        <Ligne nom="Poids" valeur={(props.data.poids || "-") + (props.data.poids ? " g" : "")} />
        <Ligne nom="Dimensions" valeur={props.data.dimensions || "-"} />
        <Ligne nom="Épaisseur" valeur={props.data.weight || "-"} />
    </>
);

export default class PageArticle extends React.Component {
    state = {
        id: this.props.match.params.id,
        show: false,
        favoris: false,
        selected: false,
        resumeOpen: false,
        resumeOpenAvis: false,
        article: {},
        auteurs: [],
        imageItems: [],
        chargement: true
    };

    componentDidMount() {
        this.setState({
            id: this.props.match.params.id
        }, this.loadArticle())
        window.scrollTo(0, 190);

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log("update");
            this.setState({ id: this.props.match.params.id }, this.loadArticle());
        }
    }


    loadArticle = () => {
        fetch(`${URL_BASE_GLC}/articleDetail/${this.props.match.params.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "GET"
        }).then(response => {
            if (response.status !== 200) {
                console.error("Error: " + response.status);
                return;
            }
            response.json().then(json => {
                this.setState({
                    article: json.data,
                    auteurs: json.data.auteurs,
                    imageItems: json.data.imageItems,
                    chargement: false
                }, () => {
                })
            });
        });
    }

    render() {

        const { openModalCommander } = this.context;

        const BoiteDialogue = props => (
            <>
                <Modal
                    show={this.state.show}
                    onHide={() => this.setState({ show: false })}
                    className="modal"
                >
                    <Modal.Body className="text-center">
                        <button
                            type="button"
                            className="bouton-page-article"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => this.setState({ show: false })}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <img
                            className="img-fluid imageCouverture"
                            src={props.url}
                            alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = URL_IMAGE_PAR_DEFAUT
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );

        //*************************************************************** */
        const Entete = () => <div className="entete">
            <h1 className="text-uppercase">
                {this.state.article.titre || ""}
            </h1>
            <h2
                className="ft-open-sans"
            >
                {this.state.article.sousTitre || ""}
            </h2>
            <h3 style={{ color: couleurs.BLEU }}>
                {this.state.auteurs.map((auteur, index) => <span key={index}>
                    {(auteur !== " XXX" && auteur !== " -") ? <span key={auteur} >{auteur}</span> : ""}
                    {(this.state.auteurs.length > 1 && index !== this.state.auteurs.length - 1) && " - "}
                </span>
                )}
                <span style={{ fontWeight: "400" }}>{(this.state.auteurs.filter(auteur => (auteur !== " XXX" && auteur !== " -")).length > 0 && this.state.article.editeur !== "Xxx") ? " | " : ""} {this.state.article.editeur !== "Xxx" ? this.state.article.editeur : ""}</span>

            </h3>
        </div >
        //*************************************************************** */

        const Couverture = () => <div className="couverture-wrapper"
        >
            <Gallerie items={this.state.imageItems} />
        </div>
        const Caracteristiques = () => <div
            className="container-fluid pt-5 pb-5 no-gutters caracteristiques"
        >
            <div className="container ">
                <div className="text-uppercase pb-2 titre">
                    Caractéristiques
                </div>

                {/* Version PC ----------------------------------------------------------------------------- */}
                <div className="blocDetail d-none d-xl-block tableau">
                    <div className="tableauGauche">
                        <table className="livreTable">
                            <tbody>
                                <DetailListe1 data={this.state.article} auteurs={this.state.auteurs} />
                            </tbody>
                        </table>
                    </div>
                    <div className="barre"></div>
                    <div className="tableauDroit">
                        <table className="livreTable">
                            <tbody>
                                <DetailListe2 data={this.state.article} />
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* --------------------------------------------------------------------------------------- */}


                {/* Version mobile ------------------------------------------------------------------------ */}
                <div className="mobile d-block d-xl-none">
                    <table className="livreTable">
                        <tbody>
                            <DetailListe1 data={this.state.article} auteurs={this.state.auteurs} />
                            <DetailListe2 data={this.state.article} />
                        </tbody>
                    </table>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>

        const id = this.props.match.params.id;
        return (
            <div className="article">
                {!this.state.chargement && <MetaTags>
                    <title>{(this.state.article.titre || "")+ " - " + (this.state.article.sousTitre || "")}</title>
                    <meta
                        name="description"
                        content={this.state.auteurs.map((auteur, index) => {
                                 return (auteur !== " XXX" && auteur !== " -") ? auteur : "" 
                            + ((this.state.auteurs.length > 1 && index !== this.state.auteurs.length - 1) && " - ")})
                            + " - " + (this.state.article.editeur !== "Xxx" ? this.state.article.editeur : "")
                            } />
                </MetaTags>}
                <div
                    className="container-fluid no-gutters"
                    style={{ minHeight: "580px" }}
                >
                    <div className="container pt-5 pb-5">
                        <Entete />
                        <div className="row pt-5">
                            <div className="col-md-4">
                                <Couverture />
                            </div>
                            <div className="col-md-8">
                                <div className="pl-md-4">
                                    <div className="vente">


                                        <span
                                            className="ft-open-sans pt-5 pt-md-0 prix"
                                        >
                                            {this.state.article.prixTotal} € {this.state.article.montantRemiseStr !== "0" && <span className="pourcent"><span className="ft-open-sans prix-avant-remise">{this.state.article.prixAvantRemise} €</span> (-{this.state.article.montantRemiseStr}%)</span>}
                                        </span>
                                        {this.state.article.disponibilite && <button
                                            onMouseEnter={() => {
                                                this.setState({ selected: true })
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ selected: false });
                                            }}
                                            onClick={() => {
                                                const id = this.state.article.id;
                                                openModalCommander(id);
                                            }}
                                            className="button-commander"
                                        >
                                            <span className="icone">
                                                <IconeCommander width={15} couleur={this.state.selected ? couleurs.ROSE : "#fff"} />
                                            </span>
                                            <span className="label">
                                                Commander
                                            </span>
                                        </button>}
                                        <span className="disponibilite">
                                            <span className="icone">
                                                {this.state.article.disponibilite ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
                                                        <path fill="none" stroke="#2f2f87" strokeWidth={2} d="m2.13,10.7 4.87,4.87 11.3-11.3" />
                                                    </svg>
                                                    :
                                                    <svg height={20} id="non-dispo" xmlns="http://www.w3.org/2000/svg" viewBox="390 230 500 500"><defs><style dangerouslySetInnerHTML={{ __html: ".non-dispo-1{fill:none;stroke:#e73065;stroke-miterlimit:10;stroke-width:32px;}" }} /></defs><circle className="non-dispo-1" cx="641.5" cy="478.54" r="228.39" /><line className="non-dispo-1" x1="770.3" y1="301.57" x2="526.18" y2="666.49" /></svg>
                                                }
                                            </span>
                                            <div className="texte">
                                                {this.state.article.disponibilite ? "Disponible" : "Non disponible"}
                                            </div>
                                        </span>
                                        <span className="etat"> - {this.state.article.etat}</span>
                                        <BoutonFavoris id={this.props.match.params.id} />
                                    </div>
                                    <div className="contenu">
                                        {this.state.article.resume && <div className="resume">
                                            <h3 className="titre">4<sup>e</sup> de couverture</h3>
                                            {/* <h3 className="titre">4<sup>ème</sup> de couverture</h3> */}
                                            <div className="texte" style={{ WebkitLineClamp: this.state.resumeOpen ? "30" : "4" }}>
                                                <p style={{ fontSize: "0.9em", textAlign: "justify" }}

                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.article.resume
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="lire-la-suite"
                                                onClick={() => {
                                                    this.setState({
                                                        resumeOpen: !this.state.resumeOpen
                                                    })
                                                }}
                                            >{this.state.resumeOpen ? "Réduire" : "Lire la suite"}</div>
                                        </div>}

                                        {this.state.article.avis && <div className="avis">
                                            <h3 className="titre">L'avis de Calvin</h3>
                                            <p className="texte" style={{ WebkitLineClamp: this.state.resumeOpenAvis ? "30" : "4" }}>
                                                <div style={{ fontSize: "0.9em", textAlign: "justify" }}>
                                                    {this.state.article.avis}
                                                </div>
                                            </p>
                                            <div
                                                className="lire-la-suite"
                                                onClick={() => {
                                                    this.setState({
                                                        resumeOpenAvis: !this.state.resumeOpenAvis
                                                    })
                                                }}
                                            >{this.state.resumeOpenAvis ? "Réduire" : "Lire la suite"}</div>
                                        </div>}
                                        {/* <h3 className="titre">Télécharger</h3>
                                        <div style={{ color: "#000" }}>
                                            {DATA[id].fichiers.map(fichier => (
                                                <div className="">
                                                    <i className="ion-ios-download-outline"></i>
                                                    <a
                                                        className="pl-2"
                                                        style={{ color: "#000" }}
                                                        href={fichier.url}
                                                        target="_blank"
                                                    >
                                                        {fichier.nom}
                                                    </a>
                                                </div>
                                            ))}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {DATA[2].lienVideoYoutube && <>
                    <div className="container video-wrapper pb-3">
                        <div className="text-uppercase pb-2 titre">
                            On en parle sur le net...
                        </div>
                        <div className="video row">

                            <div className="col-md-2"></div>
                            <div
                                className="center-block col-md-8"
                            >
                                <div className="video-container " style={{ top: "24px" }}>
                                    <iframe
                                        title="video-presentation-livre"
                                        width="560"
                                        height="315"
                                        src={DATA[2].lienVideoYoutube}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                            <div className="col-md-2"></div>

                        </div>
                    </div>
                </>} */}
                <Caracteristiques />
                <BoiteDialogue url={`${LIEN_IMAGE_THUMB}/${this.state.article.id}.jpg`} />
            </div>
        );
    }
}
PageArticle.contextType = VenteContext;