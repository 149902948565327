
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from "react-router-dom"
import * as couleurs from "../Couleurs"
import './MonCompte.scss';
import { useAuth } from "react-oidc-context";
import * as config from "../Config"
import Moment from 'moment';
import { Crayon } from "../SVG/Svg"
import { InputText } from "./InputText"
import Modal from "react-bootstrap/Modal";
import { Chargement } from '../Chargement/Chargement';

export const MonCompte = () => {
    const [chargement, setChargement] = useState(false);
    const [titre, setTitre] = useState("");
    const [orders, setOrders] = useState([]);
    const [orderSelected, setOrderSelected] = useState(null);

    //---------------------------------------------------------------------------
    // Infos personnelles
    const [showModalInfosPerso, setShowModalInfosPerso] = useState(false);
    const [messageErreurSaveProfil, setMessageErreurSaveProfil] = useState("");
    const [inputFields, setInputFields] = useState({
        nom: "",
        prenom: "",
        email: ""
    });

    const [validFields, setValidFields] = useState({
        nom: false,
        prenom: false,
        email: false
    });
    //---------------------------------------------------------------------------

    //---------------------------------------------------------------------------
    // Mot de passe
    const [showModalMotDePasse, setShowModalMotDePasse] = useState(false);
    const [messageErreurSavePassword, setMessageErreurSavePassword] = useState("");
    const [inputFieldsPassword, setInputFieldsPassword] = useState({
        old: "",
        new: ""
    });

    const [validFieldsPassword, setValidFieldsPassword] = useState({
        old: false,
        new: false
    });
    //---------------------------------------------------------------------------

    const history = useHistory();
    const auth = useAuth();


    useEffect((props) => {
        loadOrders();
    }, [auth.isAuthenticated]);


    const loadOrders = async () => {

        const url = `${config.URL_BASE_ORDER}/orders/ordersOfCustomer`;
        const fetchData = async () => {
            fetch(url, {
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    const val = data.data;
                    setChargement(false);
                    console.log(data);
                    if (val !== null) {
                        setOrders(val)
                    } else {

                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const saveProfil = async () => {
        setChargement(true);
        const url = `${config.URL_BASE_USER}/users/saveProfil`;
        const fetchData = async () => {
            fetch(url, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: JSON.stringify(inputFields)
            })
                .then((response) => response.json())
                .then((data) => {
                    setChargement(false);
                    if (data.success) {
                        auth.signinSilent();
                        setShowModalInfosPerso(false);
                        setMessageErreurSavePassword("")
                    } else {
                        setMessageErreurSavePassword("Une erreur est survenue, veuillez réessayer plus tard !")
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const savePassword = async () => {
        setChargement(true);
        const url = `${config.URL_BASE_USER}/users/savePassword`;
        const fetchData = async () => {
            fetch(url, {
                method: "PUT",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: config.getToken(auth)
                },
                body: JSON.stringify(inputFieldsPassword)
            })
                .then((response) => response.json())
                .then((data) => {
                    setChargement(false);
                    if (data.success) {
                        auth.signinSilent();
                        setShowModalMotDePasse(false);
                        setInputFieldsPassword({
                            ...inputFields, ["old"]: "",
                            ["new"]: ""
                        });
                        setValidFieldsPassword({
                            ...validFields, ["old"]: false,
                            ["new"]: false
                        });
                        setMessageErreurSavePassword("")
                    } else {
                        setMessageErreurSavePassword("le mot de passe n'a pas pu être changé, veuillez vérifier votre ancien mot de passe !")
                    }
                })
                .catch(error => {

                });
        };
        fetchData();
        //---------------------------------------------
    }

    const getStrFromCentimes = (centimes) => {
        // Diviser les centimes par 100 pour obtenir les euros
        let euros = centimes / 100;
        // Vérifier si le montant est un entier ou non
        if (Number.isInteger(euros)) {
            // Si c'est un entier, retourner sans décimales
            return `${euros}`;
        } else {
            // Sinon, formater avec deux décimales
            return `${euros.toFixed(2).replace('.', ',')}`;
        }
    }

    const handleShowOrder = item => {
        console.log(item);
        setOrderSelected(item);
    }

    const handleChangeInput = useCallback(
        (name, value, valid) => {
            setInputFields({ ...inputFields, [name]: value });
            setValidFields({ ...validFields, [name]: valid });
            console.log(inputFields)
        },
        [inputFields],
    )

    const handleChangeInputPassword = useCallback(
        (name, value, valid) => {
            setInputFieldsPassword({ ...inputFieldsPassword, [name]: value });
            setValidFieldsPassword({ ...validFieldsPassword, [name]: valid });
            console.log(inputFieldsPassword)
        },
        [inputFieldsPassword],
    )

    return (
        <div className="mon-compte container" style={{ minHeight: "580px" }}>

            {chargement && <Chargement />}
            {/* --------------------------------------------------------------------------------------- */}
            {/* --- Modal informations personnelles                                                     */}
            <Modal show={showModalInfosPerso} onHide={() => {
                setShowModalInfosPerso(false);
                setMessageErreurSaveProfil("");
            }} className='modal-lg modal-adresse' >
                <Modal.Header closeButton style={{ backgroundColor: "#e2e3e4" }}>
                    <Modal.Title><span style={{ color: "#111" }}>Informations personnelles</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="form-row row">
                        <InputText
                            nom="prenom"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="text"
                            type="text"
                            label="Prénom"
                        />
                        <InputText
                            nom="nom"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="6"
                            mask="text"
                            type="text"
                            label="Nom"
                        />

                    </div>
                    <div className="form-row row">
                        <InputText
                            nom="email"
                            value={inputFields}
                            valid={validFields}
                            handleChangeInput={handleChangeInput}
                            required={true}
                            valCol="12"
                            mask="email"
                            type="email"
                            label="Email"
                        />

                    </div>
                </Modal.Body>
                {messageErreurSaveProfil !== "" &&
                    <div class="alert alert-danger" role="alert">
                        {messageErreurSaveProfil}
                    </div>}
                <Modal.Footer style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="footer-modal mb-3">

                        {validFields["nom"] &&
                            validFields["prenom"] &&
                            validFields["email"]
                            && <button
                                className="btn btn-dark mr-3 bouton-valider"
                                style={{ float: "right", backgroundColor: "#241c97" }}
                                onClick={() => {
                                    saveProfil();
                                }}>
                                Enregistrer
                            </button>}
                    </div>
                </Modal.Footer>
            </Modal>
            {/* --------------------------------------------------------------------------------------- */}


            {/* --------------------------------------------------------------------------------------- */}
            {/* --- Modal Mot de passe                                                                  */}
            <Modal show={showModalMotDePasse} onHide={() => {
                setShowModalMotDePasse(false)
                setMessageErreurSavePassword("")
            }} className='modal-lg modal-adresse' >
                <Modal.Header closeButton style={{ backgroundColor: "#e2e3e4" }}>
                    <Modal.Title><span style={{ color: "#111" }}>Mot de passe</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="form-row row">
                        <InputText
                            nom="old"
                            value={inputFieldsPassword}
                            valid={validFieldsPassword}
                            handleChangeInput={handleChangeInputPassword}
                            required={true}
                            valCol="12"
                            mask="text"
                            type="password"
                            label="Mot de passe actuel"
                        />
                    </div>
                    <div className="form-row row">
                        <InputText
                            nom="new"
                            value={inputFieldsPassword}
                            valid={validFieldsPassword}
                            handleChangeInput={handleChangeInputPassword}
                            required={true}
                            valCol="12"
                            mask="text"
                            type="password"
                            label="Nouveau mot de passe"
                            userNom={auth.user?.profile.given_name}
                            userPrenom={auth.user?.profile.family_name}
                            userEmail={auth.user?.profile.email}
                        />
                    </div>
                    {messageErreurSavePassword !== "" &&
                        <div class="alert alert-danger" role="alert">
                            {messageErreurSavePassword}
                        </div>}
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#e2e3e4" }}>
                    <div className="footer-modal mb-3">

                        {validFieldsPassword["old"] &&
                            validFieldsPassword["new"]
                            && <button
                                className="btn btn-dark mr-3 bouton-valider"
                                style={{ float: "right", backgroundColor: "#241c97" }}
                                onClick={() => {
                                    savePassword()
                                }}>
                                Enregistrer le mot de passe
                            </button>}
                    </div>
                </Modal.Footer>
            </Modal>
            {/* --------------------------------------------------------------------------------------- */}

            {auth.isAuthenticated &&
                <>
                    <h1 style={{ color: "#111" }} className='text-center pt-4'>
                        Bienvenue sur votre compte ! <br />
                    </h1>
                    <div className="text-center pb-2 pt-2">
                        <button className="bouton" onClick={() => {
                            // void auth.stopSilentRenew();
                            void auth.signoutSilent();
                            localStorage.removeItem('basket');
                            // void auth.removeUser();
                        }}>Me déconnecter</button>
                    </div>

                    {/* --------------------------------------------------------------------
                    Informations personnelles                                                */}
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 col-12 informations-personnelles">
                            <div className="entete">

                                <span className="titre">Informations personnelles</span>
                                <span className='crayon-wrapper' onClick={() => {
                                    setInputFields({
                                        ...inputFields, ["nom"]: auth.user?.profile.family_name,
                                        ["prenom"]: auth.user?.profile.given_name,
                                        ["email"]: auth.user?.profile.email
                                    });
                                    setValidFields({
                                        ...validFields, ["nom"]: true,
                                        ["prenom"]: true,
                                        ["email"]: true

                                    });
                                    setShowModalInfosPerso(true);

                                }}>
                                    <span className="icone-crayon">
                                        <Crayon height={25} color={couleurs.ROSE} />
                                    </span>
                                    <span className="texte">
                                        Modifier
                                    </span>
                                </span>
                            </div>
                            <div className="nom">
                                Nom : <span style={{ fontWeight: 500 }}>{auth.user?.profile.name}</span>
                            </div>
                            <div className="email">
                                Adresse email : <span style={{ fontWeight: 500 }}>{auth.user?.profile.email}</span>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    {/* --------------------------------------------------------------------------------------- */}

                    {/* ---------------------------------------------------------------------------------------
                    Mot de passe                                                                                */}
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 col-12 informations-personnelles">
                            <div className="entete">

                                <span className="titre">Mot de passe</span>
                                <span className='crayon-wrapper' onClick={() => {
                                    setShowModalMotDePasse(true);
                                    setInputFieldsPassword({
                                        ...inputFields, ["old"]: "",
                                        ["new"]: ""
                                    });
                                    setValidFieldsPassword({
                                        ...validFields, ["old"]: false,
                                        ["new"]: false
                                    });

                                }}>
                                    <span className="icone-crayon">
                                        <Crayon height={25} color={couleurs.ROSE} />
                                    </span>
                                    <span className="texte">
                                        Modifier
                                    </span>
                                </span>
                            </div>
                            <div className="nom" style={{ fontSize: "2rem" }}>
                                •••••••••••
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    {/* --------------------------------------------------------------------------------------- */}

                    {orderSelected == null && orders !== null && <div className="order-list">
                        <h3 className='text-center'>Liste de vos commandes</h3>
                        {orders.length > 0 && <table className="table table-hover mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">Référence</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Infos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders
                                    .sort((a, b) => new Date(b.dateOrderCreated) - new Date(a.dateOrderCreated))
                                    .map((item, index) =>
                                        <tr key={item.reference} onClick={() => { handleShowOrder(item) }}>
                                            <th scope="row">{item.reference}</th>
                                            <td>{Moment(item.dateOrderCreated).format('DD/MM/YYYY HH[h]mm')}</td>
                                            <td><b>{getStrFromCentimes(item.montantTotalCentimes)} €</b></td>
                                            <td><span className='order-status' style={{ backgroundColor: `#${item.couleurFond}`, color: `#${item.couleur}` }}>{item.status}</span></td>
                                            <td scope="row">{item.commentaireStatus !== "null" ? item.commentaireStatus : ""}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>}
                        {orders.length === 0 && <div className="no-orders">Vous n'avez pas de commandes</div>}
                    </div>}

                    {orderSelected != null &&
                        <div className="order-details">
                            <div className="retour" onClick={() => { setOrderSelected(null) }}>{'<'} Retour</div>
                            <div className="titre">
                                Commande réf : {orderSelected.reference}
                                <br />
                                mode de paiement : {orderSelected.paymentMode}
                                <br />
                                <br />
                                Status : <span className='order-status' style={{ backgroundColor: `#${orderSelected.couleurFond}`, color: `#${orderSelected.couleur}` }}>{orderSelected.status}</span>
                            </div>



                            <div>
                                <h5 className="pt-5 pb-3">Journal des événements</h5>
                                <table className="table table-striped pt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">État</th>
                                            <th scope="col">Information</th>
                                            <th scope="col">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderSelected.commandeStatus
                                            .map((element, index) => <tr>
                                                <th scope="row">{Moment(orderSelected.dateAdded).format('DD/MM/YYYY HH[h]mm ss[s]')}</th>
                                                <td>
                                                    <span style={{ borderRadius: "8px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", backgroundColor: `#${element.commandeStatusType.colorBackground}`, color: `#${element.commandeStatusType.color}` }}>
                                                        <b>{element.commandeStatusType.label}</b>
                                                    </span>
                                                </td>
                                                <td>{element.commentaire !== "null" ? element.commentaire : ""}</td>
                                                <td>{element.commandeStatusType.description}</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>





                            <div className="articles-list">

                                <div className="pt-4 pb-2" style={{ color: "#111", fontWeight: "500", fontSize: "1.2rem" }}>Liste des articles</div>
                                <table className="table table-striped pt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Isbn</th>
                                            <th scope="col"></th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Prix unit</th>
                                            <th scope="col">Qté</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderSelected.commandeDetails
                                            .map((element, index) => <tr>
                                                <th scope="row">{element.isbn}</th>

                                                <td className='text-center'>
                                                    <Link to={`/article/${element.idArticle}`} ><img className="img-fluid" style={{ maxHeight: "80px" }} src={`https://storage.jeancalvin.fr/full/${element.idArticle}.jpg`} /></Link>
                                                </td>
                                                <td><Link style={{textDecoration: "none"}} to={`/article/${element.idArticle}`} >{element.description}</Link></td>
                                                <td>{getStrFromCentimes(element.prixTotalCentimes)} € {element.pourcentageRemise > 0 ? <span style={{ fontSize: "0.9rem" }}>({getStrFromCentimes(element.prixAvantRemiseCentimes)}€ - {element.pourcentageRemise}%)</span> : ""}</td>
                                                <td>{element.qte}</td>
                                                <td>{getStrFromCentimes(element.prixTotalCentimes * element.qte)} €</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                <br />


                                <table className="table table-striped pt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Articles</th>
                                            <th scope="col">Livraison</th>
                                            <th scope="col">Montant total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span style={{ fontSize: "1.3rem", fontWeight: "600" }}>{getStrFromCentimes(orderSelected.prixSousTotalTotalCentimes)} €</span></td>
                                            <td><span style={{ fontSize: "1.3rem", fontWeight: "600" }}>{getStrFromCentimes(orderSelected.prixTotalLivraisonEnCentimes)} €</span></td>
                                            <td><span style={{ fontSize: "1.3rem", fontWeight: "600" }}>{getStrFromCentimes(orderSelected.montantTotalCentimes)} €</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h5 className='pt-5'>Livraison</h5>
                            <p>
                                <ul>
                                    {orderSelected.codeDeliveryMode === "MON" ? <li>Choix livraison : MondialRelay</li> : ""}
                                    {orderSelected.codeDeliveryMode === "COL" ? <li>Choix livraison : Colissimo</li> : ""}
                                    {orderSelected.codeDeliveryMode === "LVE" ? <li>Choix livraison : Lettre Verte</li> : ""}
                                    {orderSelected.codeDeliveryMode === "GRA" ? <li>Choix livraison : Livraison gratuite</li> : ""}
                                    {orderSelected.codeDeliveryMode === "CHR" ? <li>Choix livraison : Chronopost</li> : ""}
                                    {orderSelected.codeDeliveryMode === "LBR" ? <li>Choix livraison : Livres et Brochure</li> : ""}



                                    {orderSelected.codeDeliveryMode === "MON" && <>
                                        {/* {orderSelected.mondialRelayId !== "" ? <li>Id mondial relay : {orderSelected.mondialRelayId}</li> : ""} */}
                                        {orderSelected.mondialRelayNom !== "" ? <li>Nom : {orderSelected.mondialRelayNom}</li> : ""}
                                        {orderSelected.mondialRelayAdresse !== "" ? <li>Adresse : {orderSelected.mondialRelayAdresse}</li> : ""}
                                        {orderSelected.mondialRelayCp !== "" ? <li>Code postal : {orderSelected.mondialRelayCp}</li> : ""}
                                        {orderSelected.mondialRelayVille !== "" ? <li>Ville : {orderSelected.mondialRelayVille}</li> : ""}
                                        {orderSelected.mondialRelayPays !== "" ? <li>Pays : {orderSelected.mondialRelayPays}</li> : ""}
                                    </>}
                                    {orderSelected.codeDeliveryMode !== "MON" && <>
                                        {<li>Nom : {orderSelected.nom}</li>}
                                        {<li>Prénom : {orderSelected.prenom}</li>}
                                        {<li>Adresse : {orderSelected.adresse}</li>}
                                        {<li>Code postal : {orderSelected.codePostal}</li>}
                                        {<li>Ville : {orderSelected.ville}</li>}
                                        {<li>Pays : {orderSelected.pays}</li>}
                                        {<li>Tel : {orderSelected.prefixTel} {orderSelected.tel}</li>}
                                    </>}
                                </ul>
                            </p>

                        </div>
                    }

                </>
            }

            {
                !auth.isAuthenticated && <div className="text-center pb-5 pt-5">
                    <button className="bouton" onClick={() => {
                        history.push('/');
                        void auth.signinRedirect();

                    }}>Me connecter</button>
                </div>
            }

        </div >
    );
}

