import React from "react";
import "./ElementCaroussel.scss"
import { Link } from "react-router-dom";
import { IconeFavoris, IconeCommander } from "../SVG/Svg"
import BoutonFavoris from "../Favoris/BoutonFavoris"
import * as couleurs from "../Couleurs"
import * as config from "../Config"
import { VenteContext } from "../ContextList";



export class ElementCaroussel extends React.Component {
    componentDidMount() {

    }

    state = {
        selected: false
    };

    render() {
        const { openModalCommander } = this.context;
        return (
            <div className="element-article-caroussel" >
                <div className="wrapper">
                    <div className="contenu-image">

                        <span className="favoris-wrapper">
                            <BoutonFavoris id={this.props.result.id} />
                        </span>
                        <Link
                            to={`/article/${this.props.result.id}/${this.props.result.titre}`}
                        >
                            <img
                                className="image img-fluid"
                                // style={{ margin: "auto" }}
                                src={`${config.LIEN_IMAGE_THUMB}/${this.props.result.id}.jpg`}
                                // src={`https://www.librairiejeancalvin.fr/var/data_ljc/cov/thumb/${this.props.result.id}.jpg`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = config.URL_IMAGE_PAR_DEFAUT
                                }}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="contenu">
                        <div className="bloc">
                            <div className="titre">

                                <h2>{this.props.result.titre}</h2>
                            </div>
                            <div className="auteurs">
                                <ul>
                                    {this.props.result.auteurs && this.props.result.auteurs.reverse().map((elem, index) => {
                                        if (index < 3) {
                                            const size = this.props.result.auteurs.length;
                                            if (index == 1 && size == 2) { // si on a deux éléments et qu'on est au deuxième
                                                return <li key={elem}>{elem}</li>
                                            } else {
                                                return <li key={elem}>{elem + ((size > 1 && index < 2) ? "," : "")}</li>
                                            }
                                        }
                                    })}
                                </ul>
                            </div>
                            <div className="align-content-end align-items-end element-bas">
                                {this.props.result.montantRemiseStr !== "0" && <span className="prix-avant-remise">{this.props.result.prixAvantRemise}€</span>}
                                <div className="prix">{this.props.result.prixTotal} €</div>
                                <button
                                    onMouseEnter={() => {
                                        this.setState({ selected: true })
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({ selected: false });
                                    }}
                                    onClick={()=>{
                                        const id = this.props.result.id;
                                        openModalCommander(id);
                                    }}
                                >
                                    <span className="icone"
                                    >
                                        <IconeCommander width={15} couleur={this.state.selected === true ? couleurs.ROSE : "#fff"} />
                                    </span>
                                    <span className="label">
                                        Commander
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
ElementCaroussel.contextType = VenteContext;

