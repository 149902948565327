import React, { Fragment } from 'react';
import Slider from 'react-slick';
import * as icones from './Icones'
import * as tools from './Tools';
import { api } from "@mitim/react-mitim"
import { Link } from 'react-router-dom';
import isExternal from 'is-url-external';
import { ElementCaroussel } from "./ElementCaroussel"


const lien = "https://storage.timothee.fr/ftp/sites/photos/";

export class ModuleCaroussel extends React.Component {
	constructor(props) {
		super(props);
	}



	render() {



		const childElements = this.props.elements.map(element => {
			return <div key={element} className="lienSlide" style={{ textDecoration: 'none !important' }}>
					<ElementCaroussel result={element}  />
				</div>

		});

		var settings = {
			dots: true,
			infinite: true,
			// speed: 50,
			slidesToShow: 4,
			//slidesToScroll: 4,
			arrows: false,



			slidesToScroll: 4,
			autoplay: true,
			speed: 200,
			autoplaySpeed: 6000,
			cssEase: "linear",



			responsive: [
				{
					breakpoint: 1224,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};
		
		return (
			<>
				<div className="gridSlide">
					<Slider {...settings}>{childElements}</Slider>
				</div>
			</>
		);
	}
}

