import "./Chargement.scss"



export const Chargement = () => <div className="chargement">
    <div className="d-flex justify-content-center">
        <div className="spinner-border  text-light" style={{ width: "3rem", height: "3rem" }} role="status">
            <div className="item">
                <span className="sr-only">Chargement...</span>
            </div>
        </div>
    </div>
</div>