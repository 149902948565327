import { Hidden } from "@material-ui/core";
import React from "react";
import "./Navigation.scss"
import { IconeFermer, Fleche, FlecheLeft } from "../SVG/Svg"
import { Link } from "react-router-dom"
import { encodageUrl } from "../ElasticLjc/fonctions";
import { URL_BASE_GLC } from "../Config"

export default class Navigation extends React.Component {


    getCategories = async () => {
        //console.log("valeur de session : " + sessionStorage.getItem("categoriesLoaded"))

        if (sessionStorage.getItem("categoriesLoaded") == null) {
            const response = await fetch(`${URL_BASE_GLC}/categories`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "GET"
            }).then(response => {
                if (response.status !== 200) {
                    console.error("Error: " + response.status);
                    return;
                }
                response.json().then(json => {
                    sessionStorage.setItem("categoriesLoaded", "true");
                    sessionStorage.setItem("categories", JSON.stringify(json.data));
                    this.setState({
                        categories: JSON.parse(sessionStorage.getItem("categories"))
                    })
                });
            });
        }
        //console.log(JSON.parse(sessionStorage.getItem("categories")))
        return JSON.parse(sessionStorage.getItem("categories"));
    }

    componentDidMount() {
        this.getCategories();
        if (sessionStorage.getItem("categoriesLoaded") == "true") {
            this.setState({
                categories: JSON.parse(sessionStorage.getItem("categories"))
            })
        }
    }
    state = {
        niveau: 0,
        idThemeSelected: 0,
        nomThemeSelected: "",
        nomNiveau1: "",
        nomNiveau2: "",
        nomNiveau3: "",
        idNiveau1: 0,
        idNiveau2: 0,
        idNiveau3: 0,
        categories: []
    }

    handleClick = () => {
        // this.props.self.setState({
        //     navigationVisible: !this.props.navigationVisible
        // })
        this.props.closeNavigationVisible();
    }

    handleClickTheme = (niveau, idThemeSelected, nomThemeSelected, type) => {
        if (niveau === 2) { // affichage niveau 2
            if (type === "retour") {
                this.setState({
                    niveau: niveau,
                    idThemeSelected: idThemeSelected,
                    nomThemeSelected: nomThemeSelected
                })
            } else {
                this.setState({
                    niveau: niveau,
                    idThemeSelected: idThemeSelected,
                    nomThemeSelected: nomThemeSelected,
                    nomNiveau1: nomThemeSelected,
                    idNiveau1: idThemeSelected
                })
            }
        } else if (niveau === 3) { // affichage niveau 2
            this.setState({
                niveau: niveau,
                idThemeSelected: idThemeSelected,
                nomThemeSelected: nomThemeSelected,
                nomNiveau2: nomThemeSelected,
                idNiveau2: idThemeSelected
            })
        } else this.setState({
            niveau: niveau,
            idThemeSelected: idThemeSelected,
            nomThemeSelected: nomThemeSelected
        })
    }

    render() {


        // affiche le menu
        const Niveau0 = () => <>
            <ul className="listes" >
                {this.props.items.map(niveau0 => (
                    <li key={niveau0.name}>
                        {niveau0.type === "liste" ?
                            <div className="bouton"
                                onClick={this.handleClickTheme.bind(this, 1)}
                            >
                                {niveau0.name}
                                <div className="fleche">
                                    <Fleche width={10} color="#000" />
                                </div>
                            </div>
                            :
                            <Link className="bouton" to={niveau0.link}
                                onClick={() => {
                                    // this.props.self.setState({
                                    //     navigationVisible: false
                                    // })
                                    console.log("Bouton fermer")
                                    this.props.closeNavigationVisible();
                                }}
                            >
                                {niveau0.name}
                            </Link>}
                    </li>
                ))}
            </ul>
        </>

        // affiche la liste des thèmes de niveau 1
        const Niveau1 = () => <>
            <ul className="listes" >
                {this.props.screen === "mobile" && <span className="retour"
                    onClick={this.handleClickTheme.bind(this, 0)}
                >
                    <span className="flecheRetour">
                        <FlecheLeft width={10} color="#000" />
                    </span>
                    <span className="label">
                        Retour
                    </span>
                </span>}
                {this.state.categories.filter(data => (data.id == data.n1 && data.n2 == 0)).sort((a, b) => a.rang - b.rang).map(niveau1 => (
                    <li key={niveau1.nom}>
                        <div className="bouton"
                            onClick={this.handleClickTheme.bind(this, 2, niveau1.id, niveau1.nom)}
                        >
                            {niveau1.nom}
                            <div className="fleche">
                                <Fleche width={10} color="#000" />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>

        const EnteteNiveau = ({ niveau, lien, idThemeSelected, nomThemeSelected }) => <>
            <div className="retour"
                onClick={this.handleClickTheme.bind(this, niveau, idThemeSelected, nomThemeSelected, "retour")}
            >
                <span className="flecheRetour">
                    <FlecheLeft width={10} color="#000" />
                </span>
                <span className="label">
                    Retour
                </span>
            </div>
            <div className="titre-wrapper">
                <h2 className="titre-theme">
                    {this.state.nomThemeSelected}
                </h2>
                <Link to={lien} className="">Voir tout</Link>
            </div>
        </>

        // affiche la liste des thèmes de niveau 2
        const Niveau2 = () => <>
            <EnteteNiveau
                niveau={1}
                lien={`/articles/${encodageUrl(this.state.nomNiveau1)}-${this.state.idThemeSelected}`}
            />
            <ul className="sous-categorie">
                {this.state.categories.filter(data => (data.n1 === this.state.idThemeSelected && data.n2 != 0 && data.n3 === 0)).sort((a, b) => a.rang - b.rang).map(niveau => (
                    <li key={niveau.nom}>
                        {this.state.categories.filter(e => (e.parent === niveau.id)).length > 0 ?
                            <div className="bouton"
                                onClick={this.handleClickTheme.bind(this, 3, niveau.id, niveau.nom)}
                            >
                                {niveau.nom}
                                <div className="fleche">
                                    <Fleche width={10} color="#000" />
                                </div>
                            </div>
                            :
                            <Link to={`/articles/${encodageUrl(this.state.nomNiveau1)}-${this.state.idThemeSelected}/${encodageUrl(niveau.nom)}-${niveau.id}`} className="bouton" >
                                {niveau.nom}
                            </Link>
                        }
                    </li>
                ))}
            </ul>
        </>

        // affiche la liste des thèmes de niveau 3
        const Niveau3 = () => <>
            <EnteteNiveau
                niveau={2}
                idThemeSelected={this.state.idNiveau1}
                nomThemeSelected={this.state.nomNiveau1}
                lien={`/articles/${encodageUrl(this.state.nomNiveau1)}-${this.state.idNiveau1}/${encodageUrl(this.state.nomNiveau2)}-${this.state.idThemeSelected}`}
            />
            <ul className="sous-categorie">
                {this.state.categories.filter(data => (data.n1 == this.state.idNiveau1 && data.n2 === this.state.idNiveau2 && data.n3 != 0)).sort((a, b) => a.rang - b.rang).map(niveau => (
                    <li key={niveau.nom}>
                        {this.state.categories.filter(e => (e.parent === niveau.id)).length > 0 ?
                            <div className="bouton"
                                onClick={this.handleClickTheme.bind(this, 3, niveau.id, niveau.nom)}
                            >
                                {niveau.nom}
                                <div className="fleche">
                                    <Fleche width={10} color="#000" />
                                </div>
                            </div>
                            :
                            <Link to={`/articles/${encodageUrl(this.state.nomNiveau1)}-${this.state.idNiveau1}/${encodageUrl(this.state.nomNiveau2)}-${this.state.idNiveau2}/${encodageUrl(niveau.nom)}-${niveau.id}`} className="bouton" >
                                {niveau.nom}
                            </Link>
                        }
                    </li>
                ))}
            </ul>
        </>


        return (
            <div>
                <div className="navigation-overlay"
                    style={{ opacity: this.props.navigationVisible ? "100%" : "0", visibility: this.props.navigationVisible ? "visible" : "Hidden" }}
                    onClick={this.handleClick.bind()}
                >
                </div>
                <div className="navigation" style={{
                    transform: this.props.navigationVisible ? "translateX(0%)" : "translateX(-103%)",
                    visibility: this.props.navigationVisible ? "visible" : "Hidden"
                }}>

                    <div className="header text-center">
                        <h2 className="titre">Menu</h2>
                        <span
                            className="fermer"
                            onClick={this.handleClick.bind()}
                        >
                            <IconeFermer width={30} color="#666" />
                        </span>
                    </div>

                    <div className="contenu-wrapper">
                        <div className="contenu">

                            <div className="niveau0">
                                <Niveau0 />
                            </div>

                            <div className="niveau1 panneau"
                                style={{
                                    transform: (this.state.niveau === 1 || this.state.niveau === 2 || this.props.screen === "computer") ? "translateX(0%)" : "translateX(106%)",
                                }}
                            >
                                <Niveau1 />
                            </div>
                            <div className="niveau2 panneau"
                                style={{
                                    transform: (this.state.niveau === 2 || this.state.niveau === 3) ? "translateX(0%)" : "translateX(106%)",
                                }}
                            >
                                <Niveau2 />
                            </div>
                            <div className="niveau3 panneau"
                                style={{
                                    transform: this.state.niveau === 3 ? "translateX(0%)" : "translateX(106%)",
                                }}
                            >
                                <ul className="listes" >
                                    <Niveau3 />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
