import React from 'react';
import './ListeLibrairies.scss';
import { Link } from "react-router-dom"
import * as couleurs from "../Couleurs"


const ListeLibrairies = () => {
    return (
        <>
            <div className="liste-librairies pt-3 d-none d-md-block">
                <div className="flex-container">
                    {/* <div className=""> */}

                        <Link to="/nos-librairies/ales" className="elem flex-item text-uppercase ales">
                            <span className="tooltip">04 66 86 16 61</span>
                            <div className="fleche"></div>
                            Alès
                        </Link>

                        <Link to="/nos-librairies/rennes" className="elem flex-item text-uppercase rennes">
                            <span className="tooltip">09 73 88 08 09</span>
                            <div className="fleche"></div>
                            Rennes
                        </Link>

                        {/* <Link to="/nos-librairies/cholet" className="elem flex-item text-uppercase cholet">
                            <span className="tooltip">02 41 58 01 17</span>
                            <div className="fleche"></div>
                            Cholet
                        </Link> */}
                    {/* </div> */}
                </div>
            </div>
            <div className="liste-librairies-mobile pt-3 d-md-none">

                <div className="bouton-librairie">
                    <Link to="/nos-librairies/ales" className="element text-uppercase ales" >
                        Alès
                    </Link>
                </div>

                <div className="bouton-librairie">
                    <Link to="/nos-librairies/rennes" className="element text-uppercase rennes" >
                        Rennes
                    </Link>
                </div>

                {/* <div className="bouton-librairie">
                    <Link to="/nos-librairies/cholet" className="element text-uppercase cholet" >
                        Cholet
                    </Link>
                </div> */}
            </div>
        </>
    );
};

export default ListeLibrairies;