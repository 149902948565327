// Librairies
import React, { useEffect, useState, useRef } from 'react';
import { Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// Component
import Header from './Header/Header';
import { VenteContext } from './ContextList';
import Modal from "react-bootstrap/Modal";
import "./HeaderAndRoute.scss"
import { ElementPanierModal } from "./Panier/ElementPanierModal"
import * as config from "./Config"



// export default class HeaderAndRoute extends React.Component {
export const HeaderAndRoute = (props) => {

	const [showModalCommander, setShowModalCommander] = useState(false);
	const [idArticleCommander, setIdArticleCommander] = useState(0);



	useEffect(() => {

	}, [])

	const openModalCommander = (id) => {
		//---------------------------------------------
		// const token = auth.authenticated ? `Bearer ${auth.token}` : "";
		const token = "";

		const basket = localStorage.getItem('basket') !== null ? localStorage.getItem('basket') : '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}'
		const urlAddBasket = `${config.URL_BASE_BASKET}/baskets/add/${id}`;
		const fetchData = async () => {
			fetch(urlAddBasket, {
				method: "POST",
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					authorization: token
				},
				body: basket
			})
				.then((response) => response.json())
				.then((data) => {
					localStorage.setItem("basket", JSON.stringify(data.data));
				});
		};
		fetchData();
		//---------------------------------------------

		console.log("on vient de commander " + id);
		setShowModalCommander(true);
		setIdArticleCommander(id);
	};


	const { t, ...rest } = props;

	const ModalCommander = () => <>
		<Modal show={showModalCommander} onHide={() => setShowModalCommander(false)}>
			<Modal.Header closeButton>
				<Modal.Title><span style={{ color: "#111" }}>L'article à été ajouté au panier</span></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ElementPanierModal idElem={idArticleCommander} />
			</Modal.Body>
			<Modal.Footer>
				<div className="footer-modal">
					<span onClick={() => setShowModalCommander(false)} className='boutons bouton-continuer'>Continuer mes achats</span>
					<Link onClick={() => setShowModalCommander(false)} to='/panier' className='boutons bouton-panier'>Voir mon panier</Link>
				</div>
			</Modal.Footer>
		</Modal>
	</>
	const ModalCommanderTMP = () => <>
		<Modal show={showModalCommander} onHide={() => setShowModalCommander(false)}>
			<Modal.Header closeButton>
				<Modal.Title><span style={{ color: "#111" }}>Commander l'article n°{idArticleCommander}</span></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="contenu">
					La vente en ligne n'est pas encore fonctionnelle, elle devrait l'être durant l'été 2024<br />
					En attendant, vous pouvez commander par téléphone auprès de la Librairie au 04 66 86 16 61.
				</div>
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	</>


	return (
		<>
			<VenteContext.Provider value={{
				showModalCommander: false,
				openModalCommander: openModalCommander
			}}>
				<Header slide={props.slide} path={props.path} />
				{config.PROFIL !== "venteDisabled" && <ModalCommander />}
				{config.PROFIL === "venteDisabled" && <ModalCommanderTMP />}
				<Route {...rest} />
			</VenteContext.Provider>
		</>
	);

}

