import React from "react";
import "./ElementFavoris.scss"
import { Link } from "react-router-dom";
import { IconeFavoris, IconeCommander, Trash } from "../SVG/Svg"
import { URL_BASE_GLC, URL_IMAGE_PAR_DEFAUT } from "../Config"
import * as couleurs from "../Couleurs"
import { HeaderContext } from '../ContextList'; // Importer votre contexte





export class ElementFavoris extends React.Component {
    componentDidMount() {

    }

    state = {
        selected: false,
        element: {},
        imageItems: [],
        listeFavoris: []
    };

    componentDidMount() {
        this.loadElement();
    }


    loadElement = () => {
        fetch(`${URL_BASE_GLC}/articleDetail/${this.props.elem}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "GET"
        }).then(response => {
            if (response.status !== 200) {
                console.error("Error: " + response.status);
                return;
            }
            response.json().then(json => {
                this.setState({
                    element: json.data,
                    imageItems: json.data.imageItems,
                }, () => {
                })
            });
        });
    }



    render() {
        const { updateMenuFavorisOpen, menuFavorisOpen, listeFavoris, removeFavoris } = this.context;
        const deleteFavoris = () => {
            const id = this.props.elem;
            console.log("On suprime le favois :" + id)
            removeFavoris(id);
        }
        return (
            <div className="text-center element-favoris" >
                <div className="contenu-image d-block">
                    <div className="image-wrapper">
                        <div className="icone-wrapper" onClick={deleteFavoris}>
                            <Trash height={30} color={couleurs.ROSE} />
                        </div>

                        <Link
                            onClick={() => this.props.bouton()}
                            to={`/article/${this.state.element.id}`}
                        >
                            <img
                                className="img-fluid image"
                                style={{ margin: "auto" }}
                                src={this.state.imageItems ? this.state.imageItems
                                    .filter((element, index) => index === 0)
                                    .map(elem => elem.original) : ""}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = URL_IMAGE_PAR_DEFAUT;
                                }}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
                <div className="contenu">
                    <div className="titre">{this.state.element?.titre}</div>
                    <div className="affichagePrix">
                        <div className="prix">{this.state.element?.prixTotal} € {this.state.element?.montantRemiseStr !== "0" && <span className="ft-open-sans prix-avant-remise">{this.state.element?.prixAvantRemise} €</span>}</div>
                    </div>
                </div>
            </div>
        );
    }
}
ElementFavoris.contextType = HeaderContext;

