// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import MenuWrapper from "../MenuWrapper";
import MetaData from "../MetaData";
import "./Header.scss";
import ContenuHeader from "./ContenuHeader";
import MenuFavoris from "../Favoris/MenuFavoris";
import { Link } from "react-router-dom"
import { Logo } from "../SVG/Svg";
import { getFavorisListe } from "../Favoris/Fonctions"
import { HeaderContext } from '../ContextList'; // Importer votre contexte


class Header extends React.Component {
	// static contextType = AppContext;

	state = {

		statusScroll_1: false,
		statusScroll_2: false,
		statusScroll_3: false,
		statusScroll_4: false,
		statusScroll_5: false,

		//!!!!!!!!!!!!!!!!!!!!!!!!!!!
		// SCROLL
		positionMenu: "inherit",
		opacityPetitMenu: 0,
		hauteurMenuRechercher: 0,

		// Input rechercher
		topRechercher: 100,
		mouvement: "",
		postitionRechercher: "relative",
		//!!!!!!!!!!!!!!!!!!!!!!!!!!!

		//----------------------------
		//HEADER
		// Menu favoris
		listeFavoris: [],
		menuFavorisOpen: false
		//----------------------------

	}


	// Version a utiliser qui fonctionne très bien avec les hooks
	
	// useEffect(() => {
    //     function onScroll() {
    //         let currentPosition = window.scrollY;
    //         if (currentPosition > 400) {
    //             console.log("on est supérieur à 400 !!!")
    //         } 
    //     }
    //     window.addEventListener("scroll", onScroll);
    //     return () => window.removeEventListener("scroll", onScroll);
    // }, []);

	componentDidMount() {
		window.onscroll = () => {

			//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			if (window.scrollY > 150 && !this.state.statusScroll_1) {
				// console.log("1 => > 150")
				this.setState({
					positionMenu: "fixed",
					hauteurMenuRechercher: 60,
					//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
					mouvement: "mouvement",
					postitionRechercher: "fixed",
					topRechercher: 36,
					statusScroll_1: true,
					statusScroll_2: false
					//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				});
			}
			if (window.scrollY < 150 && !this.state.statusScroll_2) {
				// console.log("2 => < 150")
				this.setState({
					positionMenu: "inherit",
					hauteurMenuRechercher: 0,

					//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
					mouvement: "",
					postitionRechercher: "relative",
					topRechercher: 100,
					statusScroll_1: false,
					statusScroll_2: true
					//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				});
			}
			//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!




			//-----------------------------------------------------------------------------------
			// PETIT MENU permet l'affichage du logo et des boutons dans le menu réduit (ne pas confondre avec le menu mobile)
			if (window.scrollY > 140 && window.scrollY < 240) {
				// console.log(3)
				let val = (window.scrollY - 140) / 100;
				this.setState({
					opacityPetitMenu: val,
					statusScroll: 3,
					statusScroll_3: true,
					statusScroll_4: false,
					statusScroll_5: false
				});
			}
			if (window.scrollY < 140 && !this.state.statusScroll_4) {
				// console.log(4)
				this.setState({
					opacityPetitMenu: 0,
					statusScroll: 4,
					statusScroll_4: true,
					statusScroll_3: false,
					statusScroll_5: false,
				});
			}

			if (window.scrollY > 240 && !this.state.statusScroll_5) {
				// console.log(5)
				this.setState({
					opacityPetitMenu: 1,
					statusScroll: 5,
					statusScroll_5: true,
					statusScroll_3: false,
					statusScroll_4: false
				});
			}
			//-----------------------------------------------------------------------------------
		};
	}



	//---------------------------------------------------------
	// HEADER
	updateMenuFavorisOpen = () => {
		let listeFavoris = getFavorisListe();
		this.setState({
			menuFavorisOpen: !this.state.menuFavorisOpen,
			listeFavoris: listeFavoris
		},
			() => {
				if (!this.state.menuFavorisOpen) {
					window.location.reload();
				}
				console.log("on change menuFavoris :" + this.state.menuFavorisOpen)
			}
		);
	};

	removeFavoris = (id) => {
		console.log("On suprime le favois :" + id)
		let favorisListe = localStorage.getItem('favoris');

		if (favorisListe != undefined) {
			let datas = JSON.parse(favorisListe);
			console.log(datas);
			if (datas.find(element => element === id)) { // id existe dans la liste et décoché
				console.log("l element existe");
				for (var i = 0; i < datas.length; i++) {
					console.log(i);
					if (datas[i] === id) {
						console.log("on suprime de la liste " + i);
						datas.splice(i, 1);
					}
				}
			}
			console.log("On sauvegarde : " + datas)
			localStorage.setItem('favoris', JSON.stringify(datas));
			this.setState({
				listeFavoris: getFavorisListe()
			}, console.log("on change menuFavoris :" + this.state.menuFavorisOpen));
		}
	}
	//---------------------------------------------------------

	render() {
		const { slide } = this.props;

		return (
			<header>
				<HeaderContext.Provider value={{
					menuFavorisOpen: this.state.menuFavorisOpen,
					updateMenuFavorisOpen: this.updateMenuFavorisOpen,
					listeFavoris: getFavorisListe(),
					removeFavoris: this.removeFavoris
				}}>
					<div className="logo-mini d-none d-xl-block" style={{ opacity: this.state.opacityPetitMenu * 2 }}>
						<Link to="/"><Logo width={115} id="logo" aria-current="page" aria-label="page d'accueil" /></Link>
					</div>


					<div className="menu-favoris-wrapper" style={{ position: "relative" }}>
						<MenuFavoris
							self={this}
							listeFavoris={this.state.listeFavoris}

						/>
					</div>
					{/* <div className="menu-panier-wrapper" style={{ position: "relative" }}>
						<MenuPanier self={this} />
					</div> */}
					{!slide.disable && <MetaData
						title={
							"librairie Calvin" +
							(slide.h1 ? " - " + slide.h1 : "") +
							(slide.h2 ? " - " + slide.h2 : "") +
							(slide.h3 ? " - " + slide.h3 : "")
						}
						descriptionMeta={slide.descriptionMeta}
					/>}
					<ContenuHeader
						slide={slide}
						mouvement={this.state.mouvement}
						topRechercher={this.state.topRechercher}
						postitionRechercher={this.state.postitionRechercher}
					/>
					<MenuWrapper
						header={this}
						path={this.props.path}
					/>
				</HeaderContext.Provider>
			</header>
		);
	}
}

export default Header;
