import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import './Caisse.scss';

const Caisse = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <div className="panier" style={{height: "580px"}}>

            <div
                className="container-fluid no-gutters"
                style={{ minHeight: "580px" }}
            >
                <div className="container pt-4 pb-5">
                    <div className="votre-panier">Caisse</div>
                    <div className="row">
                        
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default withAuthenticationRequired(Caisse, {
    onBeforeSignin: () => {
        sessionStorage.setItem("callback", "panier") // sera lu ensuite dans le composant Callback
    },
    onRedirecting: () => <>
        <div className="container text-center" style={{ height: "580px" }}>
            <h1 style={{ color: "#111", fontSize: "2rem" }}>Redirection vers la page d'authentification</h1>
        </div>
    </>
});