import React, { useEffect, useState, useContext } from 'react';
import "./ContenuHeader.scss"
import { Link, useHistory } from "react-router-dom"
import {
    SearchBox
} from "@elastic/react-search-ui";
import {
    IconeMenu,
    Favoris,
    IconeLoupe,
    IconeMonCompte,
    IconePanier,
    IconeQuestion,
    Logo
} from "../SVG/Svg";
import * as couleurs from "../Couleurs"
import { getFavorisListe } from "../Favoris/Fonctions";
import { HeaderContext } from '../ContextList';
import { PROFIL } from "../Config";
import { useAuth } from "react-oidc-context";


const BarreRecherche = () => <SearchBox
    onSelectAutocomplete={(suggestion, config, defaultHandler) => {
        window.location.href = "/articles?q=" + suggestion.suggestion;
        defaultHandler(suggestion);
    }}
    onSubmit={(searchTerm) => {
        window.location.href = "/articles?q=" + searchTerm;
    }}
    inputView={({
        getAutocomplete,
        getInputProps,
        getButtonProps
    }) => (
        <>
            <div className="sui-search-box__wrapper">
                <input
                    className="input-text"
                    {...getInputProps({
                        placeholder: "Rechercher",
                        className: "input-text"
                    })}
                />
                {getAutocomplete()}
            </div>
            <label className="loupe">
                <input type="submit" value="" />
                <span className="loupe-svg"><IconeLoupe width={35} />
                </span>
            </label>
        </>
    )}
    autocompleteSuggestions={true}
/>

const couleurElements = couleurs.ROSE;
const couleurElementsSelected = couleurs.BLEU;


export const ContenuHeader = (props) => {

    const [monCompte, setMonCompte] = useState(couleurElements);
    const [panier, setPanier] = useState(couleurElements);
    const [mesFavoris, setMesFavoris] = useState(couleurElements);

    const { updateMenuFavorisOpen } = useContext(HeaderContext);
    const history = useHistory();
    const auth = useAuth();



    return (
        <>
            <div>
                <div className="d-xl-none">
                    <section className="contenu-header-mobile">
                        <div className="logo text-center img-fluid">
                            <Link to="/">
                                <Logo width={180} id="logo2" />
                            </Link></div>
                        <div className="barre-recherche">
                            <BarreRecherche />
                        </div>
                    </section>
                </div>
                <div className="d-none d-xl-block">

                    <section className="d-flex justify-content-between contenu-header">
                        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                        {/* %%%% LOGO */}
                        <div className="logo" ><Link to="/"><Logo width={230} id="logo" /></Link></div>

                        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                        {/* %%% INPUT RECHERCHER */}

                        <div
                            className={"rechercher " + props.mouvement}
                            style={{
                                top: props.topRechercher,
                                position: props.postitionRechercher
                            }}>
                            <div className="wrapper">
                                {(window.innerWidth > 768) && <BarreRecherche />}
                            </div>
                        </div>

                        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                        {/* %%%% BLOC DROITE */}
                        <div className="block-droite">
                            <div className="d-flex flex-column contenu">

                                {PROFIL !== "venteDisabled" && <div className="p-1 select"
                                    onMouseEnter={() => {
                                        setMonCompte(couleurElementsSelected);
                                    }
                                    }
                                    onMouseLeave={() => {
                                        setMonCompte(couleurElements);
                                    }}
                                >
                                    <div className="elem" onClick={() => {
                                        if (auth.isAuthenticated) {
                                            history.push('/mon-compte');
                                        } else {
                                            void auth.signinRedirect();
                                        }
                                    }}>

                                        <IconeMonCompte width={35} couleur={monCompte} /><span className="pl-3 d-xl-inline d-none titre">Mon compte</span>
                                    </div>
                                </div>}

                                {PROFIL !== "venteDisabled" && <Link className="p-1 select"
                                    onMouseEnter={() => {
                                        setPanier(couleurElementsSelected);
                                    }
                                    }
                                    onMouseLeave={() => {
                                        setPanier(couleurElements);
                                    }}
                                    to="/panier"
                                >
                                    <div className="elem">
                                        <IconePanier width={35} couleur={panier} /><span className="pl-3 d-xl-inline d-none titre">Panier</span>
                                    </div>
                                </Link>}
                                <div className="p-1 select"
                                    onMouseEnter={() => {
                                        setMesFavoris(couleurElementsSelected)
                                    }
                                    }
                                    onMouseLeave={() => {
                                        setMesFavoris(couleurElements)
                                    }}
                                    onClick={updateMenuFavorisOpen}
                                >
                                    <div className="elem">
                                        <Favoris width={35} couleur={mesFavoris} id={"fav1-"} /><span className="pl-3 d-xl-inline d-none titre">Mes favoris</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                    </section>
                </div>
            </div>
        </>
    );

}
export default ContenuHeader;
