import React from "react";
import ListeLibrairies from "../ListeLibrairies/ListeLibrairies";
import Maconerie from "../Maconerie/Maconerie"
import CarouselHome from "../CarouselHome/CarouselHome";
import {URL_BASE_MEDIAS} from "../Config"

class Actualites extends React.Component {
	
	state = {
		actualites: []
	};

	componentDidMount() {
		window.scrollTo(0, 170);
		this.loadActualites();
	}


	loadActualites = () => {
		fetch(`${URL_BASE_MEDIAS}/actualites`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			method: "GET"
		}).then(response => {
			if (response.status !== 200) {
				console.error("Error: " + response.status);
				return;
			}
			response.json().then(json => {
				this.setState({
					actualites: json.data,
				}, () => {
				})
			});
		});
	}

	render() {

		return (
			<>
				<div
					className="container-fluid pb-lg-5 no-gutters pt-4"
					style={{ minHeight: "480px", backgroundColor: "#e9e9e9" }}
				>
					<div className="container">
						<Maconerie datas={this.state.actualites} />
					</div>
				</div>
			</>
		);
	}
}

export default Actualites;
