import React from 'react';
import './Pub.scss';
import { Link } from "react-router-dom"
import * as couleurs from "../Couleurs"
import { BulleDialogue, Pouce } from '../SVG/Svg';

export const Pub = ({titre, href, img, voirPlus, icone  }) => {
    return (
        <div className="pub container">
            <div className="titre">

                {icone === "pouce" && <span className="icone"><Pouce width={20} /></span>}
                {icone === "bulle" && <span className="icone"><BulleDialogue width={20} /></span>}
                <h4 className="text-uppercase texteTitre" style={{ color: couleurs.BLEU }} >{titre}</h4>
            </div>
            <Link to={href} >
                <img
                    className="img-fluid image"
                    style={{ margin: "auto" }}
                    src={img}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://storage.timothee.fr/public_storage/LJC/pub/pub1_2.jpg"
                    }}
                    alt=""
                />
            </Link>
            {voirPlus && <div className='pt-2'>
                <Link to={voirPlus}>
                    <span style={{ color: couleurs.ROSE, fontWeight: "bolder", float: "right" }} className='ft-lato'>Voir plus</span>
                </Link>
            </div>}



        </div>
    );
};

export default Pub;