import { Hidden } from "@material-ui/core";
import React from "react";
import "./MenuFavoris.scss"
import { IconeFermer, Fleche, FlecheLeft } from "../SVG/Svg"
import { Link } from "react-router-dom"
import { encodageUrl } from "../ElasticLjc/fonctions";
import { DATA } from "../Home/Home"
import { getFavorisListe } from "./Fonctions"
import { ElementFavoris } from "./ElementFavoris"
import { HeaderContext } from '../ContextList'; // Importer votre contexte

export default class MenuFavoris extends React.Component {


    state = {
        listeFavoris: []
    }


    componentDidMount(){

    }

    // fermeture lors d'un click sur un des favoris dans le menu
    handleClick = () => {
        this.props.self.setState({
            menuFavorisOpen: false,
        })
    }

    refresh = () => {
        let listeFavoris = getFavorisListe();
        this.setState({
            listeFavoris: listeFavoris
        })
    }

    render() {
        const { updateMenuFavorisOpen, menuFavorisOpen, listeFavoris } = this.context;
        //console.log(listeFavoris);


        return (
            <div>
                <div className="menu-favoris-overlay"
                    style={{ opacity: menuFavorisOpen ? "100%" : "0", visibility: menuFavorisOpen ? "visible" : "Hidden" }}
                    // style={{ opacity: this.props.self.state.menuFavorisVisible ? "100%" : "0", visibility: this.props.self.state.menuFavorisVisible ? "visible" : "Hidden" }}
                    
                    onClick={updateMenuFavorisOpen}
                    // onClick={this.handleClick.bind()}
                >
                </div>
                <div className="menu-favoris" style={{
                    transform: menuFavorisOpen ? "translateX(0%)" : "translateX(103%)",
                    visibility: menuFavorisOpen ? "visible" : "Hidden"
                }}>
                {/* <div className="menu-favoris" style={{
                    transform: this.props.self.state.menuFavorisVisible ? "translateX(0%)" : "translateX(103%)",
                    visibility: this.props.self.state.menuFavorisVisible ? "visible" : "Hidden"
                }}> */}

                    <div className="header text-center">
                        <h2 className="titre">Mes favoris</h2>
                        <span
                            className="fermer"
                            onClick={updateMenuFavorisOpen}
                            // onClick={this.handleClick.bind()}
                        >
                            <IconeFermer width={30} color="#666" />
                        </span>
                    </div>

                    <div className="contenu-wrapper">
                        <div className="contenu">
                            <div className="niveau0">
                                {listeFavoris?.length === 0 && <div className="pas-de-favoris" >Vous n'avez aucun favoris</div>}
                                <ul className="listes" >
                                    {listeFavoris?.map(elem => (
                                        <li key={elem}>
                                            <ElementFavoris elem={elem} bouton={this.handleClick} refresh={this.refresh}/>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
MenuFavoris.contextType = HeaderContext;
