import React, { useEffect, useState, useContext } from 'react';
import {
    IconeMenu,
    IconeMonCompte,
    IconePanier,
    Favoris,
    IconeQuestion
} from "../SVG/Svg";
import "./MenuMobile.scss";
import { Link, useHistory } from "react-router-dom"
import * as couleurs from "../Couleurs"
import { HeaderContext } from '../ContextList'; // Importer votre contexte
import { PROFIL } from '../Config';
import { useAuth } from "react-oidc-context";


// export default class MenuMobile extends React.Component {
export const MenuMobile = (props) => {

    const [heightMenu, setHeightMenu] = useState(64);
    const [closed, setClosed] = useState(false);

    const { updateMenuFavorisOpen } = useContext(HeaderContext);
    const history = useHistory(); 
    const auth = useAuth();
    useEffect(() => {
        // console.log(auth);
    }, []);

    return (
        <div className="menu-mobile d-xl-none d-flex justify-content-between" style={{ height: heightMenu }}>
            <div
                className="icone-menu"
                onClick={()=>{
                    props.handleMenuMobile()
                }}
            >
                <IconeMenu width={30} />
            </div>
            <div className="">

            </div>
            <div className="block-droite">
                <div className="d-flex flex-row contenu">
                    {PROFIL !== "venteDisabled" && <div className="p-2">
                        <div
                            onClick={() => {
                                console.log("click menu mobile s'authentifier")
                               
                                if (auth.isAuthenticated) {
                                    history.push('/mon-compte');
                                } else {
                                    void auth.signinRedirect();
                                }
                            }}
                        >
                            <IconeMonCompte width={35} couleur={couleurs.ROSE} />
                        </div>
                    </div>}
                    {PROFIL !== "venteDisabled" && <div className="p-2">
                        <Link
                            to="/panier"
                        >
                            <IconePanier width={35} couleur={couleurs.ROSE} />
                        </Link>
                    </div>}
                    <div className="p-2">
                        <span onClick={updateMenuFavorisOpen}>
                            <Favoris width={35} couleur={couleurs.ROSE} id={"fav3-"} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
