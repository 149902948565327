
import "./BoutonCommander.scss"
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom'
import * as couleurs from "../Couleurs"
import * as config from "../Config"
import { VenteContext } from "../ContextList";
import { IconeFavoris, IconeCommander } from "../SVG/Svg"
import Modal from "react-bootstrap/Modal";
import { ElementPanierModal } from "../Panier/ElementPanierModal"


export const BoutonCommander = (props) => {
    // Déclare une nouvelle variable d'état, qu’on va appeler « count »
    const [selected, setSelected] = useState(false);
    const [showModalCommander, setShowModalCommander] = useState(false);

    // const { openModalCommander } = useContext(VenteContext);

    useEffect((props) => {

    }, []);


    const ModalCommander = () => <>

        {/* <Modal show={this.state.showModalCommander} onHide={() => this.setState({ showModalCommander: false })}> */}
        <Modal show={showModalCommander} onHide={() => setShowModalCommander(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span style={{ color: "#111" }}>L'article à été ajouté au panier</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="contenu"> */}
                <ElementPanierModal idElem={props.id} />
                {/* </div> */}
            </Modal.Body>
            <Modal.Footer>
                <div className="footer-modal">
                    <span onClick={() => setShowModalCommander(false)} className='boutons bouton-continuer'>Continuer mes achats</span>
                    <Link onClick={() => setShowModalCommander(false)} to='/panier' className='boutons bouton-panier'>Voir mon panier</Link>
                </div>
            </Modal.Footer>
        </Modal>
    </>

    const ModalCommanderTmp = () => <>

        {/* <Modal show={this.state.showModalCommander} onHide={() => this.setState({ showModalCommander: false })}> */}
        <Modal show={showModalCommander} onHide={() => setShowModalCommander(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span style={{ color: "#111" }}>Commander l'article n°{props.id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="contenu">

                    La vente en ligne n'est pas encore fonctionnelle, elle devrait l'être durant l'été 2024<br />
                    En attendant, vous pouvez commander par téléphone auprès de la Librairie au 04 66 86 16 61.
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    </>

    const openModalCommander = (id) => {
        //---------------------------------------------
        // const token = auth.authenticated ? `Bearer ${auth.token}` : "";
        const token = "";

        const basket = localStorage.getItem('basket') !== null ? localStorage.getItem('basket') : '{"idUser": null,"elemBasketDtos": [],"totalTTC": null}'
        const urlAddBasket = `${config.URL_BASE_BASKET}/baskets/add/${id}`;
        const fetchData = async () => {
            fetch(urlAddBasket, {
                method: "POST",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: token
                },
                body: basket
            })
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem("basket", JSON.stringify(data.data));
                });
        };
        fetchData();
        //---------------------------------------------

        console.log("on vient de commander " + id);
        setShowModalCommander(true);
    };

    return <>
        {config.PROFIL !== "venteDisabled" && <ModalCommander />}
        {config.PROFIL === "venteDisabled" && <ModalCommanderTmp />}


        <button
            onMouseEnter={() => {
                setSelected(true)
            }}
            onMouseLeave={() => {
                setSelected(false)
            }}
            onClick={() => {
                openModalCommander(props.id);
                // setShowModalCommander(true)
                // const id = props.id
                // openModalCommander(id);
            }}
        >
            <span className="icone"
            >
                <IconeCommander width={15} couleur={selected ? couleurs.ROSE : "#fff"} />
            </span>
            <span className="label">
                Commander
            </span>
        </button>
    </>

}

