import React from "react";
//import Page from "../Page";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./ElasticLjc.scss";
import { Link, generatePath } from "react-router-dom";

import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch
} from "@elastic/react-search-ui";

import { MultiCheckboxFacet, SingleLinksFacet } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Button } from "@material-ui/core";
import { LjcContext } from "./fonctions";
import {
	buildAutocompleteQueryConfig,
	buildFacetConfigFromConfig,
	buildSearchOptionsFromConfig,
	buildSortOptionsFromConfig,
	getConfig,
	getFacetFields
} from "./config/config-helper";
import { FACETS, RESULT_FIELDS } from "./config/configuration";
import { ElementArticle } from "./ElementArticle";
import { encodageUrl, getNomDecode } from "./fonctions"
import BoutonFavoris from "../Favoris/BoutonFavoris"

const CustomResultView = ({ result }) => {
	return <ElementArticle result={result} />;
};

const CustomResultsView = ({ children }) => {
	return <div className="row pl-md-2 pt-2">{children}</div>;
};


export default class ElasticLjc extends React.Component {

	static contextType = LjcContext;

	constructor(props) {
		super(props);
	}

	state = {
		filterClass: "d-none",
		categories: []
	};

	componentDidMount() {
		window.scrollTo(0, 190);
		if (sessionStorage.getItem("categoriesLoaded") == "true") {
			this.setState({
				categories: JSON.parse(sessionStorage.getItem("categories"))
			})
		}
	}

	toggleFilters = () => {
		this.state.filterClass === "d-none"
			? this.setState({ filterClass: "" })
			: this.setState({ filterClass: "d-none" });
	};

	handleChange = FieldValue => {
		console.log(FieldValue);
	}


	render() {
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};

		const Niveau1 = () => <div className="sui-facet">
			<div>
				<div className="sui-facet__title">Catégorie</div>
				<ul className="sui-single-option-facet">

					{this.state.categories.filter(data => (data.id == data.n1 && data.n2 == 0)).sort((a, b) => a.rang - b.rang).map(niveau1 => (
						<li className="sui-single-option-facet__item" key={niveau1.nom}>
							<Link className="sui-single-option-facet__link" to={`/articles/${encodageUrl(niveau1.nom)}-${niveau1.id}`} >{niveau1.nom}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>

		const Niveau2 = () => <div className="sui-facet">
			<div>
				{this.state.categories.filter(data => (data.n1 == this.props.idNiveau1 && data.n2 != 0)).length > 0 &&  <div className="sui-facet__title">Sous-catégorie</div>}
				<ul className="sui-single-option-facet">

					{this.state.categories.filter(data => (data.n1 == this.props.idNiveau1 && data.n2 != 0 && data.n3 == 0)).sort((a, b) => a.rang - b.rang).map(niveau2 => (
						<li className="sui-single-option-facet__item" key={niveau2.id} >
							<Link className="sui-single-option-facet__link" to={`/articles/${this.props.niveau1}/${encodageUrl(niveau2.nom)}-${niveau2.id}`} >{niveau2.nom}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>

		const Niveau3 = () => <div className="sui-facet">
			<div>
				{this.state.categories.filter(d => d.parent == this.props.idNiveau2).length > 0 && <div className="sui-facet__title">Sous-catégorie</div>}
				<ul className="sui-single-option-facet">
					{this.state.categories.filter(data => (data.n1 == this.props.idNiveau1 && data.n2 == this.props.idNiveau2 && data.n3 != 0)).sort((a, b) => a.rang - b.rang).map(niveau3 => (
						<li className="sui-single-option-facet__item" key={niveau3.nom}>
							<Link className="sui-single-option-facet__link" to={`/articles/${this.props.niveau1}/${this.props.niveau2}/${encodageUrl(niveau3.nom)}-${niveau3.id}`} >{niveau3.nom}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>


		const Breadcrumb = () => <span className="breadcrumb-wrapper" >
			<nav aria-label="breadcrumb" >
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to="/">Accueil</Link></li>
					{(this.props.niveau1 != null) && <li className="breadcrumb-item"><Link to={`/articles`} >Articles</Link></li>}
					{(this.props.niveau1 != null && this.props.niveau2 != null) && <li className="breadcrumb-item"><Link to={`/articles/${this.props.niveau1}`} >{getNomDecode(this.props.niveau1)}</Link></li>}
					{(this.props.niveau1 != null && this.props.niveau2 != null && this.props.niveau3 != null) && <li className="breadcrumb-item"><Link to={`/articles/${this.props.niveau1}/${this.props.niveau2}`} >{getNomDecode(this.props.niveau2)}</Link></li>}


					{this.props.niveau1 == null && <li className="breadcrumb-item active" aria-current="page">Articles</li>}
					{(this.props.niveau1 != null && this.props.niveau2 == null) && <li className="breadcrumb-item active" aria-current="page">{getNomDecode(this.props.niveau1)}</li>}
					{(this.props.niveau1 != null && this.props.niveau2 != null && this.props.niveau3 == null) && <li className="breadcrumb-item active" aria-current="page">{getNomDecode(this.props.niveau2)}</li>}
					{(this.props.niveau1 != null && this.props.niveau2 != null && this.props.niveau3 != null) && <li className="breadcrumb-item active" aria-current="page">{getNomDecode(this.props.niveau3)}</li>}
				</ol>
			</nav>
		</span>

		return (
			<div className="" style={{ backgroundColor: "#f4f4f4" }}>
				<div className="px-3 pt-3 header-recherche d-flex justify-content-between ">
					<Breadcrumb />
					{this.props.query && <><span className="terme-wrapper"
						onClick={() => {
							window.location.href = "/articles";
						}}
					>
						Recherche : <span className="terme-val">{this.props.query}
							<span className="icone">
								<svg width={20} version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
									<g>
										<path style={{ fill: "#fff" }} d="M500,990C229.8,990,10,770.2,10,500S229.8,10,500,10s490,219.8,490,490S770.2,990,500,990z M500,80.3C268.6,80.3,80.3,268.6,80.3,500c0,231.4,188.3,419.7,419.7,419.7c231.4,0,419.7-188.3,419.7-419.7C919.7,268.6,731.4,80.3,500,80.3z M549.3,501.5l151.3-149.7c13.8-13.6,13.9-35.7,0.3-49.5c-13.6-13.8-35.8-13.9-49.5-0.3L499.9,451.9L350.6,302.2c-13.7-13.7-35.8-13.8-49.5-0.1c-13.7,13.6-13.7,35.8-0.1,49.5l149,149.5L299.8,649.8c-13.8,13.6-13.9,35.7-0.3,49.5c6.9,6.9,15.9,10.4,24.9,10.4c8.9,0,17.8-3.4,24.6-10.1l150.5-148.8l151.7,152.2c6.8,6.9,15.8,10.3,24.8,10.3c9,0,17.9-3.4,24.7-10.2c13.7-13.7,13.7-35.8,0.1-49.5L549.3,501.5z" />
									</g>
								</svg>
							</span>
						</span>
					</span>

					</>}
					<span></span>

				</div>
				<div className="row">
					<div className="col-12 d-md-none p-3 filter-list">
						<div className="m-2 mb-3">
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								onClick={this.toggleFilters}
							>
								Filtres
							</Button>
						</div>
					</div>
					<div
						className={
							this.state.filterClass +
							" d-md-block col-md-4 col-lg-3 col-xl-3 col-xxl-2 filter-list"
						}
					>
						<div style={{ backgroundColor: "" }} className="p-3">
							<div className="pt-5"></div>
							{/* {wasSearched && ( */}
							<Sorting
								label={"Trier par"}
								sortOptions={buildSortOptionsFromConfig()}
							/>
							{/* )} */}
							{this.props.niveau1 == null && <Niveau1 />}
							{(this.props.niveau1 != null && this.props.niveau2 == null) && <Niveau2 />}
							{(this.props.niveau1 != null && this.props.niveau2 != null && this.props.niveau3 == null) && <Niveau3 />}
							<Facet field="disponible" label="Disponible" view={MultiCheckboxFacet} filterType="any" />
							<Facet field="etat" label="État" view={MultiCheckboxFacet} filterType="any" />
							{/* {this.props.niveau1 == null && <Facet field="niveau_1" label="Niveau 1" view={SingleLinksFacet} filterType="any" show={900} />} */}
							{/* {(this.props.niveau1 != null && this.props.niveau2 == null) && <Facet field="niveau_2" label="Niveau 2" view={SingleLinksFacet} filterType="any" show={900} />} */}
							{/* {(this.props.niveau1 != null && this.props.niveau2 != null && this.props.niveau3 == null) && <Facet field="niveau_3" label="Niveau 3" view={SingleLinksFacet} filterType="any" show={900} />} */}
							{/* <Facet field="annee_parution" label="Année de parution" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="auteurs" label="Auteurs" view={MultiCheckboxFacet} filterType="any" /> */}
							{this.props.niveau1 == "Bibles-1" && <Facet field="version_bible" label="Version de bible" view={MultiCheckboxFacet} filterType="any" />}
							{/* <Facet field="auteur" label="auteur" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="type_stock" label="Type de stock" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="code_editeur" label="Éditeur" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="collection" label="Collection" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="disponibilite" label="Disponibilité" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="disponible_en_librairie" label="Disponible en librairie" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="nbr_images" label="Nombre d'images" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="nombre_de_pages" label="Nombre de pages" view={MultiCheckboxFacet} filterType="any" /> */}
							{/* <Facet field="poids" label="Poids" view={MultiCheckboxFacet} filterType="any" /> */}

						</div>
					</div>
					<div
						className={classes.result}
						style={{ backgroundColor: "#f4f4f4" }}
					>
						<div className="row pl-3 pr-5">
							{/* <div
								className="pt-5 pb-4"
								style={{ width: "600px", margin: "auto" }}
							>

							</div> */}

							<div className="col-12">
								<span style={{ display: "inline" }}>
									{/* {wasSearched && ( */}
									<PagingInfo
										view={({ start, end, totalResults }) => (
											<div className="paging-info">
												<strong>
													{start} - {end} sur {totalResults}{" "}
													résultats
												</strong>
											</div>
										)}
									/>
									{/* )} */}
								</span>
								<span className="result-par-page">
									{/* {wasSearched && <ResultsPerPage />} */}
									{<ResultsPerPage label="Afficher" options={[18, 35, 50, 100]} inline="false" />}
								</span>
							</div>

							<Results
								view={CustomResultsView}
								resultView={CustomResultView}
							/>
							<div className="col-12">
								<div
									style={{
										position: "absolute",
										left: "50%",
										translate: "-50%"
									}}
								>
									<div className="" style={{ width: "420px" }}>
										<Paging />
									</div>
								</div>
							</div>

							<p>
								<br />
								<br />
								<br />
								<br />
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
