import React from "react";
import "./ElementArticle.scss"
import { Link } from "react-router-dom";
import { IconeFavoris, IconeCommander } from "../SVG/Svg"
import BoutonFavoris from "../Favoris/BoutonFavoris";
import * as couleurs from "../Couleurs"
import * as config from "../Config"
import { VenteContext } from "../ContextList";
import { BoutonCommander } from "./BoutonCommander"




export class ElementArticle extends React.Component {
    componentDidMount() {

    }

    state = {
        selected: false
    }


    getTitre = () => {
        let titre = this.props.result.titre.raw;
        return titre.replace(/[^\w\s]/gi, ''); // On enlève tous les caractères spéciaux
    }


    render() {
        const { openModalCommander } = this.context;
        return (
            <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 col-xxl-3 mt-1 text-center element-article" >
                <div className="wrapper">
                    <div className="contenu-image d-block">
                        <div className="favoris-wrapper">
                            <BoutonFavoris id={this.props.result.id.raw} />
                        </div>
                        <Link

                            to={`/article/${this.props.result.id.raw}/${this.getTitre()}`}
                        // to={`/article/${this.props.result.id.raw}/${this.props.result.titre.raw}`}
                        >
                            <img
                                className="img-fluid image"
                                src={`${config.LIEN_IMAGE_THUMB}/${this.props.result.id.raw}.jpg`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = config.URL_IMAGE_PAR_DEFAUT
                                }}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="contenu">
                        <div className="bloc">
                            <div className="titre">

                                <h2>{this.props.result.titre.raw}</h2>
                            </div>
                            <div className="auteurs">
                                <ul>
                                    {this.props.result.auteurs.raw && this.props.result.auteurs.raw.reverse().map((elem, index) => {
                                        if (index < 3) {
                                            const size = this.props.result.auteurs.raw.length;
                                            if (index == 1 && size == 2) { // si on a deux éléments et qu'on est au deuxième
                                                return elem !== "XXX " && <li key={elem}>{elem}</li>
                                            } else {
                                                return elem !== "XXX " && <li key={elem}>{elem + ((size > 1 && index < 2) ? "," : "")}</li>
                                            }
                                        }
                                    })}
                                </ul>
                            </div>
                            <div className="align-content-end align-items-end element-bas">
                                <div className="affichagePrix">
                                    
                                    <div className="prix">{this.props.result.prix_total.raw} € {this.props.result?.montant_remise.raw !== "0" && <span className="ft-open-sans prix-avant-remise">{this.props.result?.prix_total_avant_remise.raw} €</span>}</div>
                                </div>
                                <BoutonCommander id={this.props.result.id.raw} />
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        );
    }
}
ElementArticle.contextType = VenteContext;




// export const ElementArticle = props => {
// 	return (
// 		<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
// 			<div className="row pl-md-3">
// 				<div className="col-12 mt-1 result affichageCantiques">
// 					<Link
// 						//to={``}
// 						to={`/media/${props.result.id.raw}/${props.result.titre.raw}`}
// 						className="lien"
// 					>
// 						<img src={`https://www.librairiejeancalvin.fr/var/data_ljc/cov/thumb/${props.result.id.raw}.jpg`} alt="" />
// 						<div className="select pr-2">
// 							<div
// 								className="pt-2 text-black ft-alegreya-sans-light pb-2"
// 								style={{ fontSize: "1em" }}
// 							>
// 								<span style={{ verticalAlign: "bottom" }}>
// 									<span className="pb-5">
// 										<strong>{props.result.titre.raw}</strong>
// 									</span>
// 								</span>

// 							</div>
// 						</div>
// 					</Link>
// 					<hr className="pt-2 pl-2" />
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

