import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import * as couleurs from "../Couleurs"
import ModuleGallerie from '../ModuleGallerie/ModuleGallerie'
import './Podcast.scss';
import {URL_BASE_MEDIAS} from "../Config"

export const Podcast = () => {
    // Déclare une nouvelle variable d'état, qu’on va appeler « count »
    const [titre, setTitre] = useState("");
    const [titre2, setTitre2] = useState("");
    const [titre3, setTitre3] = useState("");
    const [contenu, setContenu] = useState("");
    const [lienYoutube, setLienYoutube] = useState("");
    const [lienAudio, setLienAudio] = useState("");
    const [auteur, setAuteur] = useState("");
    const [descAuteur, setDescAuteur] = useState("");
    const [gallerie, setGallerie] = useState([]);
    const [name, setName] = useState("");
    const params = useParams();
    useEffect((props) => {

        const url = `${URL_BASE_MEDIAS}/medias/${params.idPodcast}`;

        fetch(url, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setTitre(data.data.titre);
                setTitre2(data.data.titre2);
                setAuteur(data.data.auteur);
                let date = new Date(data.data.date);
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                let dateFormatee = date.toLocaleDateString('fr-FR', options); // 'fr-FR' pour la langue française
                setTitre3("le " + dateFormatee + " à " + data.data.lieu);
                setContenu(data.data.contenu);
                setLienYoutube(data.data.idVideoYoutube);
                setDescAuteur(data.data.descAuteur);
                setLienAudio(data.data.audioMp3?.url);
                setGallerie(data.data.imagesGallerie);
                console.log(data.data.audioMp3?.url);

            });
    }, [name]);

    return (
        <div className="podcast">

            <div
                className="container-fluid no-gutters"
                style={{ minHeight: "580px" }}
            >
                <div className="container pt-5 pb-5">

                    <NomMediaType nom="Conférence à revoir" couleur={couleurs.ROSE} />
                    <div className="text-center">

                        <div className="titre text-uppercase" style={{ color: couleurs.ROSE }}>{titre}</div>
                        {titre2 && <div className="titre2 text-uppercase" style={{ color: couleurs.ROSE }}>{titre2}</div>}
                        <div className="auteur">
                            {auteur}
                            <span className="descAuteur">, {descAuteur}</span>
                        </div>

                        <div className="titre3">{titre3}</div>
                        <p className='contenu pt-5'>
                            <div dangerouslySetInnerHTML={{ __html: contenu }} />
                        </p>
                    </div>
                    {lienYoutube && <div className="container video-wrapper pb-3">
                        <div className="pb-2 titre text-center">
                            Visionner la conférence
                        </div>
                        <div className="video row">

                            <div className="col-md-2"></div>
                            <div
                                className="center-block col-md-8"
                            >
                                <div className="video-container " style={{ top: "24px" }}>
                                    <iframe
                                        title="video-presentation-livre"
                                        width="560"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${lienYoutube}`}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>}
                    {lienAudio && <div className="text-center container audio-wrapper">
                        <div className="pb-2 titre text-center">
                            Écouter la conférence
                        </div>
                        <div className="pt-2">

                            <audio
                                controls="controls"
                                controlsList="nodownload"
                                preload="auto"
                                src={lienAudio}
                            />
                        </div>
                    </div>}
                    {gallerie.length > 0 && <div className="gallerie">
                        <div className="pb-2 titre text-center">
                            Gallerie photos
                        </div>
                        <ModuleGallerie data={gallerie} />
                    </div>}
                </div>
            </div>
        </div>
    );
}

const NomMediaType = ({ nom, couleur }) => {
    return <div className='text-center'>
        <span className='nomMediaType text-uppercase' style={{ backgroundColor: couleur }}>{nom}</span>
    </div>
}