import React, { useEffect, useState, useCallback, useRef } from 'react';
import './ChoixPaiement.scss';
import * as config from "../Config"
import { useAuth } from "react-oidc-context";
import Modal from "react-bootstrap/Modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

export const ChoixPaiement = ({ modePaiement, handlerChangeModePaiement, amount, paymentIntent, getStrFromCentimes, retourApresPaiement, handleRetourErreurPaiement }) => {
    const auth = useAuth();

    const stripePromise = loadStripe("pk_test_51Htb74E8tS6wz08wl5FrF37K2textTEZ9mphGn09JFY37UUCxyS74zC7qo5odKGZjh9CBWNRLeczIwPCvafRWBTW00s6IbN22R");

    return (
        <div className="choix-paiement" >

            <div className="titre">Mode de paiement</div>

            <div>
                <section className={"element mb-3 " + (modePaiement === "cb" ? " selected" : " not-selected")} onClick={() => handlerChangeModePaiement("cb")}>
                    <div className="titre">Carte bancaire</div>
                    {modePaiement == "cb" && <>
                        <div className="cgv">
                            En cliquant sur Payer, vous acceptez nos <a target='_blank' href="https://storage.jeancalvin.fr/cgv_ljc.pdf">Conditions générales d'utilisation et les CGV</a>.
                        </div>
                        <div className="paiementCarte" style={{}}>
                            <Elements
                                stripe={loadStripe(config.cle_stripe_ljc_publique)}
                            >
                                <CheckoutForm
                                    self={this}
                                    paymentIntent={paymentIntent}
                                    amount={amount}
                                    getStrFromCentimes={getStrFromCentimes}
                                    retourApresPaiement={retourApresPaiement}
                                    handleRetourErreurPaiement={handleRetourErreurPaiement}
                                />
                            </Elements>
                            <div className="paiement-securise-wrapper">
                                <div className="paiement-securise text-center pt-2 pb-2">
                                    <svg aria-hidden="true" focusable="false" height="1em" role="presentation" width="1em" viewBox="0 0 24 24" className=""><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" fill="currentColor" /></svg>
                                    <div className="nom-paiement-securise">
                                        Paiement sécurisé
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">

                        </div>
                        <div className="conforme">
                            Conformément à la réglementation européenne, votre banque pourra exiger l'authentification de votre paiement par la méthode 3D-Secure.
                        </div>
                    </>}
                </section>
                <section className={"element " + (modePaiement === "rib" ? " selected" : " not-selected")} onClick={() => handlerChangeModePaiement("rib")} >
                    <div className="titre">Virement bancaire</div>
                    {modePaiement == "rib" && <div className='contenu-rib'>
                        Dès que nous au­rons vé­ri­fié et trai­té votre com­mande, nous vous en­ver­rons la confir­ma­tion de com­mande avec nos co­or­don­nées ban­caires par e-mail. Veuillez noter que le trai­te­ment de votre vi­re­ment peut prendre 1-2 jours ou­vrables.
                        <div className="bouton-wrapper">

                            <button
                                type="button"
                                className={"bouton"}
                                onClick={() => {
                                    retourApresPaiement("rib");
                                }}
                            >
                                Payer {getStrFromCentimes(amount)} €
                            </button>
                        </div>
                    </div>}
                </section>
            </div>
        </div>
    );
};