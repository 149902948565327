// Librairies
import React from 'react';
import { Link } from 'react-router-dom';
import './NosLibrairies.scss';
import * as couleurs from "../Couleurs"
import { Gallerie } from '../Gallerie/Gallerie';
import {
    Contact,
    Adresse,
    Mail

} from "../SVG/Svg";


const Contenu = ({ tel, adresse, email, joursOuverture, horaires, infos, img, carte, items, couleurTexte }) => {
    return <>
        <div className="row">
            <div className="col-md-5">
                <div className="contact">

                    <h3 className='text-center pt-5 text-uppercase' style={{ color: couleurTexte }}>Contact</h3>
                    <div className="pt-4 text-center"><Contact width={20} couleur={couleurTexte} /></div>
                    <h5 className="pt-2 tel text-center">{tel}</h5>
                    <div className="pt-4 text-center"><Adresse width={20} couleur={couleurTexte} /></div>
                    <h5 className="pt-2 tel text-center">{adresse}</h5>
                    <div className="pt-4 text-center"><Mail width={20} couleur={couleurTexte} /></div>
                    <h5 className="pt-2 tel text-center">{email}</h5>
                </div>
            </div>
            <div className="col-md-7">
                <div className="image">
                    {/* <img className="img-fluid" src={img} alt="" /> */}
                    <Gallerie items={items} />
                </div>
            </div>
            <div className="col-md-5">
                <div className="horaires">
                    <h3 className='text-center pt-4 text-uppercase' style={{ color: couleurTexte }}>Horaires</h3>
                    <h5 className="tel text-center">{joursOuverture}</h5>
                    <h5 className="tel text-center">{horaires}</h5>

                </div>
                <div className="infos">
                    <h3 className='text-center pt-4 text-uppercase' style={{ color: couleurTexte }}>Infos</h3>
                    <h5 className="ps-3 ps-md-5 pe-md-0 pe-3" style={{ textAlign: "justify" }}>{infos}</h5>
                </div>
            </div>
            <div className="col-md-7">
                <div className="carte">
                    <div className="nous-trouver" style={{ backgroundColor: couleurTexte }}>nous trouver</div>
                    <iframe title="carte" src={carte} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
            </div>
        </div>
    </>
}

const lienPhotosLibrairies = "https://storage.timothee.fr/public_storage/LJC/";

export class NosLibrairies extends React.Component {
    componentDidMount() {
        // super.componentDidMount();
        const nom = this.props.match.params.nom;
        this.updateStatus(nom);
        window.scrollTo(0, 0);
    }



    updateStatus = (nom) => {
        switch (nom) {
            case "ales":
                this.setState({
                    nom: "ales",
                    ales: "selected",
                    cholet: "",
                    rennes: "",
                    couleurContenu: "#298b94"
                })
                break;
            case "rennes":
                this.setState({
                    nom: "rennes",
                    ales: "",
                    cholet: "",
                    rennes: "selected",
                    couleurContenu: "#e94c31"
                })
                break;
            case "cholet":
                this.setState({
                    nom: "cholet",
                    ales: "",
                    cholet: "selected",
                    rennes: "",
                    couleurContenu: "#e72461"
                })
                break;
            default:
                this.setState({
                    nom: "ales",
                    ales: "selected",
                    cholet: "",
                    rennes: "",
                    couleurContenu: "#298b94"
                })
                break
        }
    }

    selectedContenu = () => {
        switch (this.state.nom) {
            case "ales":
                return (<Contenu
                    tel="04 66 86 16 61"
                    adresse={<>9 quai Boissier de Sauvages<br />
                        30100 ALES<br />
                        France</>}
                    email="contact@librairiejeancalvin.fr"
                    joursOuverture={"Du lundi au samedi"}
                    horaires="10h-12h et 14h-18h"
                    infos="Relais colis, Carte bancaire, Stands : nous contacter, Click and collect, Itinéraires détaillés, Espace de tra- vail | Lecture, Depôts | Comptoirs, Chèques cadeaux | Chèques livres, Cartes cadeaux, Pass Culture"
                    img="https://www.librairiejeancalvin.fr/var/data_ljc/compt/ales.jpg"
                    carte="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2863.8930147922856!2d4.0754526673826925!3d44.12682881947398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b44276e4747869%3A0xf2cfb5b82f621fc0!2sLibrairie%20Jean%20Calvin!5e0!3m2!1sfr!2sfr!4v1705390635577!5m2!1sfr!2sfr"
                    items={[
                        {
                            original: `https://storage.jeancalvin.fr/librairie/ales/ales-1.jpg`,
                            thumbnail: `https://storage.jeancalvin.fr/librairie/ales/ales-1.jpg`
                        },
                        {
                            original: `https://storage.jeancalvin.fr/librairie/ales/ales-2.jpg`,
                            thumbnail: `https://storage.jeancalvin.fr/librairie/ales/ales-2.jpg`
                        },
                        {
                            original: `https://storage.jeancalvin.fr/librairie/ales/ales-3.jpg`,
                            thumbnail: `https://storage.jeancalvin.fr/librairie/ales/ales-3.jpg`
                        }
                    ]}
                    couleurTexte={couleurs.ROSE}
                />)
            case "cholet":
                return (<Contenu
                    tel="02 41 58 01 17"
                    adresse={<>49, rue Porte Baron<br />
                        49300 CHOLET<br />
                        France</>}
                    email="cholet@librairiejeancalvin.fr"
                    joursOuverture={"Du jeudi au samedi"}
                    horaires="14h30-18h30"
                    infos="Relais colis, Carte bancaire, Stands : nous contacter, Click and collect, Itinéraires détaillés, Espace de tra- vail | Lecture, Depôts | Comptoirs, Chèques cadeaux | Chèques livres, Cartes cadeaux, Pass Culture"
                    img="https://lh3.ggpht.com/p/AF1QipOg2BEVdv5o6uVV9CcnmaWU7B7jKprTHct2kOI5=s1024"
                    carte="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.1001851541196!2d-0.8730741320828741!3d47.05788503544962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480647cc08905369%3A0xc57b59e773285012!2sLibrairie%20Jean%20Calvin!5e0!3m2!1sfr!2sfr!4v1705396491298!5m2!1sfr!2sfr"
                    items={[
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-1.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-1.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-2.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-2.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-3.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-3.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-4.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-4.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-5.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-5.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-6.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-6.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-7.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-7.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-8.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-8.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-9.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-cholet/cholet-9.jpg`
                        },
                    ]}
                    couleurTexte={couleurs.BLEU}
                />)
            case "rennes":
                return (<Contenu
                    tel="09 73 88 08 09"
                    adresse={<>6 rue d'Argentré<br />
                        35000 RENNES<br />
                        France</>}
                    email="rennes@librairiejeancalvin.fr"
                    joursOuverture={"Du mardi au samedi"}
                    horaires="10h-18h"
                    infos="Relais colis, Carte bancaire, Stands : nous contacter, Click and collect, Itinéraires détaillés, Espace de tra- vail | Lecture, Depôts | Comptoirs, Chèques cadeaux | Chèques livres, Cartes cadeaux, Pass Culture"
                    img="https://lh3.ggpht.com/p/AF1QipMSivIxq_vtTxxQ3fPUQwOgVxMTIrnh-IWQEK-r=s1536"
                    carte="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.0332465031233!2d-1.6839003230354064!3d48.109594371240505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480edf50cdbdaff9%3A0x93619b2bb66e3e3!2sLibrairie%20Jean%20Calvin!5e0!3m2!1sfr!2sfr!4v1705396599957!5m2!1sfr!2sfr"
                    items={[
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-1.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-1.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-2.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-2.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-3.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-3.jpg`
                        },
                        {
                            original: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-4.jpg`,
                            thumbnail: `${lienPhotosLibrairies}/nos-librairies-rennes/rennes-4.jpg`
                        }
                    ]}
                    couleurTexte={couleurs.VIOLET}
                />)
            default:
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.nom !== prevProps.match.params.nom) {
            console.log("update");
            this.setState({ nom: this.props.match.params.nom });
        }
    }

    state = {
        nom: "ales",
        ales: "selected",
        cholet: "",
        rennes: "",
        couleurContenu: "#298b94"
    };


    render() {

        return (
            <>
                <div className="container-fluid no-gutters" style={{ minHeight: "1600px", backgroundColor: "#ccc" }}>
                    <div className="container">
                        <div className="nos-librairies">
                            <h1 className='ft-lato text-center pt-5 pb-4 text-uppercase' style={{ color: couleurs.ROSE, fontWeight: "500" }}>Nos librairies</h1>
                            <div className="menu ft-lato">
                                <Link className={"lib-item ales text-uppercase " + this.state.ales}
                                    onClick={() =>
                                        this.updateStatus("ales")
                                    }
                                    to="/nos-librairies/ales"
                                >
                                    Alès
                                </Link>
                                <Link className={"lib-item rennes text-uppercase " + this.state.rennes}
                                    onClick={() =>
                                        this.updateStatus("rennes")
                                    }
                                    to="/nos-librairies/rennes"
                                >
                                    Rennes
                                </Link>
                                {/* <Link className={"lib-item cholet text-uppercase " + this.state.cholet}
                                    onClick={() =>
                                        this.updateStatus("cholet")
                                    }
                                    to="/nos-librairies/cholet"
                                >
                                    Cholet
                                </Link> */}
                            </div>
                            <div className="contenu" style={{ backgroundColor: this.state.couleurContenu }}>
                                {this.selectedContenu()}
                            </div>
                        </div>
                    </div >
                </div >
            </>
        );
    }
}

