



export const getFavorisListe = () => {
    let favorisListe = localStorage.getItem('favoris');
    if (favorisListe != undefined) {
        let datas = JSON.parse(favorisListe);
        //console.log("on teste")
        return datas;
    }
}